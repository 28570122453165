import React from 'react';
import Blog from './components/pages/Blog/Blog';
import BlogList from './components/pages/Blog/BlogList';
import MyBlogs from './components/pages/Blog/MyBlogs';
import BlogView from './components/pages/Blog/BlogView';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Landing from './components/pages/Landing/Landing';
import Library from './components/pages/Library/Library';
import ForgetPassword from './components/Shared/ForgetPassword/ForgetPassword';
import WithFullDialog from './components/Shared/FullPageDialog/FullPageDialog';
import LoginForm from './components/Shared/Login/Login';
import Register from './components/Shared/Register/Register';
import Diary from './components/pages/Diary/Diary';
import PostCards from './components/pages/PostCards/index';
import Profile from './components/Shared/Profile/Profile';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy';
import CookiesPolicy from './components/pages/CookiesPolicy/CookiesPolicy';
import Hub from './components/pages/Hub/Hub';
import NonMembers from './components/pages/views/non-member.js';
import TermsAndConditions from './components/pages/TermsAndConditions/TermsAndConditions';
import CreateEssay from './components/pages/Essay/CreateEssay';
import EssayList from './components/pages/Essay/EssayList';
import MyEssays from './components/pages/Essay/MyEssays';
import EssayView from './components/pages/Essay/EssayView';
import MyPoems from './components/pages/Poems/MyPoems';
import PoemList from './components/pages/Poems/PoemList';
import CreatePoem from './components/pages/Poems/CreatePoem';
import PoemView from './components/pages/Poems/PoemView';

const RegisterPage = () => WithFullDialog(Register, 'Register');
const ForgotPasswordPage = () => WithFullDialog(ForgetPassword, 'Forgot Password ?');
const LoginPage = () => WithFullDialog(LoginForm, 'Login');

const EssayReview = () => <EssayView isReview={true}/>;

const routes = [

    {
        path: '/register',
        title: 'Register',
        exact: true,
        nav: false,
        subNav: false,
        noLayout: true,
        hideForLoggedIn: true,
        component: RegisterPage
    },
    {
        path: '/login',
        title: 'Login',
        exact: true,
        nav: false,
        subNav: false,
        noLayout: true,
        hideForLoggedIn: true,
        component: LoginPage
    },
    {
        path: '/non-member',
        title: 'Non Member',
        isPrivate: true,
        hideSideBar: false,
        exact: true,
        hasSubNav: false,
        nav: false,
        noLayout:true,
        component: NonMembers
    },
    {
        path: '/forget-password',
        title: 'Forot Password',
        exact: true,
        nav: false,
        subNav: false,
        noLayout: true,
        hideForLoggedIn: true,
        component: ForgotPasswordPage
    },
    {
        path: '/profile',
        title: 'Profile',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        component: Profile
    },
    {
        path: '/dashboard',
        title: 'DashBoard',
        isPrivate: true,
        hideSideBar: false,
        exact: true,
        hasSubNav: false,
        nav: true,
        component: Dashboard
    },
    // Start: Blogs
    {
        path: '/blogs',
        title: 'Blogs',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        component: MyBlogs
    },
    {
        path: '/blog-list',
        title: 'Blogs',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        component: BlogList
    },
    {
        path: '/blogs/create',
        title: 'Create Post',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        component: Blog
    },
    {
        path: '/blogs/edit/:postId',
        title: 'Edit Post',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        component: Blog
    },
    {
        path: '/blogs/:postId',
        title: 'View Post',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: true,
        component: BlogView
    },
    // End: Blogs
    // Start: Essay
    {
        path: '/essay',
        title: 'My Essays',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: MyEssays
    },
    {
        path: '/essay/list',
        title: 'Essay',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: EssayList
    },
    {
        path: '/essay/create',
        title: 'Create Essay',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: CreateEssay
    },
    {
        path: '/essay/edit/:essayId',
        title: 'Edit Essay',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: CreateEssay
    },
    {
        path: '/essay/:essayId',
        title: 'View Essay',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: EssayView
    },
    {
        path: '/essay/review/:essayId',
        title: 'Review',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: EssayReview
    },
    // End: Essay
    // Start: Poems
    {
        path: '/poem',
        title: 'My Poems',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: MyPoems
    },
    {
        path: '/poem/list',
        title: 'Poem',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: PoemList
    },
    {
        path: '/poem/create',
        title: 'Create Poem',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: CreatePoem
    },
    {
        path: '/poem/edit/:poemId',
        title: 'Edit Poem',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: CreatePoem
    },
    {
        path: '/poem/:poemId',
        title: 'View Poem',
        exact: true,
        nav: false,
        hasSubNav: true,
        isPrivate: true,
        hideSideBar: false,
        component: PoemView
    },
    // End: Poem
    {
        path: '/diary',
        title: 'Diary',
        isPrivate: true,
        exact: true,
        hasSubNav: false,
        nav: true,
        component: Diary
    },
    {
        path: '/post-card',
        title: 'Post Card',
        isPrivate: true,
        exact: true,
        hasSubNav: false,
        nav: true,
        component: PostCards
    },
    {
        path: '/library',
        title: 'Library',
        isPrivate: true,
        exact: true,
        hasSubNav: false,
        nav: true,
        component: Library
    },
    {
        path: '/hub',
        title: 'Hub',
        exact: true,
        isPrivate: true,
        hasSubNav: false,
        nav: true,
        component: Hub
    },
    {
        path: '/terms-conditions',
        title: 'Terms and Conditions',
        exact: true,
        isPrivate: false,
        hasSubNav: false,
        nav: true,
        component: TermsAndConditions
    },
    {
        path: '/privacy',
        title: 'Privacy Policy',
        exact: true,
        isPrivate: false,
        hasSubNav: false,
        nav: true,
        component: PrivacyPolicy
    },
    {
        path: '/cookies-policy',
        title: 'Cookies Policy',
        exact: true,
        isPrivate: false,
        hasSubNav: false,
        nav: true,
        component: CookiesPolicy
    },
    {
        path: '/',
        title: 'Home',
        exact: true,
        nav: false,
        subNav: false,
        noLayout: false,
        hideSideBar: true,
        component: Landing
    },
    {
        path: '*',
        exact: true,
        nav: false,
        component: () => <div>Page Not Found</div>
    }
];

export const topMenu = [
    {
        path: '/dashboard',
        title: 'Dashboard'
    },
    {
        path: '',
        title: 'Create',
        children: [
            {
                path: '/diary',
                title: 'Diary'
            },
            {
                path: '/blogs',
                title: 'Blog'
            },
            {
                path: '/post-card',
                title: 'PostCard'
            },
            {
                path: '/essay',
                title: 'Essay'
            },
            {
                path: '/poem',
                title: 'Poem'
            }
        ]
    },
    {
        path: '/library',
        title: 'Library'
    },
    {
        path: '/hub',
        title: 'Hub'
    }
];

export default routes;