import {
  SET_OPENED_JOURNAL,
  SET_SAVED_JOURNAL,
  SET_ADD_JOURNAL_PAGE,
  SET_GET_JOURNAL_PAGE
} from "../actions/types";

export const INITIAL_STATE = {
  openedJournal: {
    id: null,
    page: null,
    events:null,
    gratefulfor:null,
    on: null,
    userid: null
  },
  savedJournal: {
    id: null,
    page: null,
    events:null,
    gratefulfor:null,
    on: null,
    userid: null
  }
};

const journalReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_JOURNAL:
      return {
        ...state,
        openedJournal: payload,
      };    
    case SET_GET_JOURNAL_PAGE:
      return {
        ...state,
        openedJournal: payload,
      };
      case SET_ADD_JOURNAL_PAGE:
        return {
          ...state,
          openedJournal: payload,
        };      
    case SET_SAVED_JOURNAL:
      return {
        ...state,
        savedJournal: payload,
      };
    default:
      return state;
  }
};

export default journalReducer;
