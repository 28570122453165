import React, { useState,useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { makeStyles } from '@material-ui/core/styles';
import JoditEditorControl from "../../Shared/JoditEditor/JoditEditor";
import { Box, Button, Typography, Avatar, Icon, Dialog, DialogTitle, DialogContent, ButtonBase, Backdrop, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { setOpenedPost, setSavedPost } from "../../../redux/actions/postAction";
import { getBlogMails } from "../../../redux/actions/notificationsAction";
import { showErrorSnackbar } from "../../../redux/actions/uiAction";
import { green } from '@material-ui/core/colors';
import BlogViewer from "./BlogViewer";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    actions: {
        textAlign: `right`,
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
        marginRight: `10px`
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const BlogEditor = () => {
    const classes = useStyles();
    const auth = useSelector(state => state.firebase.auth);
    const firestore = useFirestore();
    const { openedPost } = useSelector(state => state.post);
    const [showPreview, setShowPreview] = useState(false);
    const { isPageLoading } = useSelector(state => state.ui);
    const [notifications, setNotifications] = React.useState([]);
    const [lastContent,setLastContent]= React.useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        handleSendMail(notifications,openedPost)
    }, [notifications]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (openedPost.title && openedPost.body!=lastContent) {
                dispatch(setSavedPost(openedPost.isPublished,true));
                setLastContent(openedPost.body);
            }
            console.log('Auto Saved!')
         }, 10000);
     
        return () => clearTimeout(timeout);
       },[openedPost.body]);

    const handleSave = event => {
        event.preventDefault();
        if (openedPost.title) {
            dispatch(setSavedPost(false,false));
            setLastContent(openedPost.body);
        }
        else {
            dispatch(showErrorSnackbar("Please enter Blog Title"));
        }
    }

    const handlePublish = async (event) => {
        event.preventDefault();
        if (openedPost.title) {
            if(openedPost.blogId.value) dispatch(getBlogMails(openedPost.blogId.value,setNotifications));
            dispatch(setSavedPost(true,false));
        }
        else {
            dispatch(showErrorSnackbar("Please enter Blog Title"));
        }
    }

    const handleSendMail = async (usersEmail,openedPost) =>{
        const uuid = uuidv4();
        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(usersEmail)) {     
        await firestore.collection("mails").doc(uuid).set({
            to: usersEmail,
            message: {
              subject: "Quill At Will Blog Updates",              
              html: openedPost.blogId.label+' has got new updates. Please <a href="https://quillatwill.com/blogs/'+openedPost.id+'">click here</a>'
            }
          })
        } 
    }

    const handleEditorChange = event => {
        const body = event;
        dispatch(setOpenedPost({ ...openedPost, body }));
    };

    const handlePreview = event => {
        event.preventDefault();
        setShowPreview(true);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    return (
        <React.Fragment>
            <Box className={classes.actions}>
                {!openedPost.isPublished && (<Button variant="contained" onClick={handleSave}>Save</Button>)}
                <Button variant="contained" onClick={handlePreview}>Preview</Button>
                <Button variant="contained">Share</Button>
                <Button variant="contained" color="primary" onClick={handlePublish}>Publish</Button>
            </Box>
            {openedPost && openedPost.blogId &&
                <Box my={4} display="flex" alignItems="center">
                    <Avatar className={classes.green}>
                        <Icon>assignment</Icon>
                    </Avatar>
                    <Typography variant="h6">{openedPost.blogId.label}</Typography>
                </Box>}

            {openedPost && openedPost.title && <Box my={4}>
                <Typography variant="h4">{openedPost.title}</Typography>
            </Box>}

            <JoditEditorControl onChange={handleEditorChange} content={openedPost.body} />

            {/* <code>{JSON.stringify(openedPost)}</code> */}

            <Dialog fullScreen open={showPreview}>
                <DialogTitle>
                    Preview
                    <Button onClick={handleClosePreview} color="primary">
                        Close
                    </Button>
                </DialogTitle>
                <DialogContent dividers>
                    <BlogViewer post={openedPost} isPreview={true} />
                </DialogContent>
            </Dialog>

            <Backdrop className={classes.backdrop} open={isPageLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
};

export default BlogEditor;
