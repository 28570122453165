import { createStore, applyMiddleware,compose  } from "redux";
import { reduxFirestore, getFirestore } from 'redux-firestore';
import rootReducer from "./reducers";
import { getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk';
import fireDB from '../base';
import logger from 'redux-logger';

//const rfConfig = {};
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};
const middlewares = [
    thunk.withExtraArgument(getFirebase),
    thunk.withExtraArgument(getFirestore),
    logger
  ]
const createStoreWithFirebase = compose(
// firebase instance as first argument, rfConfig as optional second
    compose(
      reduxFirestore(fireDB, firebaseConfig), 
        applyMiddleware(...middlewares)
     ),
    )(createStore);



//const store = createStore(rootReducer, applyMiddleware(thunk));
const initialState = {};
const store = createStoreWithFirebase(rootReducer, initialState);
export default store;
