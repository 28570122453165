export const EssayStatus = {
    IN_PROGRESS: 'In Progress',
    IN_REVIEW: 'In Review',
    PUBLISHED: 'Published',
};

export const PoemStatus = {
    IN_PROGRESS: 'In Progress',
    IN_REVIEW: 'In Review',
    PUBLISHED: 'Published',
};
