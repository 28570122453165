import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  TextField, IconButton, Icon
} from "@material-ui/core";
import {
  KeyboardDatePicker
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import { addAspirations, saveAspirations } from "../../../redux/actions/aspirationsAction";
import { addTodos, saveTodos } from "../../../redux/actions/todosAction";
import { set } from "lodash";
import { showErrorSnackbar } from "../../../redux/actions/uiAction";

const useStyles = makeStyles(() => ({
  addNewBtn: {
    marginTop: 20,
    marginLeft: 20
  },
  todoInput: {
    marginTop: 16.5,
    width: `80%`
  }
}));

const todoInitialState = {
  todoInput: "",
  selectedDate: new Date()
};

const AddTodo = (props) => {
  const { label, type, list } = props;
  const classes = useStyles();
  const { clearTodo } = useSelector(state => state.todos);
  const dispatch = useDispatch();
  const [todoState, setTodoState] = useState(todoInitialState);

  useEffect(() => {
    setTodoState({ ...todoInitialState });
  }, [clearTodo]);

  const handleAdd = event => {
    const status = 0;
    const target = new Date(todoState.selectedDate);
    const added = new Date();
    const name = todoState.todoInput;

    if (Object.keys(name).length === 0) {
      dispatch(showErrorSnackbar("Please type text for " + type.toUpperCase() + "!"));
      event.preventDefault();
      return;
    }
    if (list.some(e => e.name === name.trim())) {
      dispatch(showErrorSnackbar(type.toUpperCase() + " is already exists"));
      event.preventDefault();
      return;
    }

    // Save todo to firebase
    if (list.length > 0) {
      (type === 'aspiration') ?
        dispatch(saveAspirations({ name, added, target, status })) :
        dispatch(saveTodos({ name, added, target, status }));
    }
    else {
      (type === 'aspiration') ?
        dispatch(addAspirations({ name, added, target, status })) :
        dispatch(addTodos({ name, added, target, status }));
    }

  };

  return (
    <React.Fragment>
      <TextField id="todo-text"
        className={classes.todoInput}
        fullWidth
        label={label}
        value={todoState.todoInput}
        onChange={evt => setTodoState({ ...todoState, todoInput: evt.target.value })}
      />
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="DD/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Target Date"
        value={todoState.selectedDate}
        autoOk={true}
        onChange={evt => setTodoState({ ...todoState, selectedDate: dayjs(evt._d).format('YYYY-M-D') })}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <IconButton className={classes.addNewBtn} color="secondary">
        <Icon onClick={handleAdd}>add_circle</Icon>
      </IconButton>
    </React.Fragment>
  );
};
export default AddTodo;
