import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./Blog.scss";
import ReactHtmlParser from "react-html-parser";
import BlogViewAuthor from "./BlogViewAuthor";
import dayjs from "dayjs";
import { LayoutConfig } from "./Layout";
import CommentsMultiLevel from "../../Shared/Comments/CommentsMultiLevel";
import { showInfoSnackbar } from "../../../redux/actions/uiAction";
import { useDispatch, useSelector } from "react-redux";
import { convertKMBT } from "../../../helper/Helpers";
import ad2Src from "../../../assets/images/ad2.jpeg";

const useStyles = makeStyles((theme) => ({
  content: {
    display: `flex`,
    padding: theme.spacing(0),
  },
  mainContent: {
    flexGrow: 1,
    width: `100%`,
    overflowY: `scroll`,
    overflowX: `hidden`,
    paddingRight: theme.spacing(3),
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    marginLeft: theme.spacing(3),
  },
  adSection: {
    width: `285px`,
    overflow: `hidden`,
    "&.preview": {
      height: `calc(100vh - 100px)`,
    },
  },
  blogAuthor: {
    width: `220px`,
    textAlign: `center`,
    paddingTop: theme.spacing(10),
    overflow: `hidden`,
    "&.preview": {
      height: `calc(100vh - 100px)`,
    },
  },
  imageGrid: {
    flexGrow: 1,
    overflow: `hidden`,
  },
  imageGridItem: {
    padding: 0,
  },
  image: {
    height: `300px`,
    objectFit: `cover`,
    width: `100%`,
  },
  tags: {
    display: `flex`,
    color: theme.palette.secondary.alt,
    justifyContent: `flex-end`,
    fontStyle: `italic`,
  },
  actionIcons: {
    display: `flex`,
    justifyContent: `flex-end`,
  },
  viewIcon: {
    color: `lightgreen`,
  },
  favIcon: {
    color: `red`,
  },
  shareIcon: {
    color: `green`,
  },
  commentIcon: {
    color: theme.palette.primary.main,
  },
  likeIcon: {
    color: theme.palette.primary.main,
  },
  blogIcon: {
    display: `flex`,
    flexDirection: `column`,
    textAlign: `center`,
  },
}));

const BlogViewer = ({ post, isPreview = false }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const classes = useStyles();
  const [isCommentOpen, setCommentOpen] = useState(false);
  const [commentsCount, setcommentsCount] = useState(0);

  useEffect(() => {
    if (!isPreview) {
      let viewscount = (post.views) ? post.views + 1 : 1;
      firestore
        .collection("posts")
        .doc(post.id)
        .update({
          views: viewscount,
        });
      firestore
        .collection("comments")
        .where("postid", "==", post.id).get().then(function (querySnapshot) {
          console.log(querySnapshot.size);
          setcommentsCount(querySnapshot.size);
        });
    }
  }, []);

  const handleCommentClose = () => {
    setCommentOpen(false);
  };

  const handleCommentOpen = () => {
    setCommentOpen(true);
  };
  const handleLikedClick = () => {
    if (post?.likedby?.indexOf(auth.uid) === -1 || post?.likedby === undefined) {
      firestore.collection("posts").doc(post.id).update({
        likedby: firestore.FieldValue.arrayUnion(auth.uid)
      });
    } else {
      dispatch(showInfoSnackbar("You had already liked this post!"));
    }
  };

  const handleLovedClick = () => {
    if (post?.lovedby?.indexOf(auth.uid) === -1 || post?.lovedby === undefined) {
      firestore.collection("posts").doc(post.id).update({
        lovedby: firestore.FieldValue.arrayUnion(auth.uid)
      });
    } else {
      dispatch(showInfoSnackbar("You had already loved this post!"));
    }
  };

  const handleSharedClick = () => {

    let sharecount = (post.shared) ? post.shared + 1 : 1;
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    firestore
      .collection("posts")
      .doc(post.id)
      .update({
        shared: sharecount,
      });
    dispatch(showInfoSnackbar("This post is copied to share!"));
  };

  if (!post) {
    return ``;
  }

  const layout =
    LayoutConfig.find((itm) => itm.id === post.layout) || LayoutConfig[0];
  console.log(layout, post.layout);

  const showComments = () => (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleCommentOpen}>
        Comment
      </Button>
      <Dialog open={isCommentOpen}>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <TextField
            // autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCommentClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCommentClose} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  const showActions = () => (
    <React.Fragment>
      <Box className={classes.blogIcon}>
        <IconButton className={classes.viewIcon}>
          <Icon>visibility</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.views ? post.views : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLikedClick}>
        <IconButton className={classes.likeIcon}>
          <Icon>thumb_up_off_alt</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.likedby ? post.likedby.length : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLovedClick}>
        <IconButton className={classes.favIcon}>
          <Icon>favorite</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.lovedby ? post.lovedby.length : "[]")}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleSharedClick}>
        <IconButton className={classes.shareIcon}>
          <Icon>share</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(post.shared ? post.shared : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon}>
        <IconButton className={classes.commentIcon}>
          <Icon>comment</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {commentsCount}
        </Typography>
      </Box>
    </React.Fragment>
  );

  const getPostHeader = () => {
    return (
      <React.Fragment>
        <Typography variant="h4">{post.title}</Typography>
        {post.blogId && (
          <Typography variant="subtitle2">{post.blogId.label}</Typography>
        )}
        {post.lastUpdate && (
          <Typography variant="subtitle" component="i">
            {dayjs(new Date(post.lastUpdate.seconds * 1000)).format("MMMM D YYYY, h:mm A")}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  const getPostTags = () => {
    return (
      <React.Fragment>
        {post.tags &&
          post.tags.map((tag) => (
            <React.Fragment>
              <Typography color="textSecondary">#</Typography>
              <Typography color="textSecondary">{tag.label}&nbsp;</Typography>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  const getPostImages = () => {
    return (
      <React.Fragment>
        <Grid container className={classes.imageGrid} spacing={0}>
          {post.images &&
            post.images.map((image) => (
              <Grid item xs className={classes.imageGridItem}>
                <img src={image} className={classes.image} />
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    );
  };

  const postView = () => (
    <React.Fragment>
      {layout.title === `top` ? (
        <React.Fragment>
          <Box
            p={5}
            mt={0}
            bgcolor={layout.titleBg}
            color={layout.titleColor}
            textAlign="center"
          >
            {getPostHeader()}
          </Box>
          <Box mt={0}>{getPostImages()}</Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box mt={0} mb={0}>
            {getPostImages()}
          </Box>
          <Box
            p={5}
            mt={-2}
            bgcolor={layout.titleBg}
            color={layout.titleColor}
            textAlign="center"
          >
            {getPostHeader()}
          </Box>
        </React.Fragment>
      )}

      <Box mt={3} textAlign="right" className={classes.tags}>
        {getPostTags()}
      </Box>

      <Box mt={3} p={2}>
        <Typography variant="body2">{ReactHtmlParser(post.body)}</Typography>
      </Box>

      {!isPreview && (
        <Box
          Box
          mt={2}
          mb={5}
          textAlign="right"
          className={classes.actionIcons}
        >
          {showActions()}
        </Box>
      )}

      {!isPreview && (
        <Box mt={3} mb={3}>
          <CommentsMultiLevel
            data={post}
            id_field="postid"
            collection="posts" 
          />
        </Box>
      )}
    </React.Fragment>
  );

  return (
    <Container className={classes.content}>
      {layout.author && (
        <Box
          item
          className={classes.blogAuthor}
          bgcolor={layout.authorBg}
          color={layout.authorColor}
        >
          <BlogViewAuthor post={post} isPreview={isPreview} />
        </Box>
      )}
      <Box className={classes.mainContent} pt={!isPreview ? 2 : 0}>
        {postView()}
      </Box>
      {!isPreview && (
        <Box item className={classes.adSection}>
          <img
            alt="AD200x720"
            src={ad2Src}
          />
        </Box>
      )}
    </Container>
  );
};

export default BlogViewer;
