import {
  SET_OPENED_GALLERY,
  SET_OPENED_USERS_IMAGES
} from "../actions/types";

export const INITIAL_STATE = {
  gallery: {
    images: [],
    thumbnail: [],
    count: 0
  },
  usersImages: {
    images: [],
    userid: null,
    thumbnail: [],
    count: 0
  }
};

const imagesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_GALLERY:
      return {
        ...state,
        gallery: payload,
      };
    case SET_OPENED_USERS_IMAGES:
      return {
        ...state,
        usersImages: payload,
      };
    default:
      return state;
  }
};

export default imagesReducer;
