import { storage } from "./base";
export const uploadFromBlobAsync = async ({ blobUrl, name, uuid, metadata,userId }) => {
    if (!blobUrl || !name) return null;

    try {
        const blob = await fetch(blobUrl).then((r) => r.blob());
        const snapshot = await storage.ref().child(`/users/${userId}/${Date.now()}-${name}`).put(blob);
        // snapshot.ref().updateMetadata(metadata);
        return await snapshot.ref.getDownloadURL();
    } catch (error) {
        throw error;
    }
};

export const deleteFileBypath = async (url) => {
    if (!url) return null;

    try {
        const snapshot = await storage.refFromURL(url);
        return await snapshot.delete();
    } catch (error) {
    }
};

