import React, { } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./CookiesPolicy.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing() * 5,
        overflow: `hidden`
    }
}));

const CookiesPolicy = props => {
    const classes = useStyles();

    return (
        <div className="cookies-page">
            <div className={classes.root}>
                <div className="CookiesPolicy">
                    <h1 style={{ textAlign: "center" }}>Cookies Policy </h1>
                    <p>
                        This page explains everything you need to know about "Cookies" and how
                        Cookies are used on Quill at will . On this page, “you” or “your” refers
                        to the user and "we" or "us" refers to Quill at Will.
                    </p>
                    <p>What are Cookies?</p>
                    <p>
                        All websites use Cookies. Cookies are small files put onto your computer
                        that contain certain information about you. They do not contain any
                        sensitive information about you, like name or address.
                    </p>
                    <p>
                        But can allow us to distinguish you from other users on the site. Cookies
                        cannot harm your computer in any way.
                    </p>
                    <p>What do Cookies do?</p>
                    <p>
                        <a href="https://www.quillatwill.com/" target="_blank">
                            {" "}
                            www.quillatwill.com
                        </a>{" "}
                        uses Cookies to improve its website's features and services, so you can
                        enjoy the site more. One way we use Cookies is to remember useful stuff
                        about your member's profile, such as to obtain certain badges. We use
                        different types of Cookies on our site. These are described below.
                    </p>
                    <h3>Types of Cookies</h3>
                    <p>
                        {" "}
                        <b>"Analytical" Cookies: </b>These help us to count the number of visitors
                        on our site and how they move around the website. And which pages or
                        actions are used the most.
                    </p>
                    <p>
                        {" "}
                        <b>"Session" Cookies: </b>These Cookies help us to see when you are logged
                        into your account and what activities you are completing on the website.
                    </p>
                    <p>
                        <b>"Persistent" Cookies:</b> These Cookies are stored between your visits
                        to our site. These allow us to remember your preferences or actions when
                        you log back into the site.
                    </p>
                    <p>
                        {" "}
                        <b>“Targeting” Cookies:</b>These cookies are used to deliver adverts more
                        relevant to your and your interests. They collect information about your
                        browsing habits and the websites you visit.
                    </p>
                    <p>How to block Cookies?</p>
                    <p>
                        If you want to block Cookies, you can check the settings in your browser.
                        And this will let you block either all or certain types of Cookies. It is
                        very important to note, that if you do block Cookies this will mean that
                        you might not be able to access all areas of our site and all features.
                    </p>
                    <p></p>
                    <li>
                        {" "}
                        <b>Internet Explorer</b>
                        <a
                            href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-Cookies"
                            target="_blank"
                        >
                            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-Cookies
                        </a>
                    </li>
                    <li>
                        {" "}
                        <b>Google Chrome</b>
                        <a
                            href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647&p=cpn_Cookies"
                            target="_blank"
                        >
                            https://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647&amp;p=cpn_Cookies{" "}
                        </a>
                    </li>
                    <li>
                        <b> Mozilla Firefox</b>
                        <a
                            href="http://support.mozilla.org/en-US/kb/Blocking%20Cookies"
                            target="_blank"
                        >
                            http://support.mozilla.org/en-US/kb/Blocking%20Cookies
                        </a>
                    </li>
                    <li>
                        {" "}
                        <b>Apple Safari</b>
                        <a
                            href="https://support.apple.com/kb/ph21411?locale=en_US"
                            target="_blank"
                        >
                            https://support.apple.com/kb/ph21411?locale=en_US
                        </a>
                    </li>
                    <li>
                        {" "}
                        <b>Apple handheld devices</b>
                        <a href="https://support.apple.com/en-us/HT201265" target="_blank">
                            https://support.apple.com/en-us/HT201265
                        </a>
                    </li>
                    <p />
                    <h3>
                        Cookies used on <a href="https://www.quillatwill.com/" target="_blank"> quillatwill.com </a>
                    </h3>
                    <h3>Below is a list of Cookies we use:</h3>
                    <h3><b>Strictly Necessary Cookies</b></h3>
                    <p>
                        These Cookies are essential in order to enable you to move around the
                        website and use its features.
                    </p>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th style={{ width: "20%" }}>Cookie Name</th>
                                <th>Cookie Overview</th>
                            </tr>
                            <tr>
                                <td>martys_vault XSRF-TOKEN</td>
                                <td>
                                    These are a unique security token, used to prevent and block unknown
                                    user requests to the site. Also used to validate the current user
                                    once logged in.{" "}
                                </td>
                            </tr>
                            <tr>
                                <td>marty_seen</td>
                                <td>
                                    This cookie is used to track if a member has logged into the site.
                                </td>
                            </tr>
                            <tr>
                                <td>cookie_policy_new_feature_popup cookie_policy_sigup_popup </td>
                                <td>
                                    This cookie is used to track if user had seen the popup and have
                                    hidden it.
                                </td>
                            </tr>
                            <tr>
                                <td>cookie_policy cookie_policy_setting </td>
                                <td>
                                    This cookie are used to track if a user has agreed with our cookie
                                    policy and stores user's cookie settings.
                                </td>
                            </tr>
                            <tr>
                                <td>remember_web_*</td>
                                <td>This cookie is used to keep the member logged in.</td>
                            </tr>
                            <tr>
                                <td>tz</td>
                                <td>This cookie stores your local timezone.</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>Performance Cookies</h3>
                    <p>
                        These Cookies allow us to count visits and traffic sources so we can
                        measure and improve the performance of our site. They help us to know
                        which pages are the most and least popular, see how visitors move around
                        the site and provide a better user experience in the future. All
                        information these Cookies ggregated and therefore anonymous. If you do not
                        allow these Cookies we will not know when you have visited our site, and
                        will not be able to monitor its performance.
                    </p>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th style={{ width: "20%" }}>Cookie Name</th>
                                <th>Cookie Overview</th>
                            </tr>
                            <tr>
                                <td>_ga _gid</td>
                                <td>
                                    Google Analytics cookie is used to distinguish users when collecting
                                    information about page visits. This cookie helps us to identify
                                    website areas to be improved and these cookies d not allow Quill at
                                    Will to identify a user personally as no personal information or
                                    data is tracked.
                                </td>
                            </tr>
                            <tr>
                                <td>_gat</td>
                                <td>
                                    Google Analytics cookie is used by Google to monitor request rate
                                    toward their servers.
                                </td>
                            </tr>
                            <tr>
                                <td>__gads</td>
                                <td>
                                    This cookie is set by Google and stored under the name
                                    doubleclick.net and underQuill at Will .com. This cookie is used to
                                    track how many times users see a particular advert which helps in
                                    measuring the success of the campaign and calculate the revenue
                                    generated by the campaign. These cookies can only be read from the
                                    domain that it is set on so it will not track any data while
                                    browsing through another sites.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    fr
                                    <br />
                                    sb
                                    <br />
                                    datr
                                    <br />
                                    reg_fb_ref
                                    <br />
                                    reg_ext_ref
                                    <br />
                                    reg_fb_gate
                                </td>
                                <td>
                                    This Cookie is placed by Facebook. It enables us to measure,
                                    optimize and build audiences for advertising campaigns served on
                                    Facebook
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3>User Engagement Cookies</h3>
                    <p>
                        These Cookies are used to engage users with third party services like
                        AddThis and Disqus
                    </p>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th style={{ width: "20%" }}>Cookie Name</th>
                                <th>Cookie Overview</th>
                            </tr>
                            <tr>
                                <td>
                                    __atuvc
                                    <br />
                                    __atuvs
                                    <br />
                                    uid
                                    <br />
                                    uvc
                                    <br />
                                    mus
                                    <br />
                                    cw_id
                                </td>
                                <td>
                                    These Cookies are created and used by AddThis social sharing addon,
                                    in order to make sure users see the updated count if the user shares
                                    a page.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    disqus_unique
                                    <br />
                                    __jid
                                    <br />
                                    __utmb
                                    <br />
                                    __utmc
                                    <br />
                                    __utma
                                    <br />
                                    __utmz
                                    <br />
                                    __utmt
                                </td>
                                <td>
                                    These Cookies are created and used by Disqus social commenting
                                    system.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>Change your cookie settings</p>
                </div>
            </div>
        </div>
    );
};

export default CookiesPolicy;
