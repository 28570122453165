import React, { useEffect, useState } from "react";
import './PostCard.scss';
import { useSelector, useDispatch } from "react-redux";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import photoURL from "../../../../assets/images/profile.jpg";

const useStyles = makeStyles({
    '.MuiDialog-paperWidthXs': {
        width: "53%"
    },
    paper: { width: "900px" },
});

export default function SentPostCard() {
    const [sent, setSent] = useState([]);
    const [email, setEmail] = useState([])
    const [sentPost,setSentPost]=useState([])
    const sentingDetails = useSelector(state => state.postCard.sent);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const classes = useStyles();
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    console.log("senting details", sentingDetails);
    useEffect(()=>{

    
    var months = [
        { value: 0, month: 'January' },
        { value: 1, month: 'February' },
        { value: 2, month: 'March' },
        { value: 3, month: 'April' },
        { value: 4, month: 'May' },
        { value: 5, month: 'June' },
        { value: 6, month: 'July' },
        { value: 7, month: 'August' },
        { value: 8, month: 'September' },
        { value: 9, month: 'October' },
        { value: 10, month: 'November' },
        { value: 11, month: 'December' }
    ];
    var monthsOfTheYear = [];
    let currentMonth = new Date().getMonth();
    let find = months.findIndex(res => res.value == currentMonth);
    console.log("find", find)
    for (var i = find; i >= 0; i--) {
        monthsOfTheYear.push(months[i])
    }
    console.log("i", monthsOfTheYear);
    var finalResult = [];
    let year = new Date().getFullYear();
    function sortByDate(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
            return 1;
        }
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
            return -1;
        }
        return 0;
    }
    for (const element of monthsOfTheYear) {
        let firstDate = (new Date(year, element.value, 1)).setHours(0, 0, 0, 0);
        let lastDate = (new Date(year, element.value + 1, 0)).setHours(23, 59, 59, 999);
        let firstDay = new Date(firstDate);
        let lastDay = new Date(lastDate);
        let data = sentingDetails.filter((item) => {
            return new Date(item.date).getTime() >= firstDay
                && new Date(item.date).getTime() <= lastDay;
        });
        finalResult.push({
            month: element.month,
            value: data.sort(sortByDate)
        });
        let oldData = sentingDetails.filter((item) => {
            return new Date(item.date).getFullYear() < new Date().getFullYear()
               
        });
        finalResult.push({            
            month: new Date().getFullYear()-1 + ' and Previous',
            value: oldData.sort(sortByDate)
        });
    }
    console.log('finalResult', finalResult);
    setSentPost(finalResult)
    },[sentingDetails])

    function clickImage(item) {
        setModalIsOpen(true);
        setSent(item);
        setEmail(item.to)
    }
    const closeModal = () => {
        setModalIsOpen(false)
    }

    return (
        <div className="container postcard-root">
            <h1 className="titles">Postcard Sent</h1>
            <hr></hr>
            <p className="details">Click on the card to view  the details</p>
            <div className="scrolling">
                {sentPost.map((item, index) => (
                    <div key={index}>
                        {item.value.length >0?
                        <>
                        
                        <div className="header" style={{ marginTop: '40px' }}>
                            {item.month}
                        </div>
                        {item.value.length > 0 ?
                            <Carousel
                                swipeable={false}
                                responsive={responsive}
                            >
                                {item.value.map((items, index) => (
                                    <div className="col-md-6 mt-3 position-relative d-flex" key={index} onClick={() => clickImage(items)} style={{ cursor: 'pointer' }}>
                                        <div className="images">
                                            <img src={items.image} className="images" height="100%" width="100%"></img>
                                        </div>
                                        <h5 className="position-absolute mt-4 text-capitalize w-95 text-center bg-light-gray">{items.to.map((value, index,items)=>  (items.length-1)===index?value.userName:value.userName+', ')}</h5>
                                    </div>
                                ))}
                            </Carousel>
                            :
                            <div>No Postcard Sent</div>
                        }
                        </>
                        :""}
                    </div>
                ))}
            </div>

            <div>
                <Dialog open={modalIsOpen} onClose={closeModal} maxWidth="lg" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="postcard-root">
                    <div style={{ width: '730px', padding: '0px', overflow: 'auto' }}>
                        <div id="alert-dialog-title" style={{ backgroundColor: '#000029', padding: '10px', margin: '0' }}>
                            <div className="w-100 d-flex">
                                <h1 className="sent-receive-heading">Post Card Sent</h1>
                                <span className="cursor-pointer closeModal push" onClick={closeModal}>&#x2715;</span>
                            </div>
                        </div>
                        <div className="boxShadow border-sent-receive">
                            <div>
                                <div id="alert-dialog-description">
                                    <img src={sent.image} className="imagesModal"></img>
                                </div>
                                <div className="sent-receive-content">
                                    <p className="content">{sent.content}</p>
                                </div>
                                {/* <div>
                                {sent.name}
                            </div>
                            <div>
                                {email.map((item) => (
                                    <p>{item.email}</p>
                                ))}
                            </div> */}
                                <div>
                                    <p style={{ fontWeight: '500', marginBottom: '0px', marginLeft: '35px', fontSize: '17px' }}>To</p>
                                    <div style={{ marginBottom: '0px', height: '100px', overflowY: 'auto' }}>
                                        {email.map((item ,index) => (
                                            <div style={{ display: 'flex', marginBottom: '0px', paddingBottom: '0px' }} key={index}>
                                                <div>
                                                    <img src={photoURL} height='50px' width='50px' style={{ borderRadius: '50%' }}></img>
                                                </div>
                                                <div>
                                                    <p>{item.userName}</p>
                                                    <p>{item.email}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>


        </div>
    );
}