import {
    Button, Container, Icon, Box, IconButton,
    Table, TableHead, TableBody, TableCell, TableRow,
    CardContent, Card, CardActions, CardHeader, CardMedia, Typography, Grid,
    Link, TextField, Select, MenuItem, FormControl, InputLabel
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { firebaseAnalytics } from "../../../base";
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import dayjs from "dayjs";
import { useHistory } from 'react-router-dom';
import lodash from "lodash-es";
import deepdash from "deepdash-es";
import { capitalizeFirstLetter } from "../../../helper/Helpers";
import { objectOf } from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2)
    },
    card: {
        maxWidth: 345,
        position: `relative`
    },
    content: {
        height: 115,
        padding: `0 5px`,
        width: `100%`,
        position: `absolute`,
        bottom: 65,
        background: `#3D3E3F`,
        color: `#fff`,
        opacity: 0.75
    },
    media: {
        minHeight: 300,
        paddingTop: '56.25%', // 16:9
    },
    mediaNoImage: {
        minHeight: 300,
        paddingTop: '56.25%', // 16:9
        background: `#D9D9D9`,
        opacity: 0.75
    },
    searchWrap: {
        width: `500px`
    },
    searchBy: {
        width: `125px`
    },
    searchBox: {
        marginLeft: 10,
        width: `300px`
    },
    contentBody: {
        [`-webkit-box-orient`]: `vertical`,
        display: `-webkit-box`,
        [`-webkit-line-clamp`]: 3,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: `normal`,
        color: `#fff`
    },
    contentPublished: {
        textAlign: `right`,
        marginTop: theme.spacing(4),
        display: `inline-block`,
        width: `100%`
    },
    cardHeader: {
        display: `block`,
        width: 325,
        cursor: `pointer`,
        textDecoration: `none`,
        whiteSpace: `nowrap`,
        overflow: `hidden`,
        textOverflow: `ellipsis`
    },
    navLink: {
        cursor: `pointer`,
        textDecoration: `none`
    },
    contentAction: {
        position: `absolute`,
        height: 65,
        bottom: 0,
        width: `100%`,
        color: `#eee`,
        background: `#4E108B`,
        textAlign: `center`,
        justifyContent: 'space-around'
    },
    blogIcon: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));
const BlogList = props => {
    const classes = useStyles();
    const auth = useSelector(state => state.firebase.auth);
    const searchRef = useRef(null);
    const [allPosts, setAllPosts] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [searchBy, setSearchBy] = useState(`All`);
    const [search, setSearch] = useState(null);
    const [isGridView, setIsGridView] = useState(true);
    const history = useHistory();
    const _ = deepdash(lodash);

    useFirestoreConnect([
        { collection: 'posts', orderBy: ['views','desc'] }
    ]);

    const { posts } = useSelector((state) => state.firestore.ordered);

    useEffect(() => {
        firebaseAnalytics.logEvent("Library Accessed",auth.email);
    }, []);

    useEffect(() => {
        if (posts && posts.length > 0 && posts !== undefined) {
            setAllPosts(posts);
        }
    }, [posts]);

    const filteredSearch = allPosts.filter(post => post.isPublished);

    useEffect(() => {
        showContentGrid(filteredPosts)
        showContentList(filteredPosts)
    }, [filteredPosts]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!search)
                setFilteredPosts(filteredSearch);
        }, 250);
        return () => clearTimeout(timer);

    }, [filteredSearch]);

    const handleSearchByChange = event => {
        setSearchBy(event.target.value);
        setSearch('');
        setFilteredPosts(filteredSearch);
        searchRef.current.focus();
    }
    const handleSearch = event => {
        setSearch(event.target.value);
        const searchTerm = event.target.value.toString().toLowerCase();

        if (event.target.value.length < 3)
            setFilteredPosts(filteredSearch)
        else
            switch (searchBy) {
                case 'Tags':
                    setFilteredPosts(filteredSearch.filter(term => _.findIndex(_.flattenDeep(term.tags), function (obj) { return obj.value.toString().toLowerCase() === searchTerm })))
                    break;
                case 'Category':
                    setFilteredPosts(filteredSearch.filter(term => term.category.label.toLowerCase() === searchTerm))
                    break;
                case 'Title':
                    setFilteredPosts(filteredSearch.filter(term => term.title.toString().toLowerCase().indexOf(searchTerm) !== -1))
                    break;
                case 'Author':
                    setFilteredPosts(filteredSearch.filter(term => term.author.toString().toLowerCase().indexOf(searchTerm) !== -1))
                    break;
                default:
                    setFilteredPosts(filteredSearch.filter(term => JSON.stringify(term).toLowerCase().includes(searchTerm)));
                    break;
            }
    }

    const redirectToCreate = (id = false) => {
        if (id) {
            history.push(`/create/blogs/${id}`);
        } else {
            history.push("/create/blogs");
        }
    };

    const redirectToBack = (id = false) => {
        history.push("/library");
    };

    const redirectToView = id => {
        if (id) {
            history.push(`/blogs/${id}`);
        }
    };
    const showActions = (item) => (
        <React.Fragment>
            <Box className={classes.blogIcon}>
                <Icon>visibility</Icon>&nbsp;
                <Typography variant="body2">{item.views}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>thumb_up_off_alt</Icon>&nbsp;
                <Typography variant="body2">{item.likedby && item.likedby.length}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>favorite</Icon>&nbsp;
                <Typography variant="body2">{item.lovedby && item.lovedby.length}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>share</Icon>&nbsp;
                <Typography variant="body2">{item.shared}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>comment</Icon>
                &nbsp;
                <Typography variant="body2">{item.commentscount}</Typography>
            </Box>
        </React.Fragment>
    );

    const showContentGrid = rows => {
        if (!rows.length) {
            return ``;
        }
        console.log(rows);
        return <Grid container spacing={3}>
            {rows.map((row) => {
                const img = row.images && row.images.length ? row.images[0] : null;
                return <Grid item xs={4} key={row.title}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={<Link className={classes.cardHeader} onClick={() => redirectToView(row.id)}>{row.title}</Link>}
                            subheader={row.blogId.label}
                        />
                        {img ?
                            <CardMedia
                                className={classes.media}
                                image={img}
                                title={row.title}
                            /> : <div className={classes.mediaNoImage}></div>
                        }
                        <CardContent className={classes.content}>
                            <Typography className={classes.contentBody} variant="body2" color="textSecondary" component="p">
                                {ReactHtmlParser(row.body)}
                            </Typography>
                            {row.isPublished &&
                                <Typography variant="caption" className={classes.contentPublished}>{dayjs(new Date(row.lastUpdate.seconds*1000)).format('MMMM D YYYY, h:mm A')}</Typography>
                            }
                        </CardContent>
                        <CardActions disableSpacing className={classes.contentAction}>
                            {/* <IconButton onClick={() => redirectToCreate(row.id)} aria-label="edit">
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton aria-label="edit">
                                <Icon>delete</Icon>
                            </IconButton> */}
                             {showActions(row)}
                        </CardActions>
                    </Card>
                </Grid>
            })}
        </Grid>;
    };

    const showContentList = rows => {
        if (!rows.length) {
            return ``;
        }
       
        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Blog Name</TableCell>
                    <TableCell>Post Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.title}>
                        <TableCell component="th"><b>{row.blogId.label}</b></TableCell>
                        <TableCell component="th" scope="row">
                            <Link className={classes.navLink} onClick={() => redirectToView(row.id)}>{row.title}</Link>
                        </TableCell>
                        <TableCell>{row?.category?.label}</TableCell>
                        <TableCell>{dayjs(new Date(row.lastUpdate.seconds*1000)).format('MMMM D YYYY, h:mm A')}</TableCell>
                        <TableCell><IconButton onClick={() => redirectToCreate(row.id)}><Icon>edit</Icon></IconButton></TableCell>
                        <TableCell><IconButton><Icon>delete</Icon></IconButton></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>;
    };

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Box alignItems="flex-start" paddingTop={2} paddingBottom={4} display="flex">
                    <Box flexGrow={1}>
                        <Button
                            variant="outlined"
                            onClick={() => redirectToBack()}
                        ><Icon>arrow_back</Icon>&nbsp;Back</Button>
                    </Box>
                    <Box className={classes.searchWrap}>
                        <FormControl className={classes.searchBy} size="small" variant="outlined">
                            <InputLabel id="search-by-label">Search By</InputLabel>
                            <Select
                                labelId="search-by-label"
                                value={searchBy}
                                label="Search By"
                                onChange={handleSearchByChange}>
                                <MenuItem value={`All`}>All</MenuItem>
                                <MenuItem value={`Tags`}>Tags</MenuItem>
                                <MenuItem value={`Category`}>Category</MenuItem>
                                <MenuItem value={`Title`}>Title</MenuItem>
                                <MenuItem value={`Author`}>Author</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.searchBox}>
                            <TextField
                                label="Search"
                                size="small"
                                value={search}
                                variant="outlined"
                                onChange={handleSearch}
                                ref={searchRef} />
                        </FormControl>
                    </Box>
                    <Box alignSelf="flex-end">
                        <IconButton size="small" onClick={() => setIsGridView(true)} variant="outlined" color={isGridView ? "secondary" : "primary"} ><Icon>grid_view</Icon></IconButton>
                        <IconButton size="small" onClick={() => setIsGridView(false)} variant="outlined" color={isGridView ? "primary" : "secondary"}><Icon>view_list</Icon></IconButton>
                    </Box>
                </Box>
                {isGridView && (showContentGrid(filteredPosts))}
                {!isGridView && (showContentList(filteredPosts))}
            </Container>
        </React.Fragment>
    );
};

export default BlogList;
