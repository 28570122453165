import { SET_OPENED_RIDDLE } from "./types";
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from "./uiAction";
import store from "../store";


export const setOpendRiddle = (payload) => (dispatch) => {
  dispatch({ type:SET_OPENED_RIDDLE, payload });
};

export const getRiddle = (payload) => async(dispatch,getState) => {
  const { firestore } = store;
  // var name=payload.email.substring(0, payload.email.lastIndexOf("@"))
  let riddle = [];
  await firestore 
    .collection('riddles')
    .where('isactive','==',true)
    .get()
    .then(
      querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          riddle.push(doc.data());
        }
        )
      }).catch((error) => {
        console.log(error, "Error in getting Riddles");
      });

  dispatch({ type: SET_OPENED_RIDDLE, payload:riddle });
};

