import React, { useState } from 'react';
import { Button, FormControl, Input, InputLabel, Link, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/actions/userAction';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    margin: `${theme.spacing.unit * 7}px 0`,
    textAlign: 'center'
  }
}));

const ForgetPassword = () => {
  const classes = useStyles();
  const [email, setUserEmail] = useState(null);
  const dispatch = useDispatch();

  const onChange = event => {
    const { value } = event.target;
    setUserEmail(value);
  };

  const onSubmit = event => {
    event.preventDefault();
    dispatch(resetPassword(email));
  };

  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="Email ID">Email ID</InputLabel>
          <Input id="Email ID" name="userName" onChange={onChange} autoComplete="Email ID" value={email} autoFocus />
        </FormControl>

        <FormControl className={classes.submit} fullWidth>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >Reset Password</Button>
        </FormControl>
        <Link href="/login" variant="body2">
          {"Login"}
        </Link>
      </form>
    </React.Fragment>
  );
}

export default ForgetPassword;
