import { Dialog, DialogContent, DialogTitle, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import logo from '../../../assets/images/homepage_bg.svg';
const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '100%', // Fix IE 11 issue.
    },
    title: {
        color: theme.palette.primary.main,
        padding: `${theme.spacing() * 8}px ${theme.spacing() * 5}px ${theme.spacing() * 5}px`
    },
    content: {
        padding: `0 ${theme.spacing() * 10}px ${theme.spacing() * 5}px `
    },
    dialogBg: {
        width: `100%`,
        opacity: `0.65`,
        marginTop: `-125px`
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(5),
        color: theme.palette.grey[500],
    }
}));

const WithFullDialog = (Component, title) => {
    const classes = useStyles();
    const history = useHistory();

    const onClose = () => {
        history.push("/");
    };

    return (
        <React.Fragment>
            <img src={logo} alt="Explore, Create, Share" className={classes.dialogBg} />
            <Dialog open={true} className={classes.dialog}
                BackdropProps={{ invisible: true }}
                aria-labelledby="register-dialog">
                <DialogTitle disableTypography className={classes.title}>
                    <Typography variant="h5" align="center">{title}</Typography>
                    <IconButton onClick={onClose} className={classes.closeButton}>
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Component />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default WithFullDialog;
