import {
  CLEAR_TODO_INPUT,
  SET_ADD_TODOS,
  SET_GET_TODOS,
  SET_TODOS_ACTIONS
} from "../actions/types";

export const INITIAL_STATE = {
  openedTodos: {
    id: null,
    todos: [],
    userid: null
  },
  savedTodos: {
    id: null,
    todos: [],
    userid: null
  }
};

const todosReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GET_TODOS:
      return {
        ...state,
        openedTodos: payload,
      };
    case SET_ADD_TODOS:
      return {
        ...state,
        openedTodos: payload,
      };
    case SET_TODOS_ACTIONS:
      return {
        ...state,
        savedTodosActions: payload,
      };
    case CLEAR_TODO_INPUT:
      return {
        ...state,
        clearTodo: Date.now()
      };
    default:
      return state;
  }
};

export default todosReducer;
