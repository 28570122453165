import React, { useEffect, useState } from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { firebaseAnalytics } from "../../../base";
import lodash from "lodash-es";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import dayjs from "dayjs";
import CommentsMultiLevel from "../../Shared/Comments/CommentsMultiLevel";
import { showInfoSnackbar } from "../../../redux/actions/uiAction";
import { useDispatch, useSelector } from "react-redux";
import { convertKMBT } from "../../../helper/Helpers";
import { useLocation, useParams } from "react-router-dom";
import { EssayStatus } from "../../../helper/const";
import { saveEssay } from "../../../redux/actions/essayAction";

const useStyles = makeStyles((theme) => ({
  content: {
    display: `flex`,
    padding: theme.spacing(5),
  },
  mainContent: {
    padding: 20,
  },
  adSection: {
    width: `285px`,
    overflow: `hidden`,
    "&.preview": {
      height: `calc(100vh - 100px)`,
    },
  },
  mainImage: {
    marginTop: 10,
    textAlign: "center",
    "& image": {
      height: `300px`,
    },
  },
  actionIcons: {
    display: `flex`,
    justifyContent: `flex-end`,
  },
  viewIcon: {
    color: `lightgreen`,
  },
  favIcon: {
    color: `red`,
  },
  shareIcon: {
    color: `green`,
  },
  commentIcon: {
    color: theme.palette.primary.main,
  },
  likeIcon: {
    color: theme.palette.primary.main,
  },
  blogIcon: {
    display: `flex`,
    flexDirection: `column`,
    textAlign: `center`,
  },
}));

const EssayView = ({ isPreview = false, isReview = false }) => {
  const dispatch = useDispatch();
  const { essayId } = useParams();
  const auth = useSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const classes = useStyles();
  const [commentsCount, setcommentsCount] = useState(0);

  useFirestoreConnect([{ collection: "essay", doc: essayId }]);

  const { essay: essay_list } = useSelector((state) => state.firestore.ordered);
  const essay = essay_list && lodash.find(essay_list, { id: essayId });

  useEffect(() => {
    firebaseAnalytics.logEvent(
      "Essay Viewed",
      essay?.title + " visited by " + auth.email
    );
  }, []);

  useEffect(() => {
    if (essay && !isPreview) {
      let viewscount = essay.views ? essay.views + 1 : 1;
      firestore.collection("essay").doc(essay.id).update({
        views: viewscount,
      });
      firestore
        .collection("comments")
        .where("essayid", "==", essay.id)
        .get()
        .then(function (querySnapshot) {
          console.log(querySnapshot.size);
          setcommentsCount(querySnapshot.size);
        });
    }
  }, []);

  const submitReview = () => {
    dispatch(saveEssay({...essay, isReviewComplete: true }));
  }

  const handleLikedClick = () => {
    if (
      essay?.likedby?.indexOf(auth.uid) === -1 ||
      essay?.likedby === undefined
    ) {
      firestore
        .collection("essay")
        .doc(essay.id)
        .update({
          likedby: firestore.FieldValue.arrayUnion(auth.uid),
        });
    } else {
      dispatch(showInfoSnackbar("You had already liked this post!"));
    }
  };

  const handleLovedClick = () => {
    if (
      essay?.lovedby?.indexOf(auth.uid) === -1 ||
      essay?.lovedby === undefined
    ) {
      firestore
        .collection("essay")
        .doc(essay.id)
        .update({
          lovedby: firestore.FieldValue.arrayUnion(auth.uid),
        });
    } else {
      dispatch(showInfoSnackbar("You had already loved this post!"));
    }
  };

  const handleSharedClick = () => {
    let sharecount = essay.shared ? essay.shared + 1 : 1;
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    firestore.collection("essay").doc(essay.id).update({
      shared: sharecount,
    });
    dispatch(showInfoSnackbar("This essay is copied to share!"));
  };

  const showActions = () => (
    <Box className={classes.actionIcons}>
      <Box className={classes.blogIcon}>
        <IconButton className={classes.viewIcon}>
          <Icon>visibility</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(essay.views ? essay.views : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLikedClick}>
        <IconButton className={classes.likeIcon}>
          <Icon>thumb_up_off_alt</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(essay.likedby ? essay.likedby.length : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLovedClick}>
        <IconButton className={classes.favIcon}>
          <Icon>favorite</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(essay.lovedby ? essay.lovedby.length : "[]")}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleSharedClick}>
        <IconButton className={classes.shareIcon}>
          <Icon>share</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(essay.shared ? essay.shared : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon}>
        <IconButton className={classes.commentIcon}>
          <Icon>comment</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {commentsCount}
        </Typography>
      </Box>
    </Box>
  );

  const showEssay = () => {
    return (
      <React.Fragment>
        {isReview && 
        <Box textAlign="right" mb={3}>
          <Button variant="contained" color="primary" onClick={submitReview}>Submit Review</Button>
        </Box>
        }
        <Box textAlign="center" mb={3}>
          <Typography variant="h4">{essay.title}</Typography>
          {essay.category && (
            <Typography variant="subtitle2">{essay.category}</Typography>
          )}
          {essay.lastUpdated && (
            <Typography variant="subtitle" component="i">
              {dayjs(new Date(essay.lastUpdated)).format("MMMM D YYYY, h:mm A")}
            </Typography>
          )}
        </Box>
        <Box className={classes.mainImage}>
          <img src={essay.image} className={classes.image} />
        </Box>
        <Box mt={3} p={2}>
          <Typography variant="body2">
            {ReactHtmlParser(essay.content)}
          </Typography>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Container className={classes.content}>
      {essay && (
        <Card className={classes.mainContent}>
          {showEssay()}
          {showActions()}
          {essay.status !== EssayStatus.PUBLISHED && (
            <CommentsMultiLevel
              data={essay}
              id_field="essayid"
              collection="essay"
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default EssayView;
