import React, { } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from '../../../assets/images/homepage_bg.svg';
import logo from '../../../assets/images/homepage_bg.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing() * 5,
        overflow: `hidden`
    },
    landingBg: {
        width: "100%",
        marginTop: `-80px`,
    }
}));

const Landing = props => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <main className={classes.root}>
            <img src={logo} alt="Explore, Create, Share" className={classes.landingBg} />
            {/* <Logo preserveAspectRatio="XMinYMid slice" className={classes.landingBg} /> */}
            </main>
        </React.Fragment>
    );
};

export default Landing;
