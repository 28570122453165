import React from 'react';
import { TextField, InputAdornment, Icon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import { useFirestore } from "react-redux-firebase";
import { useHistory } from 'react-router-dom';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const useStyles = makeStyles((theme) => ({
    searchInput: {
        width: `100%`,
        borderRadius: `10px`
    }
}));

const SearchBox = () => {
    const firestore = useFirestore();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [openId, setopenId] = React.useState();
    const loading = open && options.length === 0;
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const postsList  = await firestore.collection('posts').get();
            await sleep(1e3);
            let searchList = []
            if (active) {
                postsList.forEach(doc => {
                    console.log(doc.id, '=>', doc.data());
                    searchList.push({id:doc.id,title:doc.data().title})
                  });
                setOptions(searchList);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const redirectToPost = id => {
        if (id) {
            history.push(`/blogs/${id}`);
        }
    };
    return (
        <Autocomplete
            id="search-box"
            freeSolo
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event, value) =>   redirectToPost(value.id)} 
            getOptionSelected={(option) => setopenId(option.id)}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={classes.searchInput}
                    label="Search here"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon>search</Icon>
                            </InputAdornment>
                        )
                    }}
                />
            )}
        />
    );
};

export default SearchBox;
