import React, { useEffect, useState } from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { firebaseAnalytics } from "../../../base";
import lodash from "lodash-es";
import {
  Box,
  Card,
  Container,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser from "react-html-parser";
import dayjs from "dayjs";
import CommentsMultiLevel from "../../Shared/Comments/CommentsMultiLevel";
import { showInfoSnackbar } from "../../../redux/actions/uiAction";
import { useDispatch, useSelector } from "react-redux";
import { convertKMBT } from "../../../helper/Helpers";
import { useParams } from "react-router-dom";
import { PoemStatus } from "../../../helper/const";

const useStyles = makeStyles((theme) => ({
  content: {
    display: `flex`,
    padding: theme.spacing(5),
  },
  mainContent: {
    padding: 20,
  },
  adSection: {
    width: `285px`,
    overflow: `hidden`,
    "&.preview": {
      height: `calc(100vh - 100px)`,
    },
  },
  mainImage: {
    marginTop: 10,
    textAlign: "center",
    "& image": {
      height: `300px`,
    },
  },
  actionIcons: {
    display: `flex`,
    justifyContent: `flex-end`,
  },
  viewIcon: {
    color: `lightgreen`,
  },
  favIcon: {
    color: `red`,
  },
  shareIcon: {
    color: `green`,
  },
  commentIcon: {
    color: theme.palette.primary.main,
  },
  likeIcon: {
    color: theme.palette.primary.main,
  },
  blogIcon: {
    display: `flex`,
    flexDirection: `column`,
    textAlign: `center`,
  },
}));

const PoemView = ({ isPreview = false }) => {
  const dispatch = useDispatch();
  const { poemId } = useParams();
  const auth = useSelector((state) => state.firebase.auth);
  const firestore = useFirestore();
  const classes = useStyles();
  const [commentsCount, setcommentsCount] = useState(0);

  useFirestoreConnect([{ collection: "poems", doc: poemId }]);

  const { poems: poem_list } = useSelector((state) => state.firestore.ordered);
  const poem = poem_list && lodash.find(poem_list, { id: poemId });

  useEffect(() => {
    firebaseAnalytics.logEvent(
      "Poem Viewed",
      poem?.title + " visited by " + auth.email
    );
  }, []);

  useEffect(() => {
    if (poem && !isPreview) {
      let viewscount = poem.views ? poem.views + 1 : 1;
      firestore.collection("poems").doc(poem.id).update({
        views: viewscount,
      });
      firestore
        .collection("comments")
        .where("poemId", "==", poem.id)
        .get()
        .then(function (querySnapshot) {
          console.log(querySnapshot.size);
          setcommentsCount(querySnapshot.size);
        });
    }
  }, []);

  const handleLikedClick = () => {
    if (
      poem?.likedby?.indexOf(auth.uid) === -1 ||
      poem?.likedby === undefined
    ) {
      firestore
        .collection("poems")
        .doc(poem.id)
        .update({
          likedby: firestore.FieldValue.arrayUnion(auth.uid),
        });
    } else {
      dispatch(showInfoSnackbar("You had already liked this post!"));
    }
  };

  const handleLovedClick = () => {
    if (
      poem?.lovedby?.indexOf(auth.uid) === -1 ||
      poem?.lovedby === undefined
    ) {
      firestore
        .collection("poems")
        .doc(poem.id)
        .update({
          lovedby: firestore.FieldValue.arrayUnion(auth.uid),
        });
    } else {
      dispatch(showInfoSnackbar("You had already loved this post!"));
    }
  };

  const handleSharedClick = () => {
    let sharecount = poem.shared ? poem.shared + 1 : 1;
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    firestore.collection("poems").doc(poem.id).update({
      shared: sharecount,
    });
    dispatch(showInfoSnackbar("This poem is copied to share!"));
  };

  const showActions = () => (
    <Box className={classes.actionIcons}>
      <Box className={classes.blogIcon}>
        <IconButton className={classes.viewIcon}>
          <Icon>visibility</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(poem.views ? poem.views : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLikedClick}>
        <IconButton className={classes.likeIcon}>
          <Icon>thumb_up_off_alt</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(poem.likedby ? poem.likedby.length : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleLovedClick}>
        <IconButton className={classes.favIcon}>
          <Icon>favorite</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(poem.lovedby ? poem.lovedby.length : "[]")}
        </Typography>
      </Box>
      <Box className={classes.blogIcon} onClick={handleSharedClick}>
        <IconButton className={classes.shareIcon}>
          <Icon>share</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {convertKMBT(poem.shared ? poem.shared : 0)}
        </Typography>
      </Box>
      <Box className={classes.blogIcon}>
        <IconButton className={classes.commentIcon}>
          <Icon>comment</Icon>
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {commentsCount}
        </Typography>
      </Box>
    </Box>
  );

  const showPoem = () => {
    return (
      <React.Fragment>
        <Box textAlign="center" mb={3}>
          <Typography variant="h4">{poem.title}</Typography>
          {poem.category && (
            <Typography variant="subtitle2">{poem.category}</Typography>
          )}
          {poem.lastUpdated && (
            <Typography variant="subtitle" component="i">
              {dayjs(new Date(poem.lastUpdated)).format("MMMM D YYYY, h:mm A")}
            </Typography>
          )}
        </Box>
        <Box className={classes.mainImage}>
          <img src={poem.image} className={classes.image} />
        </Box>
        <Box mt={3} p={2}>
          <Typography variant="body2">
            {ReactHtmlParser(poem.content)}
          </Typography>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Container className={classes.content}>
      {poem && (
        <Card className={classes.mainContent}>
          {showPoem()}
          {showActions()}
          {poem.status !== PoemStatus.PUBLISHED && (
            <CommentsMultiLevel
              data={poem}
              id_field="poemId"
              collection="poems"
            />
          )}
        </Card>
      )}
    </Container>
  );
};

export default PoemView;
