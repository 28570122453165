import React, { } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./TermsAndConditions.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing() * 5,
        overflow: `hidden`
    }
}));

const TermsAndConditions = props => {
    const classes = useStyles();

    return (
        <div className="terms-page">
            <div className={classes.root}>
                <div className="termsCondition">
                    <h1 style={{ textAlign: "center" }}>Terms and Conditions</h1>
                    <p>
                        We at Quillatwill.com would like to thank you for your interest in using
                        our website. We want to make sure that you feel settled in at
                        Quillatwill.com and that you understand the rules before joining our
                        humble website. In order to be a member of Quillatwill.com, you must
                        accept the following terms and conditions
                    </p>
                    <p>
                        The following is an agreement between Quillatwill.com ("us", "our" and
                        "we" ), and any person or other legal entity, ("you", "your" or "account
                        holder") who wishes to use the Quillatwill.com website. Whether as the
                        holder of an account on the Site ("Member") or merely to browse the
                        website (Members and other users of the Site are collectively described in
                        this agreement as "Users").
                    </p>
                    <h3>About Quillatwill.com</h3>
                    <p>
                        Quillatwill.com is a website owned by Badi Soch Innovations Pvt Ltd. The
                        website allows members to write stories, blogs as well as postcards online
                        and share them with other users. It also has a number of other resources
                        and activities to support aspiring writers.
                    </p>
                    <h3>Acceptance of Terms</h3>
                    <p>
                        These terms and conditions ("Terms") apply to all and any use of
                        Quillatwill.com. This includes any postings, emails and other
                        communications made to or using it. If you use Quillatwill.com, you agree
                        to comply with these terms. If you do not accept these terms,then you
                        should leave this website and discontinue use of the services immediately.
                        Please read this Agreement carefully and save it.
                    </p>
                    <h3>Quillatwill.com content</h3>
                    <p>
                        Unless permitted on this site, this website is for personal and
                        non-commercial use only, and may not be used for any purpose not expressly
                        permitted by this Agreement. In addition, you may not reproduce, copy,
                        duplicate, sell or resell any part of the Service.
                    </p>
                    <p>
                        Quillatwill.com owns all materials that appear on this website, including
                        any slogans, logos, designs, graphics, pictures, images, text, content,
                        images or other materials. All such rights of Quillatwill.com are hereby
                        asserted and reserved, and you may not make any unauthorized use of the
                        Content, i ncluding using Content in a way that is likely to cause
                        confusion among consumers, using Content in a commercial manner, or
                        otherwise infringing on Quillatwill.com's rights.
                    </p>
                    <p>
                        You are, however, allowed to print the stories or blogs that you as a
                        member of Quillatwill.com created on the website. Since Quillatwill.com is
                        a community site, we also use a range of emojis and pictures throughout
                        the site, such as in the comments and status updates.{" "}
                    </p>
                    <h3>User conduct</h3>
                    <h6>General Rules</h6>
                    <p>
                        These rules apply to all website visitors, including public visitors and
                        members of Quillatwill.com (Please ask a grown-up to read these for you,
                        if you do not understand):
                    </p>
                    <ol>
                        <li value={1}>
                            You must submit the requested information to Quillatwill.com during the
                            registration process, including a correct email address. When such
                            registration information is requested by the Service, the information
                            you provide must be true, accurate, current, and complete.
                        </li>
                        <li>
                            You are responsible for any activity that occurs under your username and
                            account. You may not transfer your account.
                        </li>
                        <li>
                            You are responsible for keeping your Member password secure and for
                            notifying Quillatwill.com immediately of any breach of security or
                            unauthorized use of your account.
                        </li>
                        <li>
                            You are responsible for maintaining the confidentiality of any personal
                            or personally identifiable data you upload and store on the Site and are
                            responsible for complying with all applicable laws governing collection,
                            storage, processing, use and transfer of such information.
                        </li>
                        <li>
                            You are solely responsible for your conduct on the Site and use of the
                            Service, and for any content, including but not limited to designs,
                            text, graphics, pictures, video, information, applications, software,
                            music, sound and other files (collectively, “Content”), that you
                            contribute to the Site.
                        </li>
                        <li>
                            You must not abuse, harass, threaten, impersonate or intimidate other
                            Users of Quillatwill.com
                        </li>
                        <li>
                            You may not use the Service for any illegal or unauthorized purpose.
                            International Users agree to comply with all local laws regarding online
                            conduct and acceptable content.
                        </li>
                        <li>
                            You must not create or submit unwanted emails or other messages to any
                            users of the Site.
                        </li>
                        <li>
                            You may not collect or harvest any personally identifiable information,
                            including account names, from the Site.
                        </li>
                        <li>
                            You may not use the Service to upload, transmit or link to Prohibited
                            Content. Generally, Prohibited Content includes Content or other
                            material that Quillatwill.com believes:
                            <ol>
                                <li value={1}>
                                    is abusive, deceptive, pornographic, obscene, defamatory,
                                    slanderous, offensive, encourages conduct that would violate or
                                    violates any law, harassing, hateful, racially or ethnically
                                    offensive, or otherwise inappropriate;
                                </li>
                                <li>
                                    comprises material that is copyrighted or protected by trade secret
                                    or otherwise subject to third party proprietary rights, including
                                    privacy and publicity rights, unless express written permission of
                                    the owner is obtained to use the material and to grant all of the
                                    license rights granted herein;
                                </li>
                                <li>violates or otherwise encroaches on the rights of others;</li>
                                <li>
                                    contains viruses, worms, corrupt files, Trojan horses or other forms
                                    of corruptive code, or any other content which may compromise the
                                    Service;{" "}
                                </li>
                                <li>advocates illegal activity;</li>
                                <li>harms anyone, including minors; or</li>
                                <li>provides a link to any of the above.</li>
                            </ol>
                        </li>
                        <li>
                            Quillatwill.com has the sole discretion to determine whether Content is
                            Prohibited Content, and any Content submitted to the Service may be
                            subject to examination from time to time. Quillatwill.com may delete,
                            move, and edit Content for any reason, at any time, without notice.
                            Quillatwill.com in no way guarantees the accuracy, non-infringement,
                            quality or appropriateness of Content available through the Service.
                        </li>
                        <li>
                            You may not use or launch any automated system, including without
                            limitation, "robots," "spiders," "offline readers," etc., that accesses
                            the Service in a manner that sends more request messages to the
                            Quillatwill.com servers in a given period of time than a human can
                            reasonably produce in the same period by using a conventional on-line
                            web browser in ordinary and customary usage.
                        </li>
                    </ol>
                    <p>
                        Any breach or non-compliance of any of these User Conduct terms will
                        entitle Quillatwill.com to immediately and without notice (i) terminate
                        any Member account, which could result in the permanent loss of any data
                        in the account and any User Content of the Member, and (ii) block you from
                        any further use of the Site and Service.
                    </p>
                    <h3>Third Parties</h3>
                    <p>
                        From time to time, we may provide links to third party websites,
                        especially on our blog. Those links are provided as a service to our
                        users. We do not sponsor or endorse those third parties, nor do we control
                        their activities. You should review any website terms and conditions,
                        privacy policies, and other disclosures that those sites provide before
                        interacting with those third parties, or providing them with information
                        about yourself. Please be aware that the terms of our Privacy Policy do
                        not apply to these outside websites.
                    </p>
                    <h3>The Quillatwill.com Blog</h3>
                    <p>
                        These Terms also apply to use of our blog. You are responsible for any
                        comments you post here, Quillatwill.com will not be held liable for any
                        offensive or illegal content (as described above). Any competitions run on
                        the blog, will include their own terms and conditions within their blog
                        post, along with a description of the prize.
                    </p>
                    <p>Personal Information</p>
                    <p>
                        Sometimes we ask you to submit some personal information about yourself.
                        Read our Privacy policy for more details on how this information is used
                        and what we collect.
                    </p>
                    <h3>Content Contributed to the Site by Members</h3>
                    <p>
                        The views expressed in blogs, stories or postcards are of the individual
                        creators or writers of those stories and not those of Quillatwill.com,
                        unless specified otherwise by Quillatwill.com. In particular but without
                        limitation to the above Quillatwill.com are not responsible for and
                        disclaims all liability in respect of any comments, views or remarks
                        expressed in stories or blogs created by users of the website.
                        Quillatwill.com gives no warranty and makes no representation as to the
                        accuracy or validity of the information and material contained within the
                        user created stories and shall not be responsible for any loss or damage
                        caused as a result of any use of such material. It is also important to
                        note that Quillatwill.com reserves the right to remove content from the
                        Site at any time, without notice, for any reason, or for no reason, in its
                        sole discretion.
                    </p>
                    <p>
                        Your use of any information or materials on this website is entirely at
                        your own risk, for which we shall not be liable. It shall be your own
                        responsibility to ensure that any products, services or information
                        available through this website meet your specific requirements.
                    </p>
                    <h3>What is Copyright?</h3>
                    <p>
                        Copyright protects writers or creators of content from having their work
                        copied and shared without permission. Copyright is automatically given to
                        the creator of content, the moment the content is created or published. In
                        the case of Quillatwill.com, a writer automatically gains copyright over
                        their book the moment they hit the “Publish” button.
                    </p>
                    <p>
                        Please remember that ideas can NOT be copyrighted. When an author writes a
                        blog or book on Quillatwill.com, it is the text or sentences that they
                        used that are copyrighted ONLY. Also images used in books can not be
                        copyrighted, as these are public domain, so anyone can use these.
                    </p>
                    <h4>Copyright &amp; Plagiarism</h4>
                    <p>
                        Copyright is very important to Quillatwill.com. All content on this
                        website including blog posts, printables and stories written by our users
                        are copyrighted. Below are our general rules in regards to copyright.
                    </p>
                    <p></p>
                    <ol>
                        <li value={1}>
                            You can not share content, such as PDFs, printables and stories by our
                            members on third-party (external) websites without written permission
                            from Quillatwill.com.
                        </li>
                        <li>
                            If you receive permission from Quillatwill.com to share content on your
                            site, then you MUST attribute our site as the original source of the
                            content AND provide a do-follow link back to our website.
                        </li>
                        <li>
                            You CAN NOT copy and paste any content from our site onto your external
                            site. This includes everything from website copy to stories written by
                            our users. If you are caught copying our content, you will be asked to
                            remove it straight away. Failure to remove this content could result in
                            legal action.
                        </li>
                        <li>
                            Copyright to all content, including books by our users ultimately
                            belongs to Quillatwill.com. This means users (and members) can not sell
                            books created on Quillatwill.com or share them on third-party sites
                            without written permission from Quillatwill.com.
                        </li>
                    </ol>
                    <p />
                    <h3>Copyright </h3>
                    <p>
                        Apart from general copyright guidelines, we also have specific guidelines
                        for stories and books written by our users/members. Below are our
                        copyright guidelines relating to books published on Quillatwill.com:
                    </p>
                    <p></p>
                    <ol>
                        <li value={1}>
                            If another user on Quillatwill.com copies your blog or book, you can
                            report that book and it will be removed from the public library.{" "}
                        </li>
                        <li>
                            Alternatively, if your book on Quillatwill.com gets copied by a
                            third-party (external) website, please let us know on
                            support@quillatwill.com.. Our team will then contact that website owner
                            asking them to remove your content.{" "}
                        </li>
                        <li>
                            The download option for books should only ever be used for PERSONAL USE
                            ONLY (commercial use is NOT allowed). For example, a user can print out
                            our books to read at home before bedtime.
                        </li>
                        <li>
                            It is illegal to copy or plagiarise the content on another website
                            without written permission from the owner.
                        </li>
                    </ol>
                    <p />
                    <p>Intellectual Property Rights of Others</p>
                    <p>
                        Quillatwill.com respects intellectual property rights. If you believe that
                        any Content on this Site infringes on another's intellectual property
                        rights you may email us at support@Quillatwill.com. Quillatwill.com
                        reserves the right to remove Content and User Content without prior
                        notice. Quillatwill.com may also terminate a Users' access to the Site,
                        immediately and without notice, if Quillatwill.com believes, in its sole
                        discretion that the User is infringing any third party rights ( including
                        but not limited to any copyright, patent, trademark, trade secret, other
                        intellectual property rights, moral rights, privacy rights or publicity
                        rights).
                    </p>
                    <h3>Liability</h3>
                    <p>
                        The Quillatwill.com website will be maintained on an "as is" and "as
                        available" basis. Quillatwill.com gives no guarantee that the website will
                        always be available, or that it will work perfectly. To the maximum extent
                        permitted by law, Quillatwill.com excludes liability for any loss or
                        damage of any kind arising out of your use of Quillatwill.com. We will not
                        be liable to you in respect of any loss or corruption of any data on the
                        site or any stories you created.
                    </p>
                    <h3>Termination</h3>
                    <p>
                        Quillatwill.com has the right to terminate this Agreement and immediately
                        remove, cancel or suspend access to and use of Quillatwill.com for any
                        reason, including if you break the rules or these Terms, or if
                        Quillatwill.com is bought by another company, or for any other reason.
                    </p>
                    <h3>Linking to Quillatwill.com</h3>
                    <p>
                        Anyone linking to Quillatwill.com must not imply or suggest affiliations
                        to Quillatwill.com that do not exist, and the link may not refer to or
                        portray Quillatwill.com in a false, misleading, derogatory or otherwise
                        offensive manner.
                    </p>
                    <h3>Miscellaneous</h3>
                    <p>
                        Your use of this website and any dispute arising out of such use of the
                        website is subject to the laws of Hyderabad, India.
                    </p>
                    <h3>Changes to Terms </h3>
                    <p>
                        Quillatwill.com may make alterations to these Terms from time to time.
                        These changes shall become effective immediately as soon as they are
                        posted on this site.
                    </p>
                    <h3>Questions?</h3>
                    <p>
                        All questions, comments or enquiries should be sent by email to:
                        support@Quillatwill.com
                    </p>
                    <p>Date: 24th Dec 2021 </p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
