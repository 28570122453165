import React from "react";
import { Box } from "@material-ui/core";

const SideBar = props => {
    return (
        <React.Fragment>
            <Box color='white' bgcolor="#0078C7">
                {/* #4E108B #0078C8*/}
                {props.children}
            </Box>
        </React.Fragment>
    );
};

export default SideBar;
