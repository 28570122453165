import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  shape: {
    borderRadius: 10,    // defaults to 4
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#FF8204',
      alt: '#61CBE5',
      altDark: '#01234E',
      contrastText: '#fff'
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    }
  },
  sideNab: {
    bg: '#61CBE5'
  },
  spacing: 4,
  overrides: {
    MuiBackdrop: {
      background: '#39296B'
    }
  }
});

export default theme;
