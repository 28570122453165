import {
  SET_OPENED_COMMENT,
  SET_NEW_COMMENT,
  SET_SAVED_COMMENT,
  SET_LIST_COMMENT,
} from "./types";
import { showErrorSnackbar, showSuccessSnackbar } from "./uiAction";
import store from "../store";
export const setOpenedComment = (payload) => (dispatch) => {
  dispatch({ type: SET_OPENED_COMMENT, payload });
};

export const setAddNewComment = (payload) => async (dispatch, getState) => {
  try {
    const data = Object.values(payload)[0];
    const { firestore } = store;
    await firestore
      .add({ collection: "comments" }, payload)
      .then((res) => {
        payload.id=res.id
        dispatch(showSuccessSnackbar("Comment Added Successfully"));
        dispatch({ type: SET_NEW_COMMENT, payload });
      })
      .catch((error) => {
        console.log(error, "Error in saving comment");
        dispatch(showErrorSnackbar("Error in saving comment"));
      });
  } catch (error) {
    console.log(error, "Error in saving Comment");
    dispatch(showErrorSnackbar("Error in saving Comment"));
  }
};

export const setSavedComment = (payload) => async (dispatch, getState) => {
  console.log('replayu',payload)
  // try {
  //   const { firestore } = store;
  //   await firestore
  //     .collection("comments")
  //     .doc(docId[0])
  //     .update(docId[1])
  //     .then(() => {
  //       payload.savedComment = payload;
  //       dispatch(showSuccessSnackbar("Comment Updated Successfully"));
  //       dispatch({ type: SET_SAVED_COMMENT, payload });
  //     })
  //     .catch((error) => {
  //       console.log(error, "Error in saving reply");
  //       dispatch(showErrorSnackbar("Error in saving reply"));
  //     });
  //   // }
  // } catch (error) {
  //   console.log(error, "Error in saving Comment");
  //   dispatch(showErrorSnackbar("Error in saving Comment"));
  // }
  // dispatch({ type: SET_SAVED_COMMENT, payload });
};

export const setListComments = (payload) => async (dispatch) => {
  try{
  const { firestore } = store;
  await firestore
    .collection("comments")
    .where("postid", "==", payload.postid)
    .get()
    .then((docRef) => {
      console.log('getCommentList',docRef)
      payload.setListComments = docRef;
      dispatch({ type: SET_LIST_COMMENT, payload });
    })
    .catch((error) => {
      console.log(error, "Error in getting Blogs");
    });
} catch (error) {
  console.log(error, "Error in getting Blogs");
}
dispatch({ type: SET_LIST_COMMENT, payload });

};
