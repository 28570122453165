import { SET_ADD_TODOS, CLEAR_TODO_INPUT } from "./types";
import { showErrorSnackbar, showSuccessSnackbar } from "./uiAction";
import store from "../store";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { fireDB } from "../../base"

export const clearTodos = () => (dispatch) => {
  dispatch({ type: CLEAR_TODO_INPUT });
};

export const addTodos = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { firestore } = store;
  await firestore.add({ collection: "todos" }, {
    todos: [payload],
    userid: auth.uid
  }).then((resp) => {
    payload.id = resp.id;
    dispatch({ type: SET_ADD_TODOS, payload });
    dispatch(clearTodos());
    dispatch(showSuccessSnackbar("Todos Added Successfully!"));
  });
};

export const saveTodos = (payload) => async (dispatch, getState) => {
  const { todos: openedTodos } = getState().firestore.ordered;
  const docId = openedTodos ? openedTodos[0].id : null;
  const todosRef = doc(fireDB, "todos", docId);
  await updateDoc(todosRef, {
    todos: arrayUnion(payload)
  })
    .then(() => {
      dispatch(showSuccessSnackbar("Todos Set Successfully!"));
      dispatch(clearTodos());
    }).catch((error) => {
      console.log(error, "Error in updating Todos");
      dispatch(showErrorSnackbar("Error in updating Todos"));
    });
};

export const saveTodosActions = (payload, doc) => async (dispatch, getState) => {
  const { firestore } = store;
  firestore.collection("todos").doc(doc.id).update({ 'todos': payload })
    .then(() => {
      firestore.collection('todos').doc(doc.id).get();
    }).catch((error) => {
      console.log(error, "Error in updating Todo");
    });
};