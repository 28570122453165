import {
  SEND_MAILS,
  GET_BLOG_MAILS
  } from "../actions/types";
  
  export const INITIAL_STATE = {
    notification: {
    from: null,
    to:[],
    content:null,
    image:null
    },
    blog_emails:{}   
  };
  
  const sendMailsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_BLOG_MAILS:
        return {
          ...state,
          blog_emails: payload,
        };
        case SEND_MAILS:
          return {
            ...state,
            notification: payload,
          };                 
      default:
        return state;
    }
  };
  
  export default sendMailsReducer;
  