import { SET_OPENED_JOURNAL, SET_GET_JOURNAL_PAGE,SET_SAVED_JOURNAL, SET_ADD_JOURNAL_PAGE } from "./types";
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from "./uiAction";
import store from "../store";
import dayjs from "dayjs";
import { INITIAL_STATE as journalInitialState } from "../reducers/journalReducer";

export const setOpenedJournal = (payload) => (dispatch) => {
  dispatch({ type: SET_OPENED_JOURNAL, payload });
};


export const clearJournal = () => (dispatch) => {
  const payload = journalInitialState.openedJournal;
  dispatch({ type: SET_OPENED_JOURNAL, payload });
};

export const getJournalPage = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { openedJournal } = getState().journal;
  const { firestore } = store;
  //const payload = journalInitialState.openedJournal;
  const on =payload.on.replaceAll('-','/');
  const journalPage = await firestore
  .collection('journal')
  .where('userid', '==', openedJournal.userid)
  .where('on','==',new Date(on))
  .limit(1)
  .get()
  if(journalPage.docs.length==0) {
    openedJournal.page = '';
    openedJournal.events = '';
    openedJournal.gratefulfor = '';
    payload.page = '';
    payload.events = '';
    payload.gratefulfor = '';
  }
  else{
    openedJournal.id = journalPage.docs[0].id;
    openedJournal.page = journalPage.docs[0].data().page;
    openedJournal.events = journalPage.docs[0].data().events;
    openedJournal.gratefulfor = journalPage.docs[0].data().gratefulfor;
    payload.id = journalPage.docs[0].id;
    payload.page = journalPage.docs[0].data().page;
    payload.events = journalPage.docs[0].data().events;
    payload.gratefulfor = journalPage.docs[0].data().gratefulfor;
  }
  dispatch({ type: SET_GET_JOURNAL_PAGE,payload })
};

export const addJournalPage = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { openedJournal } = getState().journal;
  const { firestore } = store;
    await firestore.add({ collection: "journal" }, {
      page:payload.page,
      events: payload.events,
      gratefulfor: payload.gratefulfor,
      userid: auth.uid,
      on:new Date(payload.on.replaceAll('-','/'))
    }).then((resp) => {
      payload.id = resp.id      
      dispatch({ type: SET_ADD_JOURNAL_PAGE, payload });
    });
};

export const saveJournalPage = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { openedJournal } = getState().journal;
  const { firestore } = store;
  payload.on = new Date(payload.on.replaceAll('-','/'));
  firestore.collection("journal").doc(openedJournal.id).update(payload)
  .then(() => {
    dispatch({ type: SET_SAVED_JOURNAL, openedJournal });
  }).catch((error) => {
    console.log(error, "Error in updating Journal");
    dispatch(showErrorSnackbar("Error in updating Journal"));
  });
};