import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, TextField, Typography, Grid, Container, Divider, Button } from "@material-ui/core";
import { setPostCardDetails, getSentPostCards, getReceivedPostCards,  setPostCardImage} from "../../../../redux/actions/postCardAction";
import { useDispatch, useSelector } from "react-redux";
import photoURL from "../../../../assets/images/child-avatar.png";
import store from "../../../../redux/store";
import { storage } from "../../../../base";
import { showErrorSnackbar } from "../../../../redux/actions/uiAction";
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { uploadFromBlobAsync } from "../../../../uploader";
const useStyles = makeStyles(() => ({
    root: {
        '& MuiBox-root MuiBox-root-131 makeStyles-uploadBtnWrapper-232': {
            width: "100%"
        }
    },
    content: {
        flexGrow: 1
    },
    mainContent: {
        display: 'flex',
        justifyContent: 'center'
    },
    boxShadow: {
        //     boxShadow: '0 0 0 5px red',
        //     outline: 'dashed 5px yellow',
        // background: 'white',
        // border: "8px ssolid #E9DDFD",
        borderRadius: 25,
        width: '65%',
        padding: "30px 30px 0 30px",
        // borderImage: `url(${diamond})`

    },
    postCardContainer: {
        position: 'relative',

    },
    postCardImage: {
        width: `110px`,
        display: `flex`,
        justifyContent: `end`
    },
    userAvatar: {
        display: 'inline-block',
        marginRight: `15px`
    },
    divider: {
        backgroundColor: 'black',
        padding: '0px 1px',
        margin: '0px 20px'
    },
    contentBox: {
        backgroundColor: "#ffff",
        borderRadius: "10px",
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        padding: 10

    },
    uploadBtnWrapper: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
        width: "100%",
        borderRadius: "20px",
    },
    myFile: {
        fontSize: '100px',
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        width: '120px',
        cursor: 'pointer',
        width: "100%",
        height: "100%"
    },
    next: {
        marginLeft: '20px',
        cursor: 'pointer'
    },

    contentArea: {
        alignItems: 'end',
        justifyContent: 'center'
    },
    heading: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'

    },
    postName: {
        "&> div": {
            backgroundColor: "#fff !important",
            color: 'hsl(0, 0%, 20%)',
            borderRadius: 4,
        },
        "& input": {
            padding: `10px !important`,
            fontSize: 14
        },
        "& input::before, & input::after": {
            display: `none`
        }
    },
    boxalign: {
        marginTop: "20px"
    },
    '.MuiOutlinedInput-input ': {
        padding: "13.5px 14px"
    }

}));
const PostCardForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [postcardContentData, setpostcardContentData] = useState({});
    const postcardImageData = useSelector(state => state.postCard.postCardImage)
    const [email, setEmail] = useState([])
    const previewPost = useSelector(state => state.postCard.postCard)
    const [details, setDetails] = useState({
        content: "",
        name: "",
    })
    const auth = useSelector(state => state.firebase.auth);
    const chooseImage = useSelector(state => state.images.image)

    useEffect(() => {
        dispatch(getReceivedPostCards(auth))
        dispatch(getSentPostCards(auth))
        if (postcardContentData) {
            setpostcardContentData(postcardContentData);
        }
    }, [postcardContentData]);
    useEffect(()=>{
        if(previewPost.length!==0){
            setDetails({content:previewPost.content,name:previewPost.name})
            if(previewPost.to!==undefined){
                previewPost.to.map((item)=>{
                    email.push(item.email)
                })
            }
        }
    },[previewPost])
    const handleDetails = (e) => {
        if(e.target.value.length <= 400){
            const { name, value } = e.target;
            setDetails({ ...details, [name]: value });
         }
         else{
            dispatch(showErrorSnackbar('Please enter less than 400 characters'))
         }
    }

    const uploadImage = async(e) => {
        let image = e.target.files[0]
        // let name= bloburl.name
         let userId=auth.uid
        await storage.ref(`/users/${userId}/${image.name}`).put(image)
        .on("state_changed", console.log, console.error, () => {
            storage.ref(`/users/${userId}/`).child(image.name).getDownloadURL()
                .then((url) => {
                    dispatch(setPostCardImage(url))
                    console.log(url)
                })
        });
    }
    const handleNext = async () => {
        var date = new Date()
        if (details.content === "") {
            dispatch(showErrorSnackbar('Please enter the  content'))
        }
        // else if (details.name === "") {
        //     dispatch(showErrorSnackbar('Please enter the name'))
        // }
        else if (email.length === 0) {
            dispatch(showErrorSnackbar("Please enter the email address"))
        }
        else if (postcardImageData === "") {
            dispatch(showErrorSnackbar('Please upload or select image'))
        }
        else if (postcardImageData !== '' && details.content !== "" && email.length !== "") {
            var emailList = []
            email.map((item) => {
                let data =
                {
                    email: item,
                    userId: auth.uid
                }
                emailList.push(data)
            })
            console.log('email', emailList)
            try {
                const postCard = {
                    content: details.content,
                    name: auth.displayName,
                    userId: auth.uid,
                    from:auth.email,
                    to: emailList,
                    image: postcardImageData,
                    date: date.toString()
                }
                props.handleGoTo()
                dispatch(setPostCardDetails(postCard));
            }
            catch (error) {
                console.log(error)
            }
        }
        else { return }
    }

    return (
        <React.Fragment>
            <Container className="postcard-root">
                <Box className={classes.mainContent}>
                    <Box className={classes.boxShadow}>
                        <Grid container className="border-sent-receives">
                            <Grid item xs={12} style={{padding:'20px'}}>
                                <h4 className="text-center">Post Card</h4>
                                <TextField className={classes.contentBox}
                                    id="standard-multiline-static"
                                    multiline
                                    rows={10}
                                    name="content"
                                    fullWidth
                                    value={details.content}
                                    onChange={handleDetails}
                                    InputProps={{ disableUnderline: true }}
                                    placeholder="Write your content here"
                                    />
                                {/* <Box className={classes.boxalign}>
                                    <TextField className="inputRounded" id="outlined-basic" name="name" variant="outlined" onChange={handleDetails} fullWidth placeholder="Name" value={details.name} />
                                </Box> */}
                                <Box className={classes.boxalign}>
                                    <div>
                                        <ReactMultiEmail
                                            style={{ borderRadius: "20px", fontSize: "16px", height: "55px", padding: "10px" }}
                                            emails={email}
                                            onChange={_emails => {
                                                this.setState({ emails: _emails });
                                            }}
                                            placeholder="Email Address"
                                            onChange={_emails => setEmail(_emails)}
                                            getLabel={(
                                                email,
                                                index,
                                                removeEmail,
                                            ) => {
                                                return (
                                                    <div key={index} data-tag >
                                                        {email}
                                                        <span data-tag-handle onClick={() => removeEmail(index)}>x</span>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                    {/* <TextField className="inputRounded" id="standard-basic" name="email" variant="outlined" onChange={handleDetails} fullWidth placeholder="Email Address" value={details.email} /> */}
                                </Box>
                                <Box align="center" paddingTop={2} marginTop={5} >
                                    <Box className={classes.uploadBtnWrapper}>
                                        <Button
                                            style={{ padding: 13, backgroundColor: "#6200EE", cursor:'pointer' }}
                                            variant="contained"
                                            fullWidth
                                            id="custom-button"
                                            color="primary">Upload Photos</Button>
                                        <input type="file" name="myFile" className={classes.myFile} onChange={uploadImage} />

                                    </Box>
                                    <Box marginTop={5}>
                                    <Button
                                        style={{ padding: 13, backgroundColor: "#6200EE", borderRadius: "20px" }}
                                        variant="contained"
                                        fullWidth
                                        onClick={handleNext}
                                        color="primary" >Preview 
                                    </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
};
export default PostCardForm;
