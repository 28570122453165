import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Paper, Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";
import { setOpenedPost } from "../../../../redux/actions/postAction";
import ImageUploader from "../ImageUploader";
import GallerySelect from "../../../Shared/GallerySelect/GallerySelect";
import openedGallery from "../../../../redux/actions/imagesAction"

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    paper: {
        padding: 0,
        textAlign: "center",
        color: `black`,
        cursor: `pointer`,
        borderRadius: 0,
        height: `100%`,
        border: `4px solid white`,
    },
    selectedLayout: {
        border: `4px solid ${theme.palette.secondary.main}`,
    },
    image: {
        width: `100%`,
        height: `100%`,
        background: `white`,
    },
    layoutContainer: {
        width: 900,
    },
    fileInput: {
        display: "none",
    },
    uploadForm: {
        margin: `${theme.spacing(3)}px 0`
    },
    filePreview: {
        width: `100px`,
        height: `100px`,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

const layouts = [ "layout_1", "layout_2", "layout_3", "layout_4", "layout_5" ];

const BlogLayoutForm = (props) => {
    const classes = useStyles();
    const cx = classNames.bind(classes);
    const { openedPost } = useSelector((state) => state.post);
    const { images } = openedPost;
    const dispatch = useDispatch();
    const [showGallery, toggleShowGallery] = useState(false);
    const selectLayout = (event) => {
        const layout = event;
        dispatch(setOpenedPost({ ...openedPost, layout }));
    };

    const handleUploadComplete = source => {
        dispatch(setOpenedPost({ ...openedPost, images: [...openedPost.images, source] }));
    };

    const handleDeleteComplete = source => {
        const imgs = openedPost.images.filter(img => img !== source);
        dispatch(setOpenedPost({ ...openedPost, images: [...imgs ] }));
    }
    return (
        <React.Fragment>
            <Box className={classes.layoutContainer}>
                <Grid container spacing={3} className={classes.layoutContainer}>
                    {layouts.map((layoutId) => (
                        <Grid item xs={2} key={layoutId}>
                            <Paper
                                onClick={() => selectLayout(layoutId)}
                                className={cx({
                                    [classes.paper]: true,
                                    [classes.selectedLayout]: openedPost.layout === layoutId,
                                })}
                            >
                                <img
                                    className={classes.image}
                                    src={`${window.location.origin}/layouts/${layoutId}.jpeg`}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>

                <Box className={classes.uploadForm} paddingBottom={2}>

                    <ImageUploader
                        onDeleteComplete={handleDeleteComplete}
                        onUploadComplete={handleUploadComplete}
                        images={images}                       
                    />
                    <Typography align="center" color="secondary">OR</Typography>
                    <Box pt={2} pb={2} textAlign="right">
                        <Button variant="contained" color="secondary" onClick={() => props.onNext()}>Next</Button>
                    </Box>
                    <GallerySelect images={images} onUploadComplete={handleUploadComplete}/>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default BlogLayoutForm;
