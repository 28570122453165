import {
  SET_OPENED_POST,
  SET_SAVED_POST
} from "../actions/types";

export const INITIAL_STATE = {
  openedPost: {
    id: null,
    title: null,
    comments: [],
    layout: null,
    category: null,
    tags: [],
    body: null,
    images: [],
    blogId: null,
    isPublished: false
  },
  savedPost: {
    id: null,
    title: null,
    comments: [],
    layout: null,
    category: null,
    tags: [],
    body: null,
    images: [],
    blogId: null,
    isPublished: false
  }
};

const postReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_POST:
      return {
        ...state,
        openedPost: payload,
      };
    case SET_SAVED_POST:
      return {
        ...state,
        savedPost: payload,
      };
    default:
      return state;
  }
};

export default postReducer;
