export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';
export const SNACKBAR_INFO = 'SNACKBAR_INFO';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const IS_PAGE_LOADING = 'IS_PAGE_LOADING';

export const SET_OPENED_GALLERY = 'SET_OPENED_GALLERY';
export const SET_OPENED_USERS_IMAGES = 'SET_OPENED_USERS_IMAGES';

export const SET_OPENED_BLOG = 'SET_OPENED_BLOG';
export const SET_SAVED_BLOG = 'SET_SAVED_BLOG';
export const SET_LIST_BLOGS = 'SET_LIST_BLOGS';

export const SET_OPENED_POST = 'SET_OPENED_POST';
export const SET_SAVED_POST = 'SET_SAVED_POST';

export const SET_OPENED_COMMENT = 'SET_OPENED_COMMENT';
export const SET_NEW_COMMENT = 'SET_OPENED_COMMENT';
export const SET_SAVED_COMMENT = 'SET_SAVED_COMMENT';
export const SET_LIST_COMMENT = 'SET_LIST_COMMENT';

export const SET_OPENED_DIARY = 'SET_OPENED_DIARY';
export const SET_SAVED_DIARY = 'SET_SAVED_DIARY';
export const SET_ADD_DIARY_PAGE = 'SET_ADD_DIARY_PAGE';
export const SET_GET_DIARY_PAGE = 'SET_GET_DIARY_PAGE';
export const SET_DIARY_DATES = 'SET_DIARY_DATES';

export const SET_OPENED_JOURNAL = 'SET_OPENED_JOURNAL';
export const SET_SAVED_JOURNAL = 'SET_SAVED_JOURNAL';
export const SET_ADD_JOURNAL_PAGE = 'SET_ADD_JOURNAL_PAGE';
export const SET_GET_JOURNAL_PAGE = 'SET_GET_JOURNAL_PAGE';

export const CLEAR_TODO_INPUT = 'CLEAR_TODO_INPUT';

export const SET_ADD_ASPIRATIONS = 'SET_ADD_ASPIRATIONS';
export const SET_GET_ASPIRATIONS = 'SET_GET_ASPIRATIONS';
export const SET_ASPIRATIONS_DATES = 'SET_ASPIRATIONS_DATES';
export const SET_ASPIRATIONS_ACTIONS = 'SET_ASPIRATIONS_ACTIONS';

export const SET_ADD_TODOS = 'SET_ADD_TODOS';
export const SET_GET_TODOS = 'SET_GET_TODOS';
export const SET_TODOS_ACTIONS = 'SET_TODOS_ACTIONS';

export const GET_POSTCARD = 'GET_POSTCARD';
export const SET_OPENED_POSTCARD = 'SET_OPENED_POSTCARD';
export const SET_SAVED_POSTCARD = 'SET_SAVED_POSTCARD';
export const GET_RECEIVED_POSTCARDS = 'GET_RECEIVED_POSTCARDS';
export const GET_SENT_POSTCARDS = 'GET_SENT_POSTCARDS';

export const GET_BLOG_MAILS = 'GET_BLOG_MAILS'; 
export const SEND_MAILS = 'SEND_MAILS'; 
export const GET_ALL_POSTCARDS = 'GET_ALL_POSTCARDS';
export const SET_POSTCARD_DETAILS='SET_POSTCARD_DETAILS';
export const SET_POSTCARD_IMAGE="SET_POSTCARD_IMAGE";

export const SET_OPENED_RIDDLE="SET_OPENED_RIDDLE";

export const SET_OPENED_ESSAY = 'SET_OPENED_ESSAY';

export const SET_OPENED_POEM = 'SET_OPENED_POEM';
