
import { Icon, List, ListItem, ListItemIcon, ListItemText, Paper, Divider } from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_LIST_BLOGS} from "../../../redux/actions/types";

const drawerWidth = `100%`;
const useStyles = makeStyles((theme) => ({
    drawer: {
        minWidth: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        minWidth: drawerWidth,
    },
    drawerAnchor: {
        minWidth: drawerWidth,
        height: 1
    },
    drawerContainer: {
        overflow: 'auto',
        background: theme.palette.secondary.alt,
        height: '100%'
    },
    list: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",

    },
    listStyle: {
        flexDirection: "column",
        textAlign: "center",
        width: "175px",
        padding: "15px 0",
        color: '#000',
        borderRadius: "40px 0 0px 40px",
        "&.Mui-selected, &.Mui-hover": {
            backgroundColor: "#0B0815",
            color: '#fff'
        }
    },
    listItemIcon: {
        display: "block",
        color: theme.palette.secondary.main
    },
    listItemText: {
        margin: 0
    }
}));

const BlogSideBar = props => {
    const classes = useStyles();
    const divRef = React.useRef();
    const history = useHistory();

    const onTabClick = (tab, event) => {
        props.onTabCange(tab, divRef.current);
    };
    const auth = useSelector(state => state.firebase.auth);
    const dispatch = useDispatch;
    const handleMyBlogs = () => {     
            dispatch({ type: SET_LIST_BLOGS, auth});  
    };
    return (
        <React.Fragment>
            <div className={classes.drawer}>
                <div className={classes.drawerAnchor} ref={divRef}></div>
                <Paper className={classes.drawerPaper}>
                    <div className={classes.drawerContainer}>
                        <List component="nav" aria-label="main sidenav" className={classes.list}>
                            {props.tabs.map(tab => {
                                return <ListItem
                                    button
                                    key={tab.id}
                                    onClick={(event) => onTabClick(tab, event)}
                                    selected={props.openedTab && tab.id === props.openedTab.id}
                                    classes={{ root: classes.listStyle, selected: classes.listSelectedStyle }}>
                                    {tab.icon &&
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Icon>{tab.icon}</Icon>
                                        </ListItemIcon>}
                                    <ListItemText className={classes.listItemText} primary={tab.title} /></ListItem>
                            })}
                        </List>

                        <Divider light />

                        <List component="nav" aria-label="main sidenav" className={classes.list}>
                            <ListItem
                                button
                                classes={{ root: classes.listStyle, selected: classes.listSelectedStyle }}
                                onClick={(event) => history.push("/blogs")}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Icon>{`receipt_long`}</Icon>
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText} primary={`My Blogs`} />
                            </ListItem>

                            <ListItem button classes={{ root: classes.listStyle, selected: classes.listSelectedStyle }}>
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Icon>{`help_outline`}</Icon>
                                </ListItemIcon>
                                <ListItemText className={classes.listItemText} primary={`Help`} />
                            </ListItem>
                        </List>
                    </div>
                </Paper>
            </div>
        </React.Fragment >
    );
};

export default BlogSideBar;
