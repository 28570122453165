import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardActions, CardHeader, CardMedia, Grid, Link, Icon, Typography, CardContent } from "@material-ui/core";
import Carousel from 'react-material-ui-carousel';
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import dayjs from "dayjs";
import { EssayStatus } from "../../../helper/const";

const useStyles = makeStyles((theme) => ({
    root: {
        background: `#eee`
    },
    navLink: {
        cursor: `pointer`,
        textDecoration: `none`
    },
    cardNavLink: {
        cursor: `pointer`,
        textDecoration: `none`,
        color: `#000 !important`
    },
    libHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing() * 2.5}px ${theme.spacing() * 5}px 0`
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    card: {
        maxWidth: 345
    },
    content: {
        height: `150px`,
        width: `100%`
    },
    sliderCard: {
        border: `1px solid #ccc`,
        position: `relative`,
        padding: `15px 10px`
    },
    sliderCardItem: {
        position: `relative`,
        borderRadius: `20px`,
        background: `transparent`
    },
    sliderCardHeader: {
        position: `absolute`,
        top: 0,
        padding: `10px !important`,
        width: `100%`,
        background: `rgba(255, 255, 255, 0.75)`,
        textAlign: `center`
    },
    sliderCardAction: {
        position: `absolute`,
        bottom: 0,
        width: `100%`,
        background: `#FF843D`,
        textAlign: `center`,
        justifyContent: 'space-around'
    },
    sliderCardMedia: {
        height: `250px`
    },
    sliderContent: {
        height: `170px`,
        width: `100%`,
        position: `absolute`,
        bottom: 0,
        background: `#3D3E3F`,
        color: `#fff`,
        opacity: 0.75
    },
    contentBody: {
        [`-webkit-box-orient`]: `vertical`,
        display: `-webkit-box`,
        [`-webkit-line-clamp`]: 3,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: `normal`,
        color: `#fff`
    },
    blogIcon: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const Library = props => {
    const classes = useStyles();
    const history = useHistory();
    const [allPosts, setAllPosts] = useState([]);
    const [allEssays, setAllEssays] = useState([]);

    useFirestoreConnect([
        { collection: 'posts' },
        { collection: 'essay' },
    ]);

    const { posts } = useSelector((state) => state.firestore.ordered);
    const { essay } = useSelector((state) => state.firestore.ordered);

    useEffect(() => {
        if (posts && posts.length > 0 && posts !== undefined) {
            setAllPosts(posts);
        }
    }, [posts]);

    useEffect(() => {
        if (essay && essay.length > 0 && essay !== undefined) {
            setAllEssays(essay);
        }
    }, [essay]);

    const publishedBlogs = allPosts.filter(post => post.isPublished);
    const publishedEssays = allEssays.filter(item => item.status === EssayStatus.PUBLISHED);

    const navigateTo = path => {
        history.push(path);
    };
    const redirectToView = (id, type) => {
        if (id) {
            if (type === "posts") {
                history.push(`/blogs/${id}`);
            }
            else {
                history.push(`/essay/${id}`);
            }
        }
    };

    const showActions = (item) => (
        <React.Fragment>
            <Box className={classes.blogIcon}>
                <Icon>visibility</Icon>
                <Typography variant="body2" color="textSecondary">{item.views}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>thumb_up_off_alt</Icon>
                <Typography variant="body2" color="textSecondary">{item.likedby && item.likedby.length}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>favorite</Icon>
                <Typography variant="body2" color="textSecondary">{item.lovedby && item.lovedby.length}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>share</Icon>
                <Typography variant="body2" color="textSecondary">{item.shared}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>comment</Icon>
                <Typography variant="body2" color="textSecondary">{item.commentscount}</Typography>
            </Box>
        </React.Fragment>
    );

    const Item = ({ item, type }) => {
        const img = item.images && item.images.length ? item.images[0] : null;
        const updatedDate = type === "posts" ? item.lastUpdate.seconds *1000 : item.lastUpdated;
        const body = type === "posts" ? item.body : item.content;

        return (
            <Card className={classes.sliderCardItem}>
                <CardHeader
                    className={classes.sliderCardHeader}
                    subheader={<Link className={classes.cardNavLink} onClick={() => redirectToView(item.id, type)}>{item.title}</Link>}
                />
                <CardMedia
                    className={classes.sliderCardMedia}
                    image={img}
                    title={item.title}
                />
                <CardContent className={classes.sliderContent}>
                    <Typography className={classes.contentBody} variant="body2"
                        color="textSecondary" component="p">
                        {ReactHtmlParser(body)}
                    </Typography>
                    <Typography variant="caption" className={classes.contentPublished}>
                        {dayjs(new Date(updatedDate)).format('MMMM D YYYY, h:mm A')}
                    </Typography>
                </CardContent>
                <CardActions className={classes.sliderCardAction}>
                    {showActions(item)}
                </CardActions>
            </Card>
        );
    };

    const renderItemList = (data, type) => {
        const sliderItems = data.length > 3 ? 3 : data.length;
        const itemsArray = [];
        for (let i = 0; i < data.length; i += sliderItems) {
            if (i % sliderItems === 0) {
                itemsArray.push(
                    <Card className={classes.sliderCard} raised key={`${type}-${i.toString()}`}>
                        <Grid container spacing={3}>
                            {data.slice(i, i + sliderItems).map((item, index) => {
                                return <Grid item xs={4} key={`${type}-${i.toString()}-${index}`}>
                                    <Item key={index.toString()} item={item} type={type} />
                                </Grid>
                            })}
                        </Grid>
                    </Card>
                );
            }
        }

        return itemsArray;
    };

    return (
        <React.Fragment>
            <main className={classes.root}>
                <Box className={classes.libHeader}>
                    <h2>Blogs</h2>
                    <Link className={classes.navLink} onClick={() => navigateTo(`blog-list`)}>View All</Link>
                </Box>

                <Carousel
                    indicators={false}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}>
                    {renderItemList(publishedBlogs, "posts")}
                </Carousel>
            </main>

            <main className={classes.root}>
                <Box className={classes.libHeader}>
                    <h2>Essays</h2>
                    <Link className={classes.navLink} onClick={() => navigateTo(`essay/list`)}>View All</Link>
                </Box>

                <Carousel
                    indicators={false}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}>
                    {renderItemList(publishedEssays, "essay")}
                </Carousel>
            </main>
            {/*
            <main className={classes.root}>
                <Box className={classes.libHeader}>
                    <h2>Books</h2>
                    <Link className={classes.navLink} onClick={() => navigateTo(`blog-list`)}>View All</Link>
                </Box>

                <Carousel
                    indicators={false}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}>
                    {renderItemList(publishedBlogs, `books`)}
                </Carousel>
            </main>

            <main className={classes.root}>
                <Box className={classes.libHeader}>
                    <h2>Poems</h2>
                    <Link className={classes.navLink} onClick={() => navigateTo(`blog-list`)}>View All</Link>
                </Box>

                <Carousel
                    indicators={false}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}>
                    {renderItemList(publishedBlogs, `poems`)}
                </Carousel>
            </main> */}
        </React.Fragment>
    );
};

export default Library;
