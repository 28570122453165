import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setOpenedBlog } from "../../../../redux/actions/blogAction";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useFirestoreConnect } from "react-redux-firebase";
import { setOpenedPost } from "../../../../redux/actions/postAction";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    userAvatar: {
        display: 'inline-block',
        marginRight: `15px`
    },
    postName: {
        "&> div": {
            backgroundColor: "#fff !important",
            color: 'hsl(0, 0%, 20%)',
            borderRadius: 4,
        },
        "& input": {
            padding: `10px !important`,
            fontSize: 14
        },
        "& input::before, & input::after": {
            display: `none`
        }
    }
}));

const CreateBlogForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useFirestoreConnect([
        { collection: 'categories' },
        { collection: 'blogs' }
    ]);

    const auth = useSelector(state => state.firebase.auth);
    const { openedPost } = useSelector(state => state.post);
    const { blogs, categories } = useSelector((state) => state.firestore.ordered);
    const { profile } = useSelector((state) => state.firebase);

    const [catOptions, setCatOptions] = useState([]);
    const [blogNameOptions, setBlogNameOptions] = useState([]);

    useEffect(() => {
        if (categories) {
            const opts = categories.map(cat => ({
                value: cat.id,
                label: cat.title
            }));
            setCatOptions(opts);
        }
    }, [categories]);

    useEffect(() => {
        if (blogs) {
            const opts = blogs.map(cat => ({
                value: cat.id,
                label: cat.title
            }));
            setBlogNameOptions(opts);
        }
    }, [blogs]);

    const handleTitleChange = event => {
        const title = event.target.value;
        dispatch(setOpenedPost({ ...openedPost, title }));
    };

    const handleBlogChange = event => {
        const blogId = event;
        dispatch(setOpenedPost({ ...openedPost, blogId }));
    };

    const handleCategorySelect = category => {
        dispatch(setOpenedPost({ ...openedPost, category }));
    };

    return (
        <React.Fragment>
            <Box className={classes.content}>
                <Box mt={1} mb={2}>
                    <Typography color={'textSecondary'}>Blog Name</Typography>
                    <CreatableSelect
                        placeholder={`Type and press enter`}
                        options={blogNameOptions}
                        value={openedPost.blogId}
                        onChange={handleBlogChange}
                        classNamePrefix={`select`}
                    />
                </Box>

                <Box mb={3}>
                    <Typography color={'textSecondary'}>Select a Group or Category:</Typography>
                    <Select
                        placeholder={`Select from dropdown`}
                        options={catOptions}
                        value={openedPost.category}
                        onChange={handleCategorySelect}
                        classNamePrefix={`select`}
                    />
                </Box>

                <Box mb={4} >
                    <Typography color={'textSecondary'}>Post Name:</Typography>
                    <TextField
                        inputProps={{ maxLength: 75 }}
                        onChange={handleTitleChange}
                        variant="outlined"
                        className={classes.postName}
                        value={openedPost.title}
                        fullWidth />
                </Box>
                <Typography variant="h6" color={'textSecondary'}>Author</Typography>
                <Box mt={1} display="flex" alignItems="center">
                    <Avatar className={classes.userAvatar} alt={auth.displayName} src={auth.photoURL} />
                    <Typography component="span" variant="subtitle1">{(profile && profile.displayName) || auth.displayName}</Typography>
                </Box>
                <Box ml={7}>
                    <Typography variant="caption" color={'textSecondary'}>{profile && profile.tagLine}</Typography>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default CreateBlogForm;
