import React, { useEffect, useState } from "react";
import logo from '../../../assets/images/logo.png';
import store from "../../../redux/store";
import './styles.scss';
import envelope from '../../../assets/images/envelope.png'
import { Link } from "react-router-dom";
export default function NonMember(){
    const[nonMemberDetails,setNonMemberDetails]=useState({})
    const {firestore}=store
    useEffect(async()=>{
        let id=window.location.search.split('=').pop()
        console.log(id)
        await firestore.collection("postcard").doc(id).get().then(querySnapshot=>{
            console.log('id',querySnapshot.data())
            setNonMemberDetails(querySnapshot.data());
    }).catch((error)=>console.log(error))
    },[])
    return(
    <div className=" non-member">
    <div className="header non-member">
        <div className="image">
            <img src={logo} alt="logo" height="100" width="100" className="border-circle" />
        </div>
        <div className="heading black heading-center">
            <span>EXPLORE</span>
            <span className="spacing">&bull;</span>
            <span>CREATE</span>
            <span className="spacing">&bull;</span>
            <span>SHARE</span>
        </div>
    </div>
    <div className="header-border">
    </div>
    <div className="non-member background-white">
        <div className="postcard">
            <div className="border-sent-receive">
                <div>
                    <img src={nonMemberDetails.image} className="imagesModal" height="800" width="600"></img>
                </div>
                <div className="sent-receive-content">
                    <p>{nonMemberDetails.content}</p>
                </div>
                <div>
                    <div className="mb-150 d-flex">
                        <div>
                            <img src={nonMemberDetails.profileImage} height='50px' width='50px' className="border-circle"></img>
                        </div>
                        <div className="t-left font-16">
                            <p>{nonMemberDetails.name}</p>
                            <p classname="mt-10">{nonMemberDetails.from}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <h4 className="font-normal red-color pt-5 pb-4 text-center">You can also send amazing postcard to your loved ones.</h4>
        <h2 className=" text-center">Not a Member yet? </h2>
        <h2 className="font-normal text-center py-3">Click <span className="text-underline"><Link to="/register"
            className="black pr-2" >here
        </Link></span>to join fabulous QuillAtWill platform! </h2>
    </div>
    <div className="footer non-member">
        <div>
            <ul>
                <li>
                    <h2 className="font-normal">Have a question?</h2>
                </li>
                <li>
                    <div className="support-mail">
                        <Link to="mailto:support@quillatwill.com">
                            <img src={envelope} className="envelope" alt="envelope" />
                                <span className="white mail">support@quillatwill.com</span></Link>
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <ul>
                <li>
                    <h2 className="font-normal">Explore Quill at Will</h2>
                </li>
                <li><Link to="/blogs" className="weblink">Blogs</Link></li>
                <li><Link to="/post-card" className="weblink">Postcard</Link></li>
            </ul>
        </div>
        <div className="marginTop">
            <ul>
                <li><Link to="dashboard" className="weblink">Dashboard</Link></li>
                <li><Link to="diary" className="weblink">Diary</Link></li>
                <li><Link to="blog-list" className="weblink">Library</Link></li>
            </ul>
        </div>
        <div>
            <ul>
                <li>
                    <h2 className="font-normal">Join Us Now!</h2>
                </li>
                <li><Link to="/" className="weblink">www.quillatwill.com</Link></li>
            </ul>
        </div>
    </div>
</div>
)
    
}
