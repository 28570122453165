import {
  SET_OPENED_DIARY,
  SET_ADD_DIARY_PAGE,
  SET_SAVED_DIARY,
  SET_GET_DIARY_PAGE,
  SET_DIARY_DATES
} from "../actions/types";

export const INITIAL_STATE = {
  openedDiary: {
    id: null,
    page: null,
    on: null,
    userid: null,
    feelings:null
  },
  savedDiary: {
    id: null,
    page: null,
    on: null,
    userid: null,
    feellings:null
  },
  diaryDates:{
    dates:[]
  }
};

const diaryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_DIARY:
      return {
        ...state,
        openedDiary: payload,
      };    
    case SET_GET_DIARY_PAGE:
      return {
        ...state,
        openedDiary: payload,
      };
      case SET_ADD_DIARY_PAGE:
        return {
          ...state,
          openedDiary: payload,
        };      
    case SET_SAVED_DIARY:
      return {
        ...state,
        savedDiary: payload,
      };
    case SET_DIARY_DATES:
      return {
        ...state,
        diaryDates: payload,
      };
    default:
      return state;
  }
};

export default diaryReducer;
