import { IS_PAGE_LOADING, SNACKBAR_CLEAR, SNACKBAR_ERROR, SNACKBAR_INFO, SNACKBAR_SUCCESS } from "./types";

export const showSuccessSnackbar = payload => dispatch => {
    dispatch({ type: SNACKBAR_SUCCESS, payload });
};

export const showErrorSnackbar = payload => dispatch => {
    dispatch({ type: SNACKBAR_ERROR, payload });
};

export const showInfoSnackbar = payload => dispatch => {
    dispatch({ type: SNACKBAR_INFO, payload });
};

export const clearSnackbar = () => dispatch => {
    dispatch({ type: SNACKBAR_CLEAR, payload: null });
};

export const togglePageLoading = payload => dispatch => {
    dispatch({ type: IS_PAGE_LOADING, payload });
};
