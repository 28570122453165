import {
    Button, Container, Tab, Icon, Box, IconButton,
    Table, TableHead, TableBody, TableCell, TableRow,
    CardContent, Card, CardActions, CardHeader, CardMedia, Typography, Grid,
    Link
} from "@material-ui/core";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { showErrorSnackbar,showSuccessSnackbar } from "../../../redux/actions/uiAction";
import { authIsReady, useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import dayjs from "dayjs";
import { useHistory } from 'react-router-dom';
import { fireDB } from '../../../base';
import { doc, deleteDoc } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2)
    },
    card: {
        maxWidth: 345
    },
    content: {
        height: `150px`,
        width: `100%`
    },
    contentBody: {
        [`-webkit-box-orient`]: `vertical`,
        display: `-webkit-box`,
        [`-webkit-line-clamp`]: 3,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: `normal`
    },
    contentPublished: {
        textAlign: `right`,
        marginTop: theme.spacing(4),
        display: `inline-block`,
        width: `100%`
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    navLink: {
        cursor: `pointer`,
        textDecoration: `none`
    },
    contentAction: {
        flexDirection: `row`
    }
}));

const MyBlogs = props => {
    const classes = useStyles();
    const [value, setValue] = useState("1");
    const [allPosts, setAllPosts] = useState([]);
    const [isGridView, setIsGridView] = useState(false);
    const history = useHistory();
    const auth = useSelector(state => state.firebase.auth);
    const dispatch = useDispatch();
    useFirestoreConnect([
        { collection: 'posts' }
    ]);

    const { posts } = useSelector((state) => state.firestore.ordered);
    console.log(posts, `postspostspostspostspostspostspostspostspostsposts`)
    useEffect(() => {
        if (posts && posts.length > 0 && posts !== undefined) {
            setAllPosts(posts);
        }
    }, [posts]);

    const published = allPosts.filter(post => post.isPublished && post.userId === auth.uid);
    const inProgress = allPosts.filter(post => !post.isPublished && post.userId === auth.uid);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const redirectToCreate = (id = false,userId) => {
        if (id) {
            if(userId!=auth.uid) {  dispatch(showErrorSnackbar("You're not allowed to edit other's posts")); return;}
            history.push(`/blogs/edit/${id}`);
        } else {
            history.push("/blogs/create");
        }
    };

    const redirectToDelete = (id = false,userId) => {
        if (id) {
            if(userId!=auth.uid) {  dispatch(showErrorSnackbar("You're not allowed to delete other's posts")); return;}   
            else{
                deleteDoc(doc(fireDB, "posts", id));
                dispatch(showSuccessSnackbar("Post deleted successfully!!"));
            }     
        }
    }
    const redirectToView = id => {
        if (id) {
            history.push(`/blogs/${id}`);
        }
    };

    const showContentGrid = rows => {
        if (!rows.length) {
            return ``;
        }
        return <Grid container spacing={3}>
            {rows.map((row) => {
                const img = row.images && row.images.length ? row.images[0] : null;
                return <Grid item xs={4} key={row.title}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={<Link className={classes.navLink} onClick={() => redirectToView(row.id)}>{row.title}</Link>}
                            subheader={row.blogId.label}
                        />
                        {img &&
                            <CardMedia
                                className={classes.media}
                                image={img}
                                title={row.title}
                            />
                        }
                        <CardContent className={classes.content}>
                            <Typography className={classes.contentBody} variant="body2" color="textSecondary" component="p">
                                {ReactHtmlParser(row.body)}
                            </Typography>
                            {row.isPublished &&
                                <Typography variant="caption" className={classes.contentPublished}>{dayjs(new Date(row.lastUpdate.seconds * 1000)).format('MMMM D YYYY, h:mm A')}</Typography>
                            }
                        </CardContent>
                        <CardActions disableSpacing className={classes.contentAction}>
                            <IconButton onClick={() => redirectToCreate(row.id,row.userId)} aria-label="edit">
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton onClick={() => redirectToDelete(row.id,row.userId)} aria-label="edit">
                                <Icon>delete</Icon>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Grid>
            })}
        </Grid>;
    };

    const showContentList = rows => {
        if (!rows.length) {
            return ``;
        }
        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Blog Name</TableCell>
                    <TableCell>Post Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.title}>
                        <TableCell component="th"><b>{row.blogId.label}</b></TableCell>
                        <TableCell component="th" scope="row">
                            <Link className={classes.navLink} onClick={() => redirectToView(row.id)}>{row.title}</Link>
                        </TableCell>
                        <TableCell>{row.category.label}</TableCell>
                        <TableCell>{dayjs(new Date(row.lastUpdate.seconds*1000)).format('MMMM D YYYY, h:mm A')}</TableCell>
                        <TableCell><IconButton onClick={() => redirectToCreate(row.id,row.userId)}><Icon>edit</Icon></IconButton></TableCell>
                        <TableCell><IconButton  onClick={() => redirectToDelete(row.id,row.userId)} ><Icon>delete</Icon></IconButton></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>;
    };

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Box alignItems="flex-start" paddingTop={2} paddingBottom={4} display="flex">
                    <Box flexGrow={1}>
                        <Button size="small"
                            variant="contained"
                            onClick={() => redirectToCreate()}
                            color="primary">Create New Post &nbsp;<Icon>add_circle</Icon></Button>
                    </Box>
                    <Box alignSelf="flex-end">
                        <IconButton size="small" onClick={() => setIsGridView(true)} variant="outlined" color={isGridView ? "secondary" : "primary"} ><Icon>grid_view</Icon></IconButton>
                        <IconButton size="small" onClick={() => setIsGridView(false)} variant="outlined" color={isGridView ? "primary" : "secondary"}><Icon>view_list</Icon></IconButton>
                    </Box>
                </Box>
                <TabContext value={value}>
                    <TabList onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="In Progress" value="1" />
                        <Tab label="Published" value="2" />
                    </TabList>
                    <TabPanel value="1">
                        {isGridView && (showContentGrid(inProgress))}
                        {!isGridView && (showContentList(inProgress))}
                    </TabPanel>
                    <TabPanel value="2">
                        {isGridView && (showContentGrid(published))}
                        {!isGridView && (showContentList(published))}
                    </TabPanel>
                </TabContext>
            </Container>
        </React.Fragment>
    );
};

export default MyBlogs;
