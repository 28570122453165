import { LOGIN, LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  isAuthenticated: false,
  user: {}
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: payload
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      };

    default:
      return state;
  }
};

export default userReducer;
