import { GET_RECEIVED_POSTCARDS,GET_SENT_POSTCARDS,SET_OPENED_POSTCARD,SET_SAVED_POSTCARD, SET_POSTCARD_DETAILS, SET_POSTCARD_IMAGE } from "./types";
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from "./uiAction";
import store from "../store";


export const setOpenedPostCard = (payload) => (dispatch) => {
  dispatch({ type:SET_OPENED_POSTCARD, payload });
};

export const getReceivedPostCards = (payload) => async(dispatch,getState) => {
  const { firestore } = store;
  var name=payload.email.substring(0, payload.email.lastIndexOf("@"))
  let postcards = [];
  await firestore 
    .collection('postcard')
    // .whereField("to", 'arrayContains', payload.uid)
    .where("to", "array-contains", {email:payload.email,userName:name}) 
    .get()
    .then(
      querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          postcards.push(doc.data());
        }
        )
      }).catch((error) => {
        console.log(error, "Error in getting Post Cards");
      });

  dispatch({ type: GET_RECEIVED_POSTCARDS, payload:postcards });
};


export const getSentPostCards = (payload) => async(dispatch,getState) => {
  const { firestore } = store;
  let postcards = [];
  console.log("id",payload.uid)
  await firestore 
    .collection('postcard')
    .where('userId', '==', payload.uid)
    .get()
    .then(
      querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          postcards.push(doc.data());
        }
        )
      }).catch((error) => {
        console.log(error, "Error in getting the Sent Post Cards");
      }); 
  dispatch({ type: GET_SENT_POSTCARDS, payload:postcards });
};

export const getPostCard = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { openedPostCard } = getState().postcards;
  const { firestore } = store;
   await firestore
    .collection('postcard')
    .doc(payload.id)
    .get()
    .then(
      querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          openedPostCard.id = doc.id;
          openedPostCard.content = doc.data().content;
          payload.id = doc.id;
          payload.content = doc.data().content;
        }
        )
      }).catch((error) => {
        console.log(error, "Error in getting PostCard");
      });
  openedPostCard.userid = auth.uid;
  dispatch({ type: SET_OPENED_POSTCARD, payload })

};

export const savePostCard = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
 
  console.log("payload",payload)
  const { openedPostCard, savedPostCard } = getState().postCard;
  console.log("getstate() postCard", openedPostCard, savedPostCard)
 
  const { firestore } = store;
  await firestore.add({ collection: "postcard" }, {
    
    content: payload.content || "",
    userId: auth.uid
  }).then((resp) => {
    openedPostCard.id = resp.id;
    openedPostCard.userid = auth.uid;
    savedPostCard.id = resp.id;
    savedPostCard.userid = auth.uid;  
    payload.id = resp.id;
    dispatch({ type: SET_SAVED_POSTCARD, payload });
    dispatch(showSuccessSnackbar("Post Card Saved Successfully for Sending!"));  
})};
export const setPostCardDetails=(payload)=>(dispatch,getState)=>{
  const {postCardDetails}=getState().postCard
  dispatch({type:SET_POSTCARD_DETAILS,payload})
}

export const setPostCardImage =(payload)=>(dispatch)=>{
  dispatch({type:SET_POSTCARD_IMAGE, payload})
}