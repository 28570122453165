import React from "react";
import { Box, Breadcrumbs, Typography, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import "./SubHeader.scss";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  crumb: {
    fontSize: `12px`,
    color: `white`,
    textDecoration: `none`
  },
  lastCrumb: {
    textTransform: `uppercase`
  },
  box: {
    background: theme.palette.secondary.altDark
  }
}));

const SubHeader = ({ crumbs }) => {
  const classes = useStyles();
  const { profile } = useSelector((state) => state.firebase);

  const location = useLocation();
  if (location.pathname === "/") {
    return <React.Fragment></React.Fragment>;
  }
  let lastCrumb = ``;

  return (
    <React.Fragment>
      <Box color="white" className={classes.box} p={3} style={{background: profile && profile.theme}}>
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          {crumbs.map(crumb => {
            lastCrumb = crumb.title;
            return <Link key={crumb.path} className={classes.crumb} to={crumb.path}>
              {crumb.title}
            </Link>
          })}
        </Breadcrumbs>
        <Typography variant="h5" className={classes.lastCrumb}>{lastCrumb}</Typography>
      </Box>
    </React.Fragment>
  );
};

export default SubHeader;
