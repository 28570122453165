import { SEND_MAILS,GET_BLOG_MAILS } from "./types";
import store from "../store";


// export const setOpenedPostCard = (payload) => (dispatch) => {
//   dispatch({ type:SET_OPENED_POSTCARD, payload });
// };

export const getBlogMails = (payload,setNotifications) => async(dispatch) => {
  const { firestore } = store;
  let subscribedbyUID = [], subscribedby=[];
  await firestore
  .collection("blogs")
  .doc(payload)
  .get()
  .then(async (docRef) => {
    if(docRef.data().subscribedby){
      //subscribedbyUID.push(docRef.data().subscribedby);      
      subscribedbyUID = [...docRef.data().subscribedby];
      subscribedbyUID.forEach(
        item => subscribedby.push(item.email)
      )
      setNotifications(subscribedby);
      dispatch({ type: GET_BLOG_MAILS, payload:subscribedby});
    }
  }).catch((error) => {
    console.log(error, "Error in getting Blogs");
  })
};

export const sendMails = (payload) => async(dispatch) => {
  const { firestore } = store;
  await firestore 
    .collection('postcard')
    .where('to','in',[payload.email,payload.userId,payload.userName])
    .update();

  dispatch({ type: SEND_MAILS, payload});
};

