/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Select,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import JoditEditorControl from "../../Shared/JoditEditor/JoditEditor";
import { saveEssay, updateEssay } from "../../../redux/actions/essayAction";
import { EssayStatus } from "../../../helper/const";
import "./Essay.scss";
import ImageUploader from "../Blog/ImageUploader";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  actions: {
    textAlign: `right`,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
  },
  essayForm: {
    padding: 30,
    marginTop: 20,
    borderRadius: 20,
  },
  imageUploader: {
    border: "1px solid #ccc",
    padding: 10,
    marginBottom: 20,
  },
  reviewerEmail: {
    width: 400,
  },
}));

const ESSAY_INITIAL_STATE = {
  title: "",
  category: "",
  content: "",
  image: "",
  isReviewComplete: false,
  reviewer: {},
  status: EssayStatus.IN_PROGRESS,
};

const Essay = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [essay, setEssay] = useState(ESSAY_INITIAL_STATE);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewer, setReviewer] = useState(null);
  const { essayId } = useParams();
  const history = useHistory();

  useFirestoreConnect([
    { collection: "essay_categories" },
    { collection: "essay", doc: essayId },
  ]);

  const { essay_categories, essay: essayDoc } = useSelector(
    (state) => state.firestore.ordered
  );

  useEffect(() => {
    if (
      essayDoc &&
      essayId !== undefined &&
      essayDoc.length > 0 &&
      essayDoc[0].id === essayId
    ) {
      setEssay({ ...essayDoc[0] });
    } else {
      setEssay(ESSAY_INITIAL_STATE);
    }
  }, [essayDoc]);

  useEffect(() => {
    if (essay_categories) {
      const opts = essay_categories.map(({ title }) => title);
      setCategoryOptions([...opts]);
    }
  }, [essay_categories]);

  const handleChange = (name, value) => {
    setEssay({ ...essay, [name]: value });
  };

  const handleSave = () => {
    if (!essayId) {
      dispatch(saveEssay(essay));
    } else {
      dispatch(updateEssay(essay));
    }
  };

  const handlePublish = () => {
    if (!essayId) {
      dispatch(saveEssay({ ...essay, status: EssayStatus.PUBLISHED }));
    } else {
      dispatch(updateEssay({ ...essay, status: EssayStatus.PUBLISHED }));
    }
  };

  const handleReviewClick = () => {
    setIsReviewModalOpen(true);
  };

  const handleSendToReview = () => {
    if (!reviewer) return;
    const sendTo = {
      email: reviewer,
    };
    if (!essayId) {
      dispatch(
        saveEssay({ ...essay, status: EssayStatus.IN_REVIEW, reviewer: sendTo })
      );
    } else {
      dispatch(
        updateEssay({
          ...essay,
          status: EssayStatus.IN_REVIEW,
          reviewer: sendTo,
        })
      );
    }
    setIsReviewModalOpen(false);
  };

  const handleDeleteComplete = () => {
    setEssay({ ...essay, image: null });
  };

  const handleUploadComplete = (source) => {
    setEssay({ ...essay, image: source });
  };

  const closeReviewModal = () => {
    setIsReviewModalOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={isReviewModalOpen}
        onClose={closeReviewModal}
        maxWidth="false"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Review Confirmation</DialogTitle>
        <DialogContent>
          <Box mt={2} mb={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="title"
                label="Choose Reviewer"
                inputProps={{ maxLength: 75 }}
                className={classes.reviewerEmail}
                onChange={(event) => setReviewer(event.target.value)}
                variant="outlined"
                value={reviewer}
                fullWidth
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSendToReview}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.actions}>
            <Button variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
            {!essay.isPublished && (
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
            )}
            <Button variant="contained" onClick={handleReviewClick}>
              Send for Review
            </Button>
            <Button variant="contained" color="primary" onClick={handlePublish}>
              Publish
            </Button>
          </Box>
          <Card className={classes.essayForm}>
            <Box mt={1} mb={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="title"
                  label="Essay Name"
                  inputProps={{ maxLength: 75 }}
                  onChange={(event) =>
                    handleChange("title", event.target.value)
                  }
                  variant="outlined"
                  className={classes.title}
                  value={essay.title}
                  fullWidth
                />
              </FormControl>
            </Box>
            <Box mt={7} mb={7}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  value={essay.category}
                  onChange={(event) =>
                    handleChange("category", event.target.value)
                  }
                  label="Category"
                >
                  {categoryOptions.map((opt) => (
                    <MenuItem value={opt}>{opt}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.imageUploader}>
              <ImageUploader
                onDeleteComplete={handleDeleteComplete}
                onUploadComplete={handleUploadComplete}
                maxFiles={1}
                images={essay.image ? [essay.image] : []}
              />
            </Box>
            <Box mt={1} mb={2} className={classes.body}>
              <JoditEditorControl
                onChange={(value) => handleChange("content", value)}
                content={essay.content}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Essay;
