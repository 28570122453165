import React, { useEffect, useState } from "react";
import { firebaseAnalytics } from "../../../base";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Chip, Tab, Card, Divider, Container } from "@material-ui/core";
import PostCardSideBar from "./PostCardSideBar";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import PostCardForm from './Forms/PostCardForm';
import GallerySelect from '../../Shared/GallerySelect/GallerySelect';
import PreviewForm from './Forms/Preview';
import ConformationForm from './Forms/Conformation';
import SentPostCard from "./Forms/SentPostCards";
import ReceivePostCard from "./Forms/ReceivePostCards";
import { setPostCardImage } from "../../../redux/actions/postCardAction";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    selectedPostCardImage: {
        textAlign: "center",
        marginBottom: 20,
        '& > img': {
            width: 200,
            height: 200,
            margin: 10,
            border: `1px solid #ccc`
        }
    }
}));

const PostCards = () => {
    const classes = useStyles();
    const [value, setValue] = useState("1");
    const dispatch = useDispatch();
    const auth = useSelector(state => state.firebase.auth);
    useEffect(()=>{
        if(window.location.search!==""){
            handleTabChange("Received")
        }
        firebaseAnalytics.logEvent("Post Card Viewed",auth.email);
    },[])

    const { postCardImage } = useSelector(state => state.postCard);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTabChange = (value) => {
        
        setValue(value);
        console.log("after value",value)
    }

    const tabs = [{
        id: "create_new",
        title: "Create New",
        icon: "add_circle",
    }, {
        id: "sent_PostCard",
        title: "Sent",
        icon: "view_quilt",

    }, {
        id: "Received_PostCard",
        title: "Received",
        icon: "view_quilt",
    }
    ];

    const handleUploadComplete = source => {
        dispatch(setPostCardImage(source));
    };

    return (
        <React.Fragment>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={2}>
                    <PostCardSideBar tabs={tabs} send={() => handleTabChange("Send")} received={() => handleTabChange("Received")} createNew={() => handleTabChange("1")} />
                </Grid>
                <Grid item xs={10}>
                    <TabContext value={value}>
                        {value === "Send" || value === "Received"?"":
                        <TabList onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Choose Card" value="1" disabled={true} />
                            <Tab label="Preview" value="2" disabled={true} />
                            <Tab label="Confirmation" value="3" disabled={true} />
                        </TabList>
                        }

                        <Divider />
                        <TabPanel value="1">
                            <Container fixed style={{ marginTop: "2%" }} >
                                <PostCardForm handleGoTo={() => handleTabChange("2")} />
                                <Box className={classes.selectedPostCardImage}>
                                    { postCardImage && <img src={postCardImage} width="200" height="200" /> }
                                </Box>

                                <GallerySelect onUploadComplete={handleUploadComplete}/>
                            </Container>
                        </TabPanel>

                        <TabPanel value="2">
                            <Container>
                                <PreviewForm handleGoBack={() => handleTabChange("1")} handleGoSend={() => handleTabChange("3")} />
                            </Container>
                        </TabPanel>
                        <TabPanel value="3">
                            <Container align="center">
                                < ConformationForm handleGoFirst={() => handleTabChange("1")} />
                            </Container>
                        </TabPanel>

                        <TabPanel value="Send">
                           <SentPostCard/>
                        </TabPanel>
                        <TabPanel value="Received">
                          <ReceivePostCard/>
                        </TabPanel>
                    </TabContext>


                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default PostCards;
