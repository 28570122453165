import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Select,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
} from "@material-ui/core";
import { useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import JoditEditorControl from "../../Shared/JoditEditor/JoditEditor";
import { savePoem, updatePoem } from "../../../redux/actions/poemAction";
import { PoemStatus } from "../../../helper/const";
import "./Poem.scss";
import ImageUploader from "../Blog/ImageUploader";
import { useParams, useHistory } from "react-router-dom";
import ImageEditorControl from "../../Shared/ImageEditor/ImageEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  actions: {
    textAlign: `right`,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    width: "100%",
  },
  poemForm: {
    padding: 30,
    marginTop: 20,
    borderRadius: 20,
  },
  imageUploader: {
    border: "1px solid #ccc",
    padding: 10,
    marginBottom: 20,
  },
}));

const POEM_INITIAL_STATE = {
  title: "",
  category: "",
  content: "",
  image: "",
  isReviewComplete: false,
  status: PoemStatus.IN_PROGRESS,
};

const CreatePoem = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [poem, setPoem] = useState(POEM_INITIAL_STATE);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const { poemId } = useParams();
  const history = useHistory();

  useFirestoreConnect([
    { collection: "poem_categories" },
    { collection: "poems", doc: poemId },
  ]);

  const { poem_categories, poems: poemDoc } = useSelector(
    (state) => state.firestore.ordered
  );

  useEffect(() => {
    if (
      poemDoc &&
      poemId !== undefined &&
      poemDoc.length > 0 &&
      poemDoc[0].id === poemId
    ) {
      setPoem({ ...poemDoc[0] });
    } else {
      setPoem(POEM_INITIAL_STATE);
    }
  }, [poemDoc]);

  useEffect(() => {
    if (poem_categories) {
      const opts = poem_categories.map(({ title }) => title);
      setCategoryOptions([...opts]);
    }
  }, [poem_categories]);

  const handleChange = (name, value) => {
    setPoem({ ...poem, [name]: value });
  };

  const handleSave = () => {
    if (!poemId) {
      dispatch(savePoem(poem));
    } else {
      dispatch(updatePoem(poem));
    }
  };

  const handlePublish = () => {
    if (!poemId) {
      dispatch(savePoem({ ...poem, status: PoemStatus.PUBLISHED }));
    } else {
      dispatch(updatePoem({ ...poem, status: PoemStatus.PUBLISHED }));
    }
  };

  const handleReview = () => {
    if (!poemId) {
      dispatch(savePoem({ ...poem, status: PoemStatus.IN_REVIEW }));
    } else {
      dispatch(updatePoem({ ...poem, status: PoemStatus.IN_REVIEW }));
    }
  };

  const handleDeleteComplete = () => {
    setPoem({ ...poem, image: null });
  };

  const handleUploadComplete = (source) => {
    setPoem({ ...poem, image: source });
  };

  return (
    <React.Fragment>
      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.actions}>
            <Button variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
            {!poem.isPublished && (
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
            )}
            <Button variant="contained" onClick={handleReview}>
              Send for Review
            </Button>
            <Button variant="contained" color="primary" onClick={handlePublish}>
              Publish
            </Button>
          </Box>
          <Card className={classes.poemForm}>
            <Box my={4}>
              <ImageEditorControl />
            </Box>
            <Box mt={1} mb={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  id="title"
                  label="Poem Name"
                  inputProps={{ maxLength: 75 }}
                  onChange={(event) =>
                    handleChange("title", event.target.value)
                  }
                  variant="outlined"
                  className={classes.title}
                  value={poem.title}
                  fullWidth
                />
              </FormControl>
            </Box>
            <Box mt={7} mb={7}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  value={poem.category}
                  onChange={(event) =>
                    handleChange("category", event.target.value)
                  }
                  label="Category"
                >
                  {categoryOptions.map((opt) => (
                    <MenuItem value={opt}>{opt}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.imageUploader}>
              <ImageUploader
                onDeleteComplete={handleDeleteComplete}
                onUploadComplete={handleUploadComplete}
                maxFiles={1}
                images={poem.image ? [poem.image] : []}
              />
            </Box>
            <Box mt={1} mb={2} className={classes.body}>
              <JoditEditorControl
                onChange={(value) => handleChange("content", value)}
                content={poem.content}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreatePoem;
