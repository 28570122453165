import { Box, Divider, Link } from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    box: {
        paddingRight: theme.spacing(7),
        display: `flex`,
        alignItems: `center`,
        justifyContent: `flex-end`
    },
    topLinks: {
        color: `white`,
        margin: `0 ${theme.spacing(2)}px`,
        fontSize: `14px`,
        cursor: `pointer`
    },
    divider: {
        backgroundColor: `white`,
        margin: `${theme.spacing(3)}px 0`
    },
    mobileWarning: {
        backgroundColor: `red`,
        color: `#fff`,
        padding: `10px`,
        textAlign: `center`,
        [theme.breakpoints.up('md')]: {
            display: `none`
        }
    }
}));

const TopBar = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className={classes.box} color="white" bgcolor="black" height="35px">
                {/* <Link to={`/`} className={classes.topLinks}>Quill At Will</Link> */}
                {/* <Divider className={classes.divider} orientation="vertical" variant="inset" flexItem /> */}
                <Link to='#'
                    onClick={(e) => { window.location = `mailto:support@quillatwill.com`; e.preventDefault(); }}
                    className={classes.topLinks}>
                    CONTACT US
                </Link>
                <Divider className={classes.divider} orientation="vertical" variant="inset" flexItem />
                <NavLink to="/privacy" className={classes.topLinks}>PRIVACY POLICY</NavLink>
                <Divider className={classes.divider} orientation="vertical" variant="inset" flexItem />
                <NavLink to="/cookies-policy" className={classes.topLinks}>COOKIES POLICY</NavLink>
            </Box>
            <Box className={classes.mobileWarning}>
                For amazing experience view this site on Desktop
            </Box>
        </React.Fragment>
    );
};

export default TopBar;
