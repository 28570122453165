import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, Grid, Container, Button } from "@material-ui/core";
import {setPostCardImage,setPostCardDetails,getSentPostCards} from "../../../../redux/actions/postCardAction";
import { useSelector, useDispatch } from "react-redux";
import photoURL from "../../../../assets/images/profile.jpg";
import store from "../../../../redux/store";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearchMinus, faSearchPlus, faCrop } from '@fortawesome/free-solid-svg-icons'
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { storage } from "../../../../base";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        display: 'flex',
        justifyContent: 'center'
    },
    content: {
        flexGrow: 1
    },
    shadow: {
        boxShadow: 12

    },
    boxShadow: {
        // border: "8px solid #E9DDFD",
        borderRadius: 25,
        width: '65%',
        marginBottom: "20px",
        padding: "30px"

    },
    postCardContainer: {
        position: 'relative',

    },
    contentBox: {
        backgroundColor: "#ffff",
        borderRadius: "10px",
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        padding: 10

    },
    userAvatar: {
        display: 'inline-block',
        marginRight: `15px`
    },
    boxalign: {
        marginTop: "20px"
    },
    postName: {
        "&> div": {
            backgroundColor: "#fff !important",
            color: 'hsl(0, 0%, 20%)',
            borderRadius: 4,
        },
        "& input": {
            padding: `10px !important`,
            fontSize: 14
        },
        "& input::before, & input::after": {
            display: `none`
        }
    },
    content: {
        padding: `10px 0`,
        '& > img': {
            // width: `100%`,
            height: `100%`,
            margin: 10,
            cursor: `pointer`,
            borderRadius: `10px`
        }
    },
    zoomButton: {
        backgroundColor: '#6200EE',
        margin: '10px 10px 10px 0',
        color: 'white',
        border: 'none',
        fontWeight: '600',
        borderRadius: '50%',
        padding: '5px 10px'
    },
}));

const PreviewForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.firebase.auth);
    const profile = useSelector(state => state.firebase.profile);
    const details = useSelector(state => state.postCard.postCard)
    const urlImage = useSelector(state => state.postCard.postCardImage)
    console.log('details', details)
    const { OpenedPostCard } = useSelector(state => state.postCard);
    console.log("PreviewForm OpenedPostCard ", OpenedPostCard)
    const [email, setEmail] = useState([])
    const [open, setOpen] = React.useState(false);
    const [emailUrlPath, setEmailUrlPath] = useState('')
    const [dbCollectionId, setDbCollectionId] = useState('')
    const [routePath, setRoutePath] = useState('')
    const [fromUser, setFromUser] = useState('')
    const [cropper, setCropper] = useState();
    const [data, setData] = useState({
        content: "",
        name: "",
    })

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            let data = cropper.getCroppedCanvas().toDataURL()
            let number = Math.floor(Math.random() * 100);
            await storage.ref(`/users/${auth.uid}/image${number}`).putString(data, 'data_url', { contentType: 'image/jpeg' })
                .on("state_changed", console.log('Success'), console.error("failed"), () => {
                    storage.ref(`/users/${auth.uid}`).child(`image${number}`).getDownloadURL()
                        .then((url) => {
                            console.log('image', url)
                            dispatch(setPostCardImage(url))
                        })
                })
        }
    };
    const { firestore } = store
    useEffect(() => {
        setData(details)
        //setRoutePath(window.location.href)
        setRoutePath('https://www.quillatwill.com');
        details.to.map((item) => {
            email.push(item.email)
        })
    }, [])
    useEffect(async () => {
        try {
            await firestore.collection('users').where("email", "==", email[0]).get().then(
                querySnapshot => {
                    if (querySnapshot.docs.length !== 0) {
                        setFromUser(querySnapshot.data().displayName)
                        setEmailUrlPath(`${routePath}/post-card?id=`)
                    } else {
                        setEmailUrlPath(`${routePath}/non-member?id=`)
                    }
                }
            );
        } catch (error) { console.log(error) }
    }, [emailUrlPath], [dbCollectionId])

    const handleBack = () => {
        props.handleGoBack()
    }
    const handleClickOpen = (image) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleDetails = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    }


    const handleSend = async () => {
        if (data.content !== "" && data.name !== "" && data.to !== "") {
            var emailList = []
            email.map((item) => {
                let data =
                {
                    email: item,
                    userName: item.substring(0, item.lastIndexOf("@"))
                }
                emailList.push(data)
            })

            try {
                const details = await firestore.add({ collection: "postcard" }, {
                    content: data.content,
                    from: data.from,
                    userId: data.userId,
                    to: emailList,
                    image: urlImage,
                    name: (data.name)? data.name : fromUser,
                    date: data.date,
                    profileImage: auth.photoURL == undefined ? photoURL : auth.photoURL
                })

                dispatch(setPostCardDetails(''))
                dispatch(setPostCardImage(''))
                dispatch(getSentPostCards(auth))
                props.handleGoSend()
            } catch (error) { console.log(error) }
        } else { return }
        // props.handleGoSend()
    }
    return (
        <React.Fragment>
            <Container align="center" className="postcard-root">
                <div className="d-flex flex-column">
                    <Box variant="outlined" className={classes.content}>
                        <TransformWrapper
                            initialScale={1}
                            style={{ height: '400px', width: '400px' }}
                        >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <div>
                                    <div className="tools">
                                        <button onClick={() => handleClickOpen(urlImage)} className={classes.zoomButton}> <FontAwesomeIcon icon={faCrop} /></button>
                                        <button onClick={() => zoomIn()} className={classes.zoomButton}> <FontAwesomeIcon icon={faSearchPlus} /></button>
                                        <button onClick={() => zoomOut()} className={classes.zoomButton}> <FontAwesomeIcon icon={faSearchMinus} /> </button>
                                        <button onClick={() => resetTransform()} className={classes.zoomButton}>x</button>
                                    </div>
                                    <TransformComponent>
                                        <div className="preview-border">
                                            <div>
                                                <img src={urlImage} height="400" width="600" />
                                            </div>
                                        </div>
                                    </TransformComponent>
                                </div>
                            )}
                        </TransformWrapper>
                    </Box>

                    <Box className={classes.mainContent}>
                        <Box className={classes.boxShadow}>
                            <Grid container className="border-sent-receives">
                                <Grid item xs={12} style={{ padding: '20px' }}>
                                    <h4 className="text-center">Post Card</h4>
                                    <TextField className={classes.contentBox}
                                        id="standard-multiline-static"
                                        multiline
                                        rows={10}
                                        name="content"
                                        fullWidth
                                        value={data.content}
                                        onChange={handleDetails}
                                        InputProps={{ disableUnderline: true }}
                                        placeholder="Write your content here" />
                                    {/* <Box className={classes.boxalign}>
                                        <TextField className="inputRounded" id="outlined-basic" name="name" variant="outlined" onChange={handleDetails} fullWidth placeholder="Name" value={data.name} />
                                    </Box> */}
                                    <Box className={classes.boxalign}>
                                        <div>
                                            <ReactMultiEmail
                                                style={{ borderRadius: "20px", fontSize: "16px", height: "55px", padding: "10px" }}
                                                emails={email}
                                                onChange={_emails => {
                                                    this.setState({ emails: _emails });
                                                }}
                                                placeholder="Email Address"
                                                onChange={_emails => setEmail(_emails)}
                                                getLabel={(
                                                    email,
                                                    index,
                                                    removeEmail,
                                                ) => {
                                                    return (
                                                        <div key={index} data-tag >
                                                            {email}
                                                            <span data-tag-handle onClick={() => removeEmail(index)}>x</span>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>

                                    </Box>
                                    <Box >
                                        <Box align="center" >
                                            <Box flexGrow={1} padding={3} margin={3}>
                                                <Button size="small" style={{ margin: "5px", cursor: 'pointer', padding: '7px 15px', borderRadius: "20px", }}
                                                    variant="contained"
                                                    onClick={handleBack}
                                                    color="light">Go Back</Button>

                                                <Button size="small" style={{ padding: '7px 15px', backgroundColor: "#6200EE", borderRadius: "20px", cursor: 'pointer' }}
                                                    variant="contained"
                                                    onClick={handleSend}
                                                    color="primary">send</Button>
                                            </Box>
                                        </Box>
                                        {/* 
                                    <Button
                                        style={{ padding: 13, backgroundColor: "#6200EE", borderRadius: "20px" }}
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSend}
                                        color="primary" >Next
                                    </Button> */}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </div>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {/* <img src={cropImage} className="w-100"height="400"/>  */}
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={urlImage}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" autoFocus onClick={getCropData}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default PreviewForm;
