import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  mesurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId:process.env.REACT_APP_FIREBASE_APP_ID
});


firebase.firestore();

export const fireDB = firebase.firestore();

export const storage = firebase.storage();

export const servertimestamp = firebase.firestore.FieldValue.serverTimestamp;
//export const storage = getStorage();

export const firebaseAnalytics = firebase.analytics();

export default firebaseApp;
