import React, { useState,useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { AppBar, Box, Button, Icon, Menu, MenuItem, Toolbar, Link, Avatar } from "@material-ui/core";
import routes, { topMenu } from "../../../routes";
import { NavLink } from "react-router-dom";
import logo from "../../../logo.png";
import "./Header.scss";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../../../redux/actions/userAction";
import SearchBox from "../../Shared/SearchBox/SearchBox";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" mountOnEnter unmountOnExit ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    loginPopOver: {
    },
    searchWrap: {
        width: `400px`,
        marginRight: `40px`
    },
    appLogo: {
        width: 75,
        marginLeft: 10
    },
    navbarLink: {
        color: `#000 !important`,
        fontSize: 18,
        textDecoration: `none`,
        textTransform: `uppercase`
    }
}));

const Header = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [loginAnchorEl, setLoginAnchorEl] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState();
    const history = useHistory();

    const navs = routes.filter(({ nav }) => nav);
    const subNavs = routes.filter(({ subNav }) => subNav);
    const auth = useSelector(state => state.firebase.auth);
    const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
    const dispatch = useDispatch();
    const firestore = useFirestore();

    useEffect(() => {
        firestore
        .collection("users")
        .doc(auth.uid)
        .get().then(function(querySnapshot) {   
            if(querySnapshot.data().avatarUrl) 
                setProfilePhoto(querySnapshot.data().avatarUrl)
            else
                setProfilePhoto(auth.photoURL)
      });     
    }, []);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.target);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setLoginAnchorEl(null);
        setProfileAnchorEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setProfileAnchorEl(event.target);
    }

    const handleLoginForm = (event) => {
        setLoginAnchorEl(event.target);
    };

    const handleCloseLoginForm = () => {
        setLoginAnchorEl(null);
    };
    const openLogin = Boolean(loginAnchorEl);

    return (
        <React.Fragment>
            <AppBar position="relative" color="transparent" className={classes.appBar}>
                <Toolbar className="app-header-toolbar">
                    <div className="app-logo-wrapper">
                        <Link
                            href="#"
                            onClick={(event) => { event.preventDefault(); history.push("/dashboard")}}
                            className={classes.navbarLink}>
                            <img src={logo} className={classes.appLogo} alt="logo" width={100} />
                        </Link>
                    </div>

                    <div className="app-navbar">
                        <ul className="navbar-navs">
                            {topMenu.map((nav, index) => {
                                const { title, path, children } = nav;
                                let subMenu = null;
                                if (children && !isEmpty(children)) {
                                    subMenu = children;
                                }

                                return (<li className="navbar-item" key={`li-${index}`}>
                                    {!subMenu && <NavLink to={path} className={classes.navbarLink}>{title}</NavLink>}
                                    {subMenu && <React.Fragment>
                                        <Link onClick={handleClick}
                                            href="#"
                                            className={classes.navbarLink}
                                            aria-controls={`sub-menu-${path}`}
                                            aria-haspopup="true">{title}</Link>
                                        <Menu
                                            id={`sub-menu-${path}`}
                                            className="sub-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                                            onClose={handleClose}>
                                            {subMenu.map((menu, key) =>
                                                <MenuItem key={`menu-${index}-${key}`}
                                                    onClick={handleClose}>
                                                    <NavLink to={menu.path}
                                                        className="navbar-link">{menu.title}
                                                    </NavLink>
                                                </MenuItem>)}
                                        </Menu>
                                    </React.Fragment>}
                                </li>
                                )
                            }
                            )}
                        </ul>
                    </div>

                    <Box className={classes.searchWrap}>
                        <SearchBox />
                    </Box>

                    {!isAuthenticated ?
                        <Link href={"/login"} className="login-btn">
                            <Button
                                startIcon={<Icon>lock</Icon>}
                                color="primary"
                                variant="contained" aria-label="menu"
                            >Login</Button></Link>
                        : <React.Fragment>
                            <Avatar className={`user-avatar`} alt={auth.displayName} src={profilePhoto} onClick={handleProfileMenuOpen} />
                            <Menu
                                id="profile-menu-appbar"
                                anchorEl={profileAnchorEl}
                                open={Boolean(profileAnchorEl)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                <MenuItem onClick={() => history.push(`/profile`)}><Icon fontSize={`small`}>account_circle</Icon>&nbsp;Profile</MenuItem>
                                <MenuItem onClick={() => dispatch(doLogout())}><Icon fontSize={`small`}>logout</Icon>&nbsp;Logout</MenuItem>
                            </Menu>
                        </React.Fragment>
                    }
                    {/* <Popover
                        open={openLogin}
                        anchorEl={loginAnchorEl}
                        onClose={handleCloseLoginForm}
                        className={classes.loginPopOver}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        TransitionComponent={Transition}
                    >
                        <LoginForm />
                    </Popover> */}
                </Toolbar>
            </AppBar>
        </React.Fragment >
    );
};

export default Header;
