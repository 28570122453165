import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { firebaseAnalytics } from "../../../base";
import {
    Box, Container, Button, IconButton, Icon,
    Typography, Tab, Grid, Paper
} from "@material-ui/core";
import {
    KeyboardDatePicker
} from '@material-ui/pickers';
import moment from "moment";
import classNames from "classnames/bind";
import JoditEditorControl from "../../Shared/JoditEditor/JoditEditor";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from 'react-router-dom';
import dayjs from "dayjs";
import { setOpenedDiary, addDiaryPage, getDiaryPage, saveDiaryPage, setDiaryDates } from "../../../redux/actions/diaryAction";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../redux/actions/uiAction";
import { setOpenedJournal, addJournalPage, getJournalPage, saveJournalPage } from "../../../redux/actions/journalAction";
import TodoList from "../../Shared/Todo/TodoList";
import AddTodo from "../../Shared/Todo/AddTodo";
import emoji from '../../../helper/emoji.json'
import '@fortawesome/fontawesome-free/css/all.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: `flex`,
        marginTop: 40,
        alignItems: `flex-end`
    },
    flexGrow: {
        flex: 1
    },
    filterWrap: {
        width: 200,
        marginLeft: 40
    },
    filterByItems: {
        marginLeft: `20px`
    },
    diaryToday: {
        display: "flex"
    },
    diaryTodayLeft: {
        marginLeft: 40,
        marginTop: 40,
        marginRight: 40,
        alignItems: `center`,
        width: 300,
        display: `flex`,
        flexDirection: `column`
    },
    diarySelDay: {
        height: `230px`,
        width: `200px`,
        display: `block`,
        border: `1px solid #ccc`,
        padding: `40px`,
        textAlign: `center`,
        borderRadius: 10,
    },
    dayStyle: {
        fontSize: 18,
        color: `#000`
    },
    dateBig: {
        fontSize: 50,
        color: `#300078`
    },
    monthStyle: {
        fontSize: 18,
        color: `#D95235`,
        textTransform: `uppercase`
    },
    diaryEditor: {
        marginTop: 40,
        width: `100%`
    },
    journalEditor: {
        marginTop: 30,
        paddingLeft: 20,
        width: `100%`
    },
    addNewEditor: {
        marginTop: 30,
        marginBottom: 100,
        paddingLeft: 20,
        width: `100%`
    },
    addNewEditorBox: {
        border: `1px solid #ccc`
    },
    editorLabel: {
        background: `#61CBE5`,
        padding: 10,
        color: `#00`,
        fontWeight: 500,
        fontSize: 18,
    },
    diaryEntry: {
        width: `100%`,
        fontSize: 16,
        outline: `0 !important`,
        border: `0 !important`
    },
    last7FeelWrap: {
        display: `block`,
        width: 200,
        textAlign: `center`
    },
    last7Feel: {
        width: 100,
        display: `inline-block`,
        textAlign: `center`
    },
    last7FeelIcon: {
        top: `5px`,
        left: `3px`,
        position: `relative`
    },
    daysWithData: {
        "& > .MuiPickersDay-day p": {
            color: `green !important`,
            fontWeight: 500,
            fontSize: `1rem`
        }
    },
    addNewSection: {
        padding: 20,
        display: `flex`
    },
    feelingImoji: {
        fontSize: 35
    }
}));


const Diary = props => {
    const classes = useStyles();
    const cx = classNames.bind(classes);
    const todayEditor = useRef(null);
    const journalEditorPage = useRef(null);
    const journalEditorEvents = useRef(null);
    const journalEditorGrateFulFor = useRef(null);
    const firestore = useFirestore();
    const auth = useSelector(state => state.firebase.auth);
    const history = useHistory();
    const [diaryData, setdiaryData] = useState({});
    const [daysHavingData, setDaysHavingData] = useState([]);
    const [journalData, setjournalData] = useState({});
    const [lastUpdated, setlastUpdated] = useState('auto');
    const [aspirations, setAspirations] = useState([]);
    const [todos, setTodos] = useState([]);
    const emojis = [{ 'feeling': 'happy', 'emoji': 'emoji_emotions' },
    { 'feeling': 'satisfied', 'emoji': 'sentiment_satisfied' },
    { 'feeling': 'bad_mood', 'emoji': 'mood_bad' },
    { 'feeling': 'dissatisfied', 'emoji': 'sentiment_dissatisfied' }]

    const dispatch = useDispatch();
    const config = {
        height: 300,
        toolbar: true,
        emoji: {
            data: () =>
                fetch('https://raw.githubusercontent.com/github/gemoji/master/db/emoji.json').then((res) => res.json())
            //fetch(emoji).then((res) => res.json())
        },
        buttons: [
            "bold",
            "italic",
            "font",
            "fontsize",
            "ol",
            "ul",
            "emoji"
        ],
        removeButtons: [

        ],

        toolbarAdaptive: false,
        toolbarSticky: false
    };

    const { openedDiary } = useSelector(state => state.diary);
    const { diaryDates } = useSelector(state => state.diary);
    const { openedJournal } = useSelector(state => state.journal);
    const { openedAspirations, savedAspirations } = useSelector(state => state.aspirations);
    const { openedTodos, savedTodos } = useSelector(state => state.todos);

    const [selectedDate, handleDateChange] = useState(dayjs().format("YYYY-M-D"));
    const [addNew, handleAddNew] = useState(`Aspiration`);
    const [feelings, handleFeelings] = useState('happy');
    useEffect(() => {
        const userid = auth.uid;
        const on = dayjs().format('YYYY-M-D');
        handleDateChange(on);
        firebaseAnalytics.logEvent("Diary Visited",auth.email);
    }, []);

    /** Fetch Aspirations Logic */
    useFirestoreConnect([
        {
            collection: 'aspirations',
            where: ["userid", "==", auth.uid]
        },
    ]);

    const { aspirations: aspirationsDoc } = useSelector((state) => state.firestore.ordered);
    useEffect(() => {
        if (aspirationsDoc && aspirationsDoc.length > 0) {
            setAspirations(aspirationsDoc[0].aspirations);
        }
    }, [aspirationsDoc]);
    /** End Fetch Aspirations Logic */


    /** Fetch Todo Logic */
    useFirestoreConnect([
        {
            collection: 'todos',
            where: ["userid", "==", auth.uid]
        },
    ]);

    const { todos: todosDoc } = useSelector((state) => state.firestore.ordered);
    useEffect(() => {
        if (todosDoc && todosDoc.length > 0) {
            setTodos(todosDoc[0].todos);
        }
    }, [todosDoc]);
    /** End Todo Aspirations Logic */

    useEffect(() => {
        if (diaryData) {
            setdiaryData(diaryData);
        }
    }, [diaryData]);

    useEffect(() => {
        if (diaryDates) {
            setDaysHavingData(diaryDates?.dates);
        }
    }, [diaryDates]);
    useEffect(() => {
        const onDate = (typeof (selectedDate) !== 'string') ? selectedDate.format("YYYY/M/D") : selectedDate;
        getDiary(onDate);
        getJournal(onDate);
        openedDiary.on = onDate;
        const on = onDate;
        dispatch(setDiaryDates({ ...diaryDates, on, userid }));
    }, [selectedDate]);

    useEffect(() => {
        if (openedJournal) {
            setjournalData(openedJournal);
        }
    }, [openedJournal]);

    const [tabValue, setTabValue] = useState("1");
    const userid = auth.uid;
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getEmoji = (feeling) => {
        return emojis.find(e => e.feeling === feeling).emoji;
    }

    const getDiary = (on) => {
        dispatch(setOpenedDiary({ ...openedDiary, on, userid, feelings }));
        dispatch(getDiaryPage({ ...openedDiary, userid, on, feelings }));
    }
    const getJournal = (on) => {
        dispatch(setOpenedJournal({ ...openedJournal, on, userid }));
        dispatch(getJournalPage({ ...openedJournal, userid, on }))
    }

    const saveData = async (diaryData, journalData) => {
        if (lastUpdated == 'journal') {
            saveJournal(journalData);
            dispatch(showSuccessSnackbar("Journal Updated Successfully"));
        }
        else if (lastUpdated == 'diary') {
            saveDiary(diaryData);
            dispatch(showSuccessSnackbar("Diary Updated Successfully"));
        }
        else {
            saveJournal(journalData);
            saveDiary(diaryData);
            dispatch(showSuccessSnackbar("Diary & Journal Updated Successfully"));
        }
        setlastUpdated('');
    }
    const saveDiary = async (diaryData) => {
        console.log('Saved Clicked!')
        const on = (typeof (selectedDate) !== 'string') ? selectedDate.format("YYYY-M-D") : selectedDate;
        if (diaryData.page) {
            if (openedDiary.id)
                dispatch(saveDiaryPage({ ...diaryData, on }));
            else {
                dispatch(addDiaryPage({ ...diaryData }));
            }
        }
        else {
            dispatch(showErrorSnackbar("Please enter text in diary page"));
        }
    };

    const saveJournal = async (journalData) => {
        if (journalData.page) {
            if (openedJournal.id)
                dispatch(saveJournalPage({ ...journalData }));
            else {
                dispatch(addJournalPage({ ...journalData }));
            }
        }
        else {
            dispatch(showErrorSnackbar("Please enter text in journal page"));
        }
    };

    const handleDiaryChange = event => {
        (lastUpdated === '') ? setlastUpdated('diary') : setlastUpdated('both');
        const page = event;
        const userid = auth.uid;
        var on = dayjs().format('YYYY-M-D');
        setdiaryData({ ...openedDiary, page, on, userid, feelings });
        dispatch(setOpenedDiary({ ...openedDiary, page, on, userid, feelings }));
    };

    const handleJournalPageChange = event => {
        (lastUpdated === '') ? setlastUpdated('journal') : setlastUpdated('both');
        const page = event;
        const userid = auth.uid;
        var on = dayjs().format('YYYY-M-D');
        setjournalData({ ...openedJournal, page, on, userid });
        dispatch(setOpenedJournal({ ...openedJournal, page, on, userid }));
    };

    const handleJournalEventsChange = event => {
        (lastUpdated === '') ? setlastUpdated('journal') : setlastUpdated('both');
        const events = event;
        const userid = auth.uid;
        var on = dayjs().format('YYYY-M-D');
        setjournalData({ ...openedJournal, events, on, userid });
        dispatch(setOpenedJournal({ ...openedJournal, events, on, userid }));
    };

    const handleJournalGrateFulForChange = event => {
        (lastUpdated === '') ? setlastUpdated('journal') : setlastUpdated('both');
        const gratefulfor = event;
        const userid = auth.uid;
        var on = dayjs().format('YYYY-M-D');
        setjournalData({ ...openedJournal, gratefulfor, on, userid });
        dispatch(setOpenedJournal({ ...openedJournal, gratefulfor, on, userid }));
    };

    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Box className={classes.filterWrap}>
                    <KeyboardDatePicker
                        value={selectedDate}
                        label="Date"
                        inputVariant="outlined"
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        autoOk={true}
                        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
                            const formatedDate = day.format();
                            console.log(formatedDate, daysHavingData);
                            if (daysHavingData.includes(formatedDate)) {
                                console.log(day);
                                return <span className={classes.daysWithData}>{dayComponent}</span>;
                            } else {
                                return dayComponent;
                            }
                        }}
                    />
                </Box>
                <Box className={classes.flexGrow}></Box>
                <Box ml={5}>
                    <Typography display="inline" variant="subtitle1">Feeling</Typography>
                    &nbsp;&nbsp;
                    <IconButton className={classes.feelingImoji} color={feelings === `very_happy` ? "secondary" : "default"}
                        variant="outlined" onClick={() => handleFeelings('very_happy')}>
                        <i className="far fa-laugh-wink"></i>
                    </IconButton>
                    <IconButton className={classes.feelingImoji} color={feelings === `happy` ? "secondary" : "default"}
                        variant="outlined" onClick={() => handleFeelings('happy')}>
                        <i className="far fa-laugh-beam"></i>
                    </IconButton>
                    <IconButton className={classes.feelingImoji} color={feelings === `neutral` ? "secondary" : "default"}
                        variant="outlined" onClick={() => handleFeelings('neutral')}>
                        <i className="far fa-meh"></i>
                    </IconButton>
                    <IconButton className={classes.feelingImoji} color={feelings === `sad` ? "secondary" : "default"}
                        variant="outlined" onClick={() => handleFeelings('sad')}>
                        <i className="far fa-frown"></i>
                    </IconButton>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <IconButton size="small" variant="outlined"><Icon>help</Icon></IconButton>
                    <Button color="primary" onClick={() => saveData(diaryData, journalData)} variant="contained">Save</Button>
                </Box>
            </Container>
            <Container className={classes.diaryToday}>
                <Box className={classes.diaryTodayLeft}>
                    <Box className={classes.diarySelDay}>
                        <Typography className={classes.dayStyle}>{moment(selectedDate).format(`dddd`)}</Typography>
                        <Typography className={classes.dateBig}>{moment(selectedDate).format(`DD`)}</Typography>
                        <Typography className={classes.monthStyle}>{moment(selectedDate).format(`MMMM`)}</Typography>
                    </Box>
                    <Box mt={5} className={classes.last7FeelWrap}>
                        {daysHavingData.map(i => {
                            return (
                                <Box className={classes.last7Feel}>
                                    <Typography display="inline" variant="subtitle1">
                                        {moment(i.on).format(`Do MMM`)}
                                    </Typography>
                                    <Icon className={classes.last7FeelIcon} color="secondary">{getEmoji(i.feelings)}</Icon>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>


                <Box className={classes.diaryEditor}>
                    <Typography className={classes.editorLabel}>Today</Typography>
                    <JoditEditorControl onChange={handleDiaryChange} config={{ ...config }} innerRef={todayEditor} content={openedDiary.page} />
                </Box>
            </Container>

            <Container className={classes.journalEditor}>
                {/* <Typography className={classes.editorLabel}>Journal</Typography> */}
                <Grid container spacing={2} columns={16}>
                    <Grid item xs={4}>
                        <Typography className={classes.editorLabel}>My Journal</Typography>
                        <Paper> <JoditEditorControl onChange={handleJournalPageChange} config={{ ...config }} ref={journalEditorPage} content={journalData.page} /></Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.editorLabel}>My Events</Typography>
                        <Paper> <JoditEditorControl onChange={handleJournalEventsChange} config={{ ...config }} ref={journalEditorEvents} content={journalData.events} /></Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.editorLabel}>I am grateful for</Typography>
                        <Paper> <JoditEditorControl onChange={handleJournalGrateFulForChange} config={{ ...config }} ref={journalEditorGrateFulFor} content={journalData.gratefulfor} /></Paper>
                    </Grid>
                </Grid>

            </Container>

            <Container className={classes.addNewEditor}>
                <Typography className={classes.editorLabel}>Add New</Typography>
                <Box className={classes.addNewEditorBox}>
                    <TabContext value={tabValue}>
                        <TabList onChange={handleTabChange} aria-label="simple tabs example">
                            <Tab label="Goals" value="1" />
                            <Tab label="To do" value="2" />
                        </TabList>
                        <TabPanel value="1">
                            <Box className={classes.addNewSection}>
                                <AddTodo label="Add a Goal *" type="aspiration" list={aspirations} />
                            </Box>
                            <TodoList key='goals' id='goals' title={`Goals`}
                                doc={aspirationsDoc && aspirationsDoc[0]} list={aspirations || []} />
                        </TabPanel>
                        <TabPanel value="2">
                            <Box className={classes.addNewSection}>
                                <AddTodo label="Add a todo *" type="todo" list={todos} />
                            </Box>
                            <TodoList key='todos' id='todos' title={`To Do`}
                                doc={todosDoc && todosDoc[0]} list={todos || []} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default Diary;
