import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOpenedGallery, getOpenedUsersImages } from '../../../redux/actions/imagesAction'
import Pagination from '@material-ui/lab/Pagination';


const useStyles = makeStyles((theme) => ({
    title: {
        color: `white`,
        background: `black`,
        padding: `10px 15px`
    },
    content: {
        padding: `10px 0`,
        width: `100%`,
        display: `flex`,
        flexWrap: `wrap`,
        height: `450px`,
        overflowY: `scroll`,
        overflowX: `hidden`,
        '& > img': {
            width: 200,
            height: 200,
            margin: 10,
            cursor: `pointer`
        }
    }
}));

const GallerySelect = props => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const auth = useSelector(state => state.firebase.auth);
    const { gallery,usersImages } = useSelector(state => state.images);
    const [images, SetImages] = useState([])
    const [gotImages, updateGotImages] = useState(0)

    useEffect(() => {
       dispatch(getOpenedUsersImages({ ...usersImages },auth.uid,updateGotImages))
       dispatch(getOpenedGallery({ ...gallery },updateGotImages))
    }, []);


    useEffect(() => {
        var imagesArray = []       
        if (usersImages.length>0) {
            imagesArray = imagesArray.concat(usersImages)
            
        }
        if (gallery.length>0) {
            imagesArray = imagesArray.concat(gallery)
        }
        SetImages(imagesArray);
    }, [gotImages,usersImages,props.images]);

    const selectImage = image => {
        let uploadImage = image.replace('_50x50.png','.png');
        uploadImage = uploadImage.replace('_50x50.jpg','.jpg');
        uploadImage = uploadImage.replace(auth.uid+'%2Fthumbnail', auth.uid);
        props.onUploadComplete(uploadImage);
    }

    const totalPages = 10;

    return (
        <React.Fragment>
            <Typography className={classes.title}>Choose From Gallery</Typography>
            <Box variant="outlined" className={classes.content}>
                {images?.length > 0 &&
                    images.map((image, index) => (
                        <img onClick={(event) => selectImage(image)} src={image} key={`gallery-image-${index}`} />
                    ))}
                    {/* <Pagination count={totalPages} size="small"  /> */}
            </Box>
            <br />
            {/* {images?.length > 0 && <Pagination count={totalPages} size="small"  /> } */}
        </React.Fragment>
    )
}

export default GallerySelect;
