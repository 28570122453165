import { SET_OPENED_GALLERY, SET_OPENED_USERS_IMAGES } from "./types";
import { showErrorSnackbar, showSuccessSnackbar, toggleLoading } from "./uiAction";
import {storage} from "../../base";
import { getStorage, ref, listAll } from "firebase/storage";
import { INITIAL_STATE as imagesInitialState } from "../reducers/imagesReducer";

export const getOpenedGallery = (payload,updateGotImages) => async (dispatch, getState) => {
  if(payload.length>0) dispatch({ type: SET_OPENED_GALLERY, payload });
  const { auth } = getState().firebase;
  const { gallery } = getState().images;  
  const imgFolder = (payload.foldername) ? payload.foldername : 'images';
const listRef = storage.ref(imgFolder);
  const maxCount = payload.maxCount ? payload.maxCount : 100;
  const imgArray = [];
  await listRef.list({ maxResults: maxCount }).then(function (result) {
      result.items.forEach(function (imgRef) {
          imgRef.getDownloadURL().then(function (url) {
              imgArray.push(url);
              updateGotImages(imgArray.length);
          })
      })
    }).then(
    dispatch({ type: SET_OPENED_GALLERY, payload:imgArray })    
    )
  };
 

  export const getOpenedUsersImages = (payload,userId,updateGotImages) => async (dispatch, getState) => {
    //if(payload.length>0) dispatch({ type: SET_OPENED_USERS_IMAGES, payload });
    const { auth } = getState().firebase;
    const { usersImages } = getState().images;  
    const imgFolder = (payload.foldername) ? payload.foldername : 'users/'+userId+'/thumbnail';
  const listRef = storage.ref(imgFolder);
    const maxCount = payload.maxCount ? payload.maxCount : 200;
    const imgArray = [];
    await listRef.list({ maxResults: maxCount }).then(function (result) {
        result.items.forEach(function (imgRef) {
            imgRef.getDownloadURL().then(function (url) {
                imgArray.push(url);
                if(updateGotImages) updateGotImages(imgArray.length);
            })
        })
      }).then(
      dispatch({ type: SET_OPENED_USERS_IMAGES, payload:imgArray })    
      )
    };
