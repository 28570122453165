import React, { useEffect, useState } from "react";
import { Avatar, Container, Typography, Button, IconButton, Icon, Box, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import "./Blog.scss";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { sortDateDesc } from "../../../helper/Helpers";
import lodash from "lodash-es";
import deepdash from "deepdash-es";
import { useFirestore } from "react-redux-firebase";
import { RestoreOutlined } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';
import { showErrorSnackbar, showSuccessSnackbar } from "../../../redux/actions/uiAction";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2)
    },
    authorImage: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        borderRadius: theme.spacing(2),
        border: `1px solid #fff`
    },
    authorImage2: {
        borderRadius: theme.spacing(2),
        border: `1px solid #fff`,
        display: `block`
    },
    authorText: {
        textAlign: "left",
        marginTop: theme.spacing(2)
    },
    authorName: {
        fontWeight: `bold`
    },
    notice: {
        background: `red`,
        color: `white`
    },
    authorIcons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(5)
    },
    blogListItem: {
        cursor: `pointer`
    }
}));

const getAuthor = async (userId, firestore) => {
    let authorDetails = "";
    const doc = await firestore.collection('users').doc(userId).get();
    if (!doc.exists) {
        console.log('No such document!');
    } else {
        console.log('Document data:', doc.data());
        authorDetails = doc.data();
    }
    return authorDetails;
}

const BlogViewAuthor = ({ post, isPreview = false }, bgcolor, color) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const firestore = useFirestore();
    const _ = deepdash(lodash);
    const auth = useSelector(state => state.firebase.auth);
    const { profile } = useSelector((state) => state.firebase);
    const [author, setAuthor] = React.useState({});
    const history = useHistory();

    useEffect(() => {
        const authorId = post.userId || auth.uid;
        getAuthor(authorId, firestore).then((result) => setAuthor(result));
    }, []);

    const { posts } = useSelector((state) => state.firestore.data);

    const constPostArr = Object.entries(posts).filter(
        ([key, val]) => val.userId === post.userId && val.isPublished === true && key != post.id)

    var index = _.findIndex(constPostArr, { "body": post.body });

    const NavigateToPost = (postid, event) => {
        if (postid) {
            history.push(`/blogs/${postid}`);
        }
        console.log(postid);
    };
    const SubscribeUser = () => {
        firestore.collection("blogs").doc(post.blogId.value).update({ 'subscribedby': firestore.FieldValue.arrayUnion(auth.uid) }).then(
            dispatch(showSuccessSnackbar("You've successfully subscribed to " + post.blogId.label))
        )
    };

    if (index !== -1) {
        constPostArr.splice(index, 1);
    }
    const latestPosts = sortDateDesc(constPostArr).slice(0, 3);
    return (
        <React.Fragment>
            <Container className={classes.content}>
                <Avatar variant="square"
                    className={classes.authorImage}
                    alt={author.displayName}
                    src={author.avatarUrl} />

                <Box className={classes.authorText}>
                    <Typography className={classes.authorName} variant="body1">{author.displayName}</Typography>
                    <Typography variant="body2">{profile && profile.tagLine}</Typography>
                </Box>
                <Box mb={10} className={classes.authorIcons}>
                    <Button variant="contained" color="primary" size="small"
                        onClick={SubscribeUser}>
                        Subscribe</Button>
                    <IconButton className={classes.notice} size="small">
                        <Icon color="white">notifications</Icon>
                    </IconButton>
                </Box>
                {!isPreview && (
                    <React.Fragment>
                        <Divider light />
                        <Box>
                            <List>
                                {latestPosts.map(itm =>
                                    <ListItem
                                        disableGutters={true}
                                        className={classes.blogListItem}
                                        onClick={(event) => NavigateToPost(itm[0], event)}>
                                        <ListItemText
                                            primary={itm[1].title}
                                            secondary={`at ${dayjs(new Date(itm[1].lastUpdate.seconds * 1000)).format('MMMM D YYYY, h:mm A')}`}
                                            primaryTypographyProps={{
                                                color: `textPrimary`
                                            }}
                                            secondaryTypographyProps={{
                                                color: `textSecondary`,
                                                component: `i`
                                            }}
                                        ></ListItemText>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    );
};

export default BlogViewAuthor;
