import React, { useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch,useSelector} from "react-redux";
import {servertimestamp} from '../../../base'
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { showErrorSnackbar, showSuccessSnackbar } from "../../../redux/actions/uiAction";
import { deleteFileBypath, uploadFromBlobAsync } from "../../../uploader";
import { TramRounded } from "@material-ui/icons";
import {getOpenedUsersImages} from "../../../redux/actions/imagesAction";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ImageUploader = (props) => {
    const [imageFiles, setImageFiles] = useState([]);

    const auth = useSelector(state => state.firebase.auth);
    const { usersImages } = useSelector(state => state.images);
    const [gotImages, updateImagesCount] = useState(usersImages.count+1)
    const dispatch = useDispatch();
    useEffect(() => {
        if(props.images && props.images.length > 0) {
            const imgs = props.images.map(img => ({
                source: img,
                options: {
                    type: `local`
                }
            }));
            setImageFiles(imgs);
        } else {
            setImageFiles([]);
        }
    }, [props.images]);

    // COnfigure server for image upload
    const server = {
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            try {
                const userId = auth.uid;
                const uuid = uuidv4();
                const meta = {
                    [`Content-Disposition`]: `inline`,
                    filename: file.name
                };
                const uploadedFile = await uploadFromBlobAsync({
                    blobUrl: URL.createObjectURL(file),
                    name: file.name,
                    uuid: uuid,
                    metadata: meta,
                    userId: userId
                });
                if (uploadedFile) {
                    load(uploadedFile);
                    props.onUploadComplete(uploadedFile);
                }
            } catch (e) {
                return;
            }
        },
        load: (url, load, error, progress, abort, headers) => {
            fetch(url, {method: 'GET', mode: 'cors'}).then(res => {
                res.blob().then(load)                
            }).catch(err=>{
                console.log(err)
            });
        },
        remove: async (source, load, error) => {
            await deleteFileBypath(source);
            let delURL = source.replace('.png','_50x50.png');
            delURL = delURL.replace('.jpg','_50x50.png');
            delURL = delURL.replace(auth.uid,auth.uid+'%2Fthumbnail');
            await deleteFileBypath(delURL);
            load();
            props.onDeleteComplete(source);
        }
    };

    return (
        <React.Fragment>
            <FilePond
                files={imageFiles}
                allowMultiple={true}
                server={server}
                instantUpload={true}
                maxFiles={props.maxFiles || 3}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
        </React.Fragment>
    );
};

export default ImageUploader;