import {
  GET_RECEIVED_POSTCARDS, GET_POSTCARD, SET_OPENED_POSTCARD, SET_SAVED_POSTCARD,
  GET_ALL_POSTCARDS, SET_POSTCARD_DETAILS, GET_SENT_POSTCARDS, SET_POSTCARD_IMAGE
} from "../actions/types";

export const INITIAL_STATE = {
  OpenedPostCard: {
    from: null,
    to: [],
    content: null,
    image: null
  },
  postCard: {},
  sent: [],
  received: [],
  postCardImage: ""
};

const postCardReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_POSTCARD:
      return {
        ...state,
        OpenedPostCard: payload,
      };
    case GET_POSTCARD:
      return {
        ...state,
        setOpenedPostCard: payload,
      };
    case SET_SAVED_POSTCARD:
      return {
        ...state,
        savedPostCard: payload,
      };
    case SET_POSTCARD_DETAILS:
      return {
        ...state,
        postCard: payload
      }
    case GET_SENT_POSTCARDS:
      return {
        ...state,
        sent: payload
      }
    case GET_RECEIVED_POSTCARDS: {
      return {
        ...state,
        received: payload
      }
    }

    case SET_POSTCARD_IMAGE:
      return {
        ...state, postCardImage: payload
      }
    default:
      return state;
  }
};

export default postCardReducer;
