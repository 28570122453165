import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon, makeStyles } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { clearSnackbar } from "../../../redux/actions/uiAction";

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function JPZSnackbar() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { snackBarMessage, snackBarOpen, snackBarType } = useSelector(
        state => state.ui
    );

    const handleClose = () => {
        dispatch(clearSnackbar());
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={snackBarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={4000}
                onClose={handleClose}
                aria-describedby="client-snackbar"
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                ]}
            ><Alert onClose={handleClose} severity={snackBarType}>
                    {snackBarMessage}
                </Alert></Snackbar>
        </div>
    );
}
