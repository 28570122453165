import { SET_OPENED_POEM } from "./types";
import { showErrorSnackbar, showSuccessSnackbar } from "./uiAction";
import store from "../store";
import { INITIAL_STATE as initialState } from "../reducers/poemReducer";
import dayjs from "dayjs";

export const clearPoem = () => (dispatch) => {
  const payload = initialState.openedPoem;
  dispatch({ type: SET_OPENED_POEM, payload });
};

export const savePoem = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  const { auth } = getState().firebase;

  const poem = {
    userId: auth.uid,
    updatedBy: auth.uid,
    lastUpdated: dayjs().format("MMMM D YYYY, h:mm:ss a"),
    ...payload
  };

  firestore.add({ collection: "poems" }, poem).then(() => {
    dispatch(showSuccessSnackbar("Poem Created Successfully"));
  }).catch((error) => {
    console.log(error, "Error in Creating Poem");
    dispatch(showErrorSnackbar("Error in Creating Poem"));
  });
};

export const updatePoem = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  const { auth } = getState().firebase;

  const poem = {
    userId: auth.uid,
    updatedBy: auth.uid,
    lastUpdated: dayjs().format("MMMM D YYYY, h:mm:ss a"),
    ...payload
  };

  delete poem.id;

  const docId = payload.id;
  console.log(docId, poem, payload);

  firestore.collection("poems").doc(docId).update(poem).then(() => {
    dispatch(showSuccessSnackbar("Poem Updated Successfully"));
  }).catch((error) => {
    console.log(error, "Error in Updating Poem");
    dispatch(showErrorSnackbar("Error in Updating Poem"));
  });
};

