import { SET_OPENED_ESSAY } from "./types";
import { showErrorSnackbar, showSuccessSnackbar } from "./uiAction";
import store from "../store";
import { INITIAL_STATE as initialState } from "../reducers/essayReducer";
import dayjs from "dayjs";

export const clearEssay = () => (dispatch) => {
  const payload = initialState.openedEssay;
  dispatch({ type: SET_OPENED_ESSAY, payload });
};

export const saveEssay = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  const { auth } = getState().firebase;

  const essay = {
    userId: auth.uid,
    updatedBy: auth.uid,
    lastUpdated: dayjs().format("MMMM D YYYY, h:mm:ss a"),
    ...payload
  };

  firestore.add({ collection: "essay" }, essay).then(() => {
    dispatch(showSuccessSnackbar("Essay Created Successfully"));
  }).catch((error) => {
    console.log(error, "Error in Creating Essay");
    dispatch(showErrorSnackbar("Error in Creating Essay"));
  });
};

export const updateEssay = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  const { auth } = getState().firebase;

  const essay = {
    userId: auth.uid,
    updatedBy: auth.uid,
    lastUpdated: dayjs().format("MMMM D YYYY, h:mm:ss a"),
    ...payload
  };

  delete essay.id;

  const docId = payload.id;
  console.log(docId, essay, payload);

  firestore.collection("essay").doc(docId).update(essay).then(() => {
    dispatch(showSuccessSnackbar("Essay Updated Successfully"));
  }).catch((error) => {
    console.log(error, "Error in Updating Essay");
    dispatch(showErrorSnackbar("Error in Updating Essay"));
  });
};

