import { SET_ADD_ASPIRATIONS, CLEAR_TODO_INPUT } from "./types";
import { showErrorSnackbar, showSuccessSnackbar } from "./uiAction";
import store from "../store";
import {fireDB} from "../../base";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

export const clearAspirations = () => (dispatch) => {
  dispatch({ type: CLEAR_TODO_INPUT });
};

// export const getAspirations = (payload) => async (dispatch, getState) => {
//   const { auth } = getState().firebase;
//   const { openedAspirations } = getState().aspirations;

//   let aspirations = [];
//   const { firestore } = store;
//   await firestore
//     .collection('aspirations')
//     .where('userid', '==', auth.uid)
//     .get()
//     .then(
//       querySnapshot => {
//         console.log(`querySnapshot.docs`, querySnapshot.docs, querySnapshot.docs.id);
//         querySnapshot.docs.forEach(doc => {
//           openedAspirations.id = doc.id;
//           payload.id = doc.id
//           aspirations.push(doc.data());
//           console.log(doc.data())
//         }
//         )
//         const responsePayload = { id: openedAspirations.id , aspirations };
//         dispatch({ type: SET_GET_ASPIRATIONS, payload: responsePayload });
//       })
//     .catch((error) => {
//       console.log(error, "Error in getting Goals");
//     });
// };

export const addAspirations = (payload) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { firestore } = store;
  await firestore.add({ collection: "aspirations" }, {
    aspirations: [payload],
    userid: auth.uid
  }).then((resp) => {
    payload.id = resp.id;
    dispatch({ type: SET_ADD_ASPIRATIONS, payload });
    dispatch(clearAspirations());
    dispatch(showSuccessSnackbar("Goals Added Successfully!"));
  });
};

export const saveAspirations = (payload) => async (dispatch, getState) => {
  const { aspirations: openedAspirations } = getState().firestore.ordered;
  const docId = openedAspirations ? openedAspirations[0].id : null;
  const goalsRef = doc(fireDB, "aspirations", docId);
  updateDoc(goalsRef, {
    aspirations: arrayUnion(payload)
  })
  .then(() => {
      dispatch(showSuccessSnackbar("Goals Set Successfully!"));
      dispatch(clearAspirations());
    }).catch((error) => {
      console.log(error, "Error in updating Aspiration");
      dispatch(showErrorSnackbar("Error in updating Aspiration"));
    });
};
export const saveAspirationsActions = (payload, doc) => async (dispatch, getState) => {
  const { firestore } = store;
  firestore.collection("aspirations").doc(doc.id).update({ 'aspirations': payload })
    .then(() => {
      firestore.collection('aspirations').doc(doc.id).get();
    }).catch((error) => {
      console.log(error, "Error in updating Goal");
    });
};