import React from "react";
import { Container } from "@material-ui/core";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SubHeader from "../SubHeader/SubHeader";
import SideBar from "../SideBar/SideBar";
import './MainWrapper.scss';
import TopBar from "../TopBar/TopBar";
import adUrl from "../../../assets/images/ad.jpg";


const MainWrapper = (props) => {
    return (
        <React.Fragment>
            <TopBar />
            <Header />
            <SubHeader crumbs={props.crumbs}/>
            <div className='main'>
                <Container maxWidth={false} disableGutters className='content'>
                    {props.children}
                </Container>
                {props.hideSideBar !== true && <SideBar className='sidebar-right'>
                {/* https://via.placeholder.com/160x600.png?text=AD 160x600' */}
                    <img alt="AD160x600" src={adUrl} />
                </SideBar> }
            </div>
            <Footer />
        </React.Fragment >
    );
};

export default MainWrapper;
