import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Chip, Typography } from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { setOpenedPost } from "../../../../redux/actions/postAction";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    tags: {
        '& > *': {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5)
        }
    }
}));

const BlogCategoriesForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useFirestoreConnect([
        { collection: 'categories' }
    ]);

    const [suggestedTags, setSuggestedTags] = useState([]);

    const { openedPost } = useSelector(state => state.post);
    const categories = useSelector((state) => state.firestore.ordered.categories);

    useEffect(() => {
        if (openedPost.category && categories) {
            console.log(openedPost.category, categories);
            const selCategory = categories.find(cat => cat.id === openedPost.category.value);
            if (selCategory) {
                const tags = selCategory.tags.map(tag => ({
                    label: tag,
                    value: tag
                }));
                setSuggestedTags(tags);
            }
        }
    }, [categories, openedPost.category]); // On Category Change

    const handleTagClick = event => { // Tag Select from Buttons
        const newTags = [...openedPost.tags, event];
        const selTags = [...new Set(newTags)];
        dispatch(setOpenedPost({ ...openedPost, tags: selTags }));
    };

    const handleTagsSelect = event => { // Tag Select from Dropdown
        const selTags = Array.isArray(event) ? event : [];
        dispatch(setOpenedPost({ ...openedPost, tags: selTags }));
    };

    return (
        <React.Fragment>
            <form className={classes.content} noValidate autoComplete="off">
                <Box mt={1}>
                    <Typography color={'textSecondary'}>Tags:</Typography>
                    <CreatableSelect
                        isMulti
                        placeholder={`Type and press enter`}
                        options={suggestedTags}
                        value={openedPost.tags}
                        onChange={handleTagsSelect}
                        classNamePrefix={`select`}
                    />
                </Box>
                <Box mt={2}>
                    <Typography color={'textSecondary'}>Suggested Tags from the list</Typography>
                    <Box mt={1} className={classes.tags}>
                        {suggestedTags.map(tag => (
                            <Chip
                                key={tag.label}
                                label={tag.label}
                                onClick={() => handleTagClick(tag)}
                            />
                        ))}
                    </Box>
                </Box>

            </form>
        </React.Fragment>
    );
};

export default BlogCategoriesForm;
