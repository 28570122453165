import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import MainWrapper from "../MainWrapper/MainWrapper";
import { isLoaded, isEmpty } from "react-redux-firebase";
import routes from "../../../routes";

const RouteWrapper = ({ component: Component, isPrivate, noLayout, ...rest }) => {
    const auth = useSelector(state => state.firebase.auth);
    const isAuthenticated = isLoaded(auth) && !isEmpty(auth);
    const history = useHistory();
    const { location, location: { state }, hideForLoggedIn } = rest;

    if (isPrivate && !isAuthenticated) {
        return <Redirect
            to={{
                pathname: '/login',
                state: { from: location },
            }} />;
    }

    if (isAuthenticated) {
        if (state && state.from) {
            history.replace(state.from);
        } else if (!isPrivate && hideForLoggedIn) {
            return <Redirect to="/dashboard" />;
        }
    }

    if (noLayout) {
        return <Route {...rest} render={props => (
            <Component {...props} />
        )} />;
    }

    return <Route {...rest} render={props => {
        const crumbs = routes.filter(({ path }) => props.match.path.includes(path))
            .map(({ path, ...rest }) => ({
                path: Object.keys(props.match.params).length
                    ? Object.keys(props.match.params).reduce(
                        (path, param) => path.replace(
                            `:${param}`, props.match.params[param]
                        ), path
                    )
                    : path,
                ...rest
            }));
        crumbs.unshift(crumbs.pop());
        //  console.log(`Generated crumbs for ${props.match.path}`);
        //  crumbs.map(({ title, path }) => console.log({ title, path }));

        return (
            <MainWrapper crumbs={crumbs} {...rest}>
                <Component {...props} />
            </MainWrapper>)
    }
    } />;
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
    isPrivate: false
};

export default RouteWrapper;