import React, { useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputLabel, Link, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { doSignUp } from '../../../redux/actions/userAction';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    margin: `${theme.spacing.unit * 7}px 0`,
    textAlign: 'center'
  }
}));

const userInit = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  displayName: '',
  avatarUrl: ''
};

const Register = () => {
  const classes = useStyles();
  const disatch = useDispatch();
  const [user, setUser] = useState(userInit);

  const onChange = event => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const onSubmit = event => {
    event.preventDefault();
    disatch(doSignUp({ ...user }));
  };

  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <Input id="firstName" name="firstName" inputProps={{ maxLength: 30 }}
            onChange={onChange} autoComplete="firstName" value={user.firstName} autoFocus />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <Input id="lastName" name="lastName" inputProps={{ maxLength: 30 }}
            onChange={onChange} autoComplete="lastName" value={user.lastName} autoFocus />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="Email ID">Email ID</InputLabel>
          <Input id="Email ID" name="email" inputProps={{ maxLength: 50 }}
            onChange={onChange} autoComplete="Email ID" value={user.email} autoFocus />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password (Minimum 6 characters)</InputLabel>
          <Input id="password" type="password" inputProps={{ maxLength: 20, minLength: 5 }}
            onChange={onChange} name="password" autoComplete="password" value={user.password} />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="displayName">Display Name</InputLabel>
          <Input id="displayName" name="displayName" inputProps={{ maxLength: 30 }}
            onChange={onChange} autoComplete="displayName" value={user.displayName} autoFocus />
        </FormControl>

        <FormControl className={classes.submit} fullWidth>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >Register</Button>
        </FormControl>
        <Link href="/login" variant="body2">
          <b>{"Back to Login"}</b>
        </Link>
      </form>
    </React.Fragment>
  );
}

export default Register;
