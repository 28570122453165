import {
  SET_OPENED_RIDDLE
} from "../actions/types";

export const INITIAL_STATE = {
  OpenedRiddle: {
    riddle: null,
    answer: null,
    start: null,
    end: null
  }
};

const riddlesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_RIDDLE:
      return {
        ...state,
        OpenedRiddle: payload,
      };
    default:
      return state;
  }
};

export default riddlesReducer;
