import React, { useEffect, useState } from "react";
import BlogSideBar from "./BlogSideBar";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Chip } from "@material-ui/core";
import BlogConfig from "./BlogConfig";
import CreateBlogForm from "./Forms/CreateBlogForm";
import BlogEditor from "./BlogEditor";
import BlogLayoutForm from "./Forms/SelectLayout";
import BlogCategoriesForm from "./Forms/BlogCategoriesForm";
import "./Blog.scss";
import { useParams } from "react-router-dom";
import { authIsReady, useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { setOpenedPost, clearPost } from "../../../redux/actions/postAction";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const Blog = () => {
    const classes = useStyles();
    const tabs = [{
        id: "create_new",
        icon: "add_circle",
        title: "Add Details",
        formTitle: "Add Details for New Post",
        form: CreateBlogForm
    }, {
        id: "add_layout",
        title: "Layout",
        icon: "view_quilt",
        formTitle: "Layout",
        form: BlogLayoutForm
    }, {
        id: "add_category",
        title: "Tag Details",
        formTitle: "Tag Details",
        icon: "category",
        form: BlogCategoriesForm
    }];

    const [anchorEl, setAnchorEl] = useState(tabs[0]);
    const { postId } = useParams();
    const [openedTab, setOpenedTab] = useState(postId ? null : tabs[0]);
    const dispatch = useDispatch();
    const { openedPost } = useSelector(state => state.post);
    const { posts } = useSelector((state) => state.firestore.ordered);
    const auth = useSelector(state => state.firebase.auth);

    useEffect(() => {
        dispatch(clearPost());
    }, []);

    useFirestoreConnect([
        { collection: 'posts', doc: postId }
    ]);

    useEffect(() => {
        if (posts && postId !== undefined && posts.length > 0 && posts[0].id === postId ) {
            const currentPost = posts[0];
            dispatch(setOpenedPost({ ...currentPost }));
        } else {
            dispatch(clearPost());
        }
    }, [posts])

    const handleTabChange = (event, anchor) => {
        setOpenedTab(event);
        setAnchorEl(anchor);
    };

    const handleNext = () => {
        const index = tabs.findIndex(tab => tab.id === openedTab.id);
        const nextTab = tabs[index + 1];
        setOpenedTab(nextTab);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={2}>
                    <BlogSideBar tabs={tabs} openedTab={openedTab} onTabCange={handleTabChange} />
                </Grid>
                <Grid item xs={10}>
                    {openedTab &&
                        <BlogConfig openedTab={openedTab} tabs={tabs} anchorEl={anchorEl} onClose={handleClose} next={handleNext} />
                    }
                    <main className={classes.content}>
                        <Box zIndex="modal">
                            {(openedPost && openedPost.isPublished === true) &&
                                <Chip color="primary" label={`Published`} />
                            }
                            <BlogEditor />
                        </Box>
                    </main>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Blog;
