import React, { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import lodash from "lodash-es";
import deepdash from "deepdash-es";
import {
  Card, CardContent, CardHeader,
  Box, Grid, Button, TextField, Icon,
  Typography, Avatar
} from "@material-ui/core";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import photoURL from "../../../assets/images/child-avatar.png";
import {
  setSavedComment,
  setAddNewComment
} from "../../../redux/actions/commentAction";
import { showErrorSnackbar } from "../../../redux/actions/uiAction";
import { fireDB } from "../../../base";

const useStyles = makeStyles((theme) => ({
  commentItem: {
    position: `relative`
  },
  commentWrap: {
    position: `relative`,
    marginLeft: `55px`
  },
  commentCard: {
    boxShadow: `none`
  },
  commentContent: {
    padding: `0 15px !important`
  },
  avatarSm: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  avatarLg: {
    width: theme.spacing(9),
    height: theme.spacing(9)
  }
}));

const CommentsMultiLevel = (props) => {
  const classes = useStyles();
  const _ = deepdash(lodash);
  const [newcomment, setNewComment] = useState({});
  const [repliedComment, setrepliedComment] = useState({});
  const [origComment, setorigComment] = useState([]);
  const [showReplyTo, setshowReplyTo] = useState();
  const [commentId, setCommentId] = useState();
  const [mainCommentId, setMainCommentId] = useState();
  const [level, setLevel] = useState(-1);

  const [savecomments, setSaveComments] = useState({});
  const history = useHistory();

  useFirestoreConnect([
     { collection: "comments", where: [props.id_field, "==", props.data.id] },
  ]);

  const { comments } = useSelector((state) => state.firestore.data);

  const firestore = useFirestore();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    if (newcomment && newcomment !== undefined) {
      setNewComment(newcomment);
    }
  }, [newcomment]);

  const textInput = useRef(null);

  const handleAddReply = async (event) => {
    event.preventDefault();
    var updatedComments = {},
      oComment = {},
      commentStack = [];
    if (!newcomment.comment) {
      dispatch(showErrorSnackbar("Please enter Comments/Replies"));
      return false;
    } else {
      if (!showReplyTo) {
        //Add Main Comment
        dispatch(setAddNewComment({ ...newcomment }));
      } else {
        // dispatch(setSavedComment(repliedComment))
        // firestore.collection("posts").doc(post.id).update({
        //   lovedby: firestore.FieldValue.arrayUnion(auth.uid)
        // });
        // firestore
        // .collection("comments")
        // .where("postid", "==", post.id).get().then(function (querySnapshot) {
        //   console.log(querySnapshot.size);
        //   setcommentsCount(querySnapshot.size);
        // });
        console.log(commentId)
       await firestore.collection("comments").where("id","==",commentId).get().then(function(res){
         console.log(res.docs[0].id)
         firestore.collection('comments').doc(res.docs[0].id).update({"children":firestore.FieldValue.arrayUnion(repliedComment)})
       })
        // var selectedComment = {};
        // _.forEach(comments, function (value, key) {
        //   if (JSON.stringify(value).indexOf(commentId) != -1) {
        //     selectedComment[key] = value;
        //   }
        // });

        // if (origComment[0].children) {
        //   //If the node has children
        //   commentStack.push(repliedComment.children[0]);
        //   commentStack.push(...origComment[0].children);

        //   updatedComments = JSON.stringify(commentStack);
        //   oComment = JSON.stringify(origComment[0].children);
        //   updatedComments = JSON.stringify(selectedComment).replace(
        //     oComment,
        //     updatedComments
        //   );
        // } else {
        //   commentStack.push(JSON.stringify(repliedComment));
        //   updatedComments = JSON.stringify(selectedComment).replace(
        //     JSON.stringify(origComment[0]),
        //     commentStack[0].toString()
        //   );
        // }

        // updatedComments = JSON.parse(updatedComments);
        // await dispatch(setSavedComment({ ...updatedComments }));
        // await firestore
        //   .collection(props.collection)
        //   .doc(props.data.id)
        //   .update({
        //     commentscount: props.data.commentscount? props.data.commentscount + 1 : 1,
        //   });
      }

      // Reset/clear Objects
      while (origComment.length) {
        origComment.pop();
      }
      handleFormClear();
    }
  };
  const handleReplyClick = (parent, level, id) => {
    setCommentId(id);
    console.log(id)
    // origComment.push({ ...parent });
    // setorigComment(origComment);
    // let replacedComment = {};
    // replacedComment = newcomment.comment
    //   ? { ...repliedComment, ...parent, children: [{ ...newcomment }] }
    //   : { ...repliedComment, ...parent };
    // setrepliedComment({ ...replacedComment });
    // setLevel({ ...level });
    setshowReplyTo("Reply to: " + parent.username);
    textInput.current.focus();
  };

  const handleChange = (data) => {
    console.log(data, `data`);
    if (data.text) {
      const newComm = {
        id: uuid(),
        username: auth.displayName ? auth.displayName : "",
        userid: auth.uid,
        avatarURL: auth.photoURL ? auth.photoURL : photoURL,
        lastupdate: new Date(),
        comment: data.text,
        blogid: props.data?.blogId?.value || "",
        [props.id_field]: props.data?.id,
        order: comments ? Object.keys(comments).length + 1 : 1
      };
      setNewComment(newComm);
      if (showReplyTo) {
        setrepliedComment(newComm)
        // setrepliedComment({ ...repliedComment, children: [{ ...newComm }] });
      }
    }
  };
  const handleFormClear = () => {
    setNewComment({
      id: "",
      username: "",
      userid: "",
      avatarURL: "",
      lastupdate: "",
      comment: "Add Comments/Replies here",
    });
    setrepliedComment({});
    setorigComment([]);
    setshowReplyTo("");
    setCommentId(0);
    setLevel(-1);
    setMainCommentId(0);
  };

  const handleFormClick = (event) => {
    if (
      event.target.value == "Add Comments/Replies here" ||
      !newcomment.comment
    ) {
      event.target.value = "";
    }
  };

  const CommentGroups = (comments) => {
    return (
      <Box className={classes.commentWrap}>
        {CommentTree(comments)}{" "}
      </Box>
    );
  };

  const CommentTree = (comments, isTop = false) => {
    const commentData = Object.entries(comments).sort(
      (firstItem, secondItem) => secondItem[1].order - firstItem[1].order
    );

    const items = commentData.map((comment, i) => {
      return (
        <Box className={classes.commentItem}>
          <Card className={classes.commentCard}>
            <CardHeader
              avatar={<Avatar as="a" src={comment[1].avatarURL} className={isTop ? classes.avatarLg : classes.avatarSm} />}
              title={comment[1].username}
              subheader={dayjs(new Date(comment[1].lastupdate.seconds * 1000)).format("MMMM D YYYY, h:mm A")}
            />
            <CardContent className={classes.commentContent}>
              <Typography>{comment[1].comment}</Typography>
            </CardContent>
            {isTop && <Box ml={1}>
              <Button
                size="small"
                onClick={() => handleReplyClick(comment[1], i, comment[1].id)}>
                Reply
              </Button>
            </Box>
            }
          </Card>
          {comment[1].children && CommentGroups(comment[1].children)}
        </Box>
      );
    });
    return items;
  };

  return (
    <React.Fragment>
      <Box m={2} />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Comments
          </Typography>
          <Box>
            <p>{showReplyTo}</p>
            <TextField
              onChange={(e) => handleChange({ text: e.target.value })}
              id="replytext"
              value={newcomment.comment}
              ref={textInput}
              label="Add Comments/Replies here"
              onClick={handleFormClick}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
            <Box mt={2} mb={2}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleAddReply}>
                <Icon>add</Icon>Submit
              </Button>
              &nbsp;
              <Button
                color="primary"
                variant="outlined"
                onClick={handleFormClear}>
                <Icon>clear</Icon>Clear
              </Button>
            </Box>
          </Box>

          {comments &&
            <Box mb={10}>
              {CommentTree(comments, true)}
            </Box>
          }

        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default CommentsMultiLevel;
