import React, { useEffect } from "react";
import { firebaseAnalytics } from "../../../base";
import { makeStyles } from '@material-ui/core/styles';
import "./Blog.scss";
import { useHistory, useParams } from "react-router-dom";
import { authIsReady, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import BlogViewer from "./BlogViewer";
import lodash from "lodash-es";
import { Box, Button, Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    content: {
        display: `flex`,
        padding: theme.spacing(0)
    },
    box: {
        margin: 10,
        padding: 10
    }
}));

const BlogView = () => {
    const classes = useStyles();
    const { postId } = useParams();
    const { posts } = useSelector((state) => state.firestore.ordered);
    const auth = useSelector((state) => state.firebase.auth);
    const history = useHistory();
    const _ = lodash;
    useFirestoreConnect([
        { collection: 'posts', doc: postId }
    ]);
    const post = posts && _.find(posts, { 'id': postId })
    useEffect(() => {
        firebaseAnalytics.logEvent("Blog Viewed",post?.title+' visited by '+auth.email);
    }, []);
    if (!post) {
        return ``;
    }
    const redirectToBack = (id = false) => {
        history.goBack();
    };

    return (
        <React.Fragment>
            <Box className={classes.box}>
                <Button
                    variant="outlined"
                    onClick={() => redirectToBack()}
                ><Icon>arrow_back</Icon>&nbsp;Back</Button>
            </Box>
            <BlogViewer post={post} />
        </React.Fragment>
    )
};

export default BlogView;
