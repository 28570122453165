import React from "react";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import "./ImageEditor.scss";

const ImageEditorControl = (props) => {
  const config = {
    includeUI: {
      initMenu: "filter",
      uiSize: {
        width: "1000px",
        height: "700px",
      },
      menuBarPosition: "bottom",
    },
    cssMaxHeight: 500,
    cssMaxWidth: 700,
    usageStatistics: true,
  };

  return (
    <React.Fragment>
      <ImageEditor {...config} />
    </React.Fragment>
  );
};

export default ImageEditorControl;
