import { SET_OPENED_DIARY, SET_GET_DIARY_PAGE,SET_SAVED_DIARY, SET_ADD_DIARY_PAGE,SET_DIARY_DATES } from "./types";
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from "./uiAction";
import store from "../store";
import dayjs from "dayjs";
import { INITIAL_STATE as diaryInitialState } from "../reducers/diaryReducer";
import { forEach } from "lodash";
import {toTimestamp} from '../../helper/Helpers';

export const setOpenedDiary = (payload) => (dispatch) => {
  dispatch({ type: SET_OPENED_DIARY, payload });
};


export const clearDiary = () => (dispatch) => {
  const payload = diaryInitialState.openedDiary;
  dispatch({ type: SET_OPENED_DIARY, payload });
};

export const getDiaryPage = (payload) => async (dispatch, getState) => {
  const { openedDiary } = getState().diary;
  const { firestore } = store;
  const on =payload.on.replaceAll('-','/');
  const diaryPage = await firestore
  .collection('diary')
  .where('userid', '==', payload.userid)
  .where('on','==',new Date(on))
  .limit(1)
  .get()
  if(diaryPage.docs.length==0) {
    openedDiary.page = '';
    payload.page = '';
  }
  else{
    openedDiary.id = diaryPage.docs[0].id;
    openedDiary.page = diaryPage.docs[0].data().page;
    payload.id = diaryPage.docs[0].id;
    payload.page = diaryPage.docs[0].data().page;
  }
  dispatch({ type: SET_GET_DIARY_PAGE,payload })
};

export const addDiaryPage = (payload) => async (dispatch, getState) => {
  const { firestore } = store;  
    await firestore.add({ collection: "diary" }, {
      page:payload.page,
      userid: payload.userid,
      on:new Date(payload.on.replaceAll('-','/')),
      feelings:payload.feelings
    }).then((resp) => {
      payload.id = resp.id
      dispatch({ type: SET_ADD_DIARY_PAGE, payload });
    });
};

export const saveDiaryPage = (payload) => async (dispatch, getState) => {
  const { openedDiary } = getState().diary;
  payload.on = new Date(payload.on.replaceAll('-','/'));
  const { firestore } = store;
  firestore.collection("diary").doc(openedDiary.id).update(payload)
  .then(() => {
    dispatch({ type: SET_SAVED_DIARY, openedDiary });
  }).catch((error) => {
    console.log(error, "Error in updating Diary");
    dispatch(showErrorSnackbar("Error in updating Diary"));
  });
};


export const setDiaryDates = (payload) => async (dispatch, getState) => {
  const { firestore } = store;
  const { diaryDates } = getState().diary;  
  const datesArray = [];
  const on = payload.on.replaceAll('-','/');
 const diaryPage = await firestore
 .collection("diary")
 .where('userid', '==', payload.userid)
// .where('on','<',new Date(on)) //Todo: Fix this bug
  .limit(6)
  .get()
  diaryPage.forEach((doc) => {
    datesArray.push({'on':doc.data().on.toDate(),'feelings':doc.data().feelings})
});
  payload.dates = datesArray;
  dispatch({ type: SET_DIARY_DATES,payload})
};