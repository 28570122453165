import React from "react";
import { DialogContentText } from "@material-ui/core";

const BlogConfigForms = props => {
    const { openedTab } = props;
    const OpenedForm = openedTab.form;
    return (
        <React.Fragment>
            <DialogContentText>
            </DialogContentText>
            { openedTab.form && <OpenedForm onNext={props.onNext}/> }
        </React.Fragment>
    );
};

export default BlogConfigForms;
