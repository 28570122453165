import React from 'react';
import RouteWrapper from './components/Layout/RouteWrapper/RouteWrapper';
import { BrowserRouter, Switch } from 'react-router-dom';
import routes from './routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faApple, faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { AuthProvider } from './Auth';
import './App.scss';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import JPZSnackbar from './components/Shared/SnackBar/SnackBar';

library.add(fab, faApple, faFacebook, faGoogle);

const App = () => {
  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthProvider>
          <JPZSnackbar />
          <BrowserRouter>
            <Switch>
              {routes.map(route => <RouteWrapper key={route.path} {...route} />)}
            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

export default App;
