import { SNACKBAR_SUCCESS, SNACKBAR_CLEAR, SNACKBAR_ERROR, SNACKBAR_INFO, IS_PAGE_LOADING } from "../actions/types";

const INITIAL_STATE = {
    snackBarOpen: false,
    snackBarType: "",
    snackBarMessage: null,
    isPageLoading: false
};

const uiReducer = (state = INITIAL_STATE, action = undefined) => {
    const { type, payload } = action;
    switch (type) {
        case SNACKBAR_SUCCESS:
            return {
                ...state,
                snackBarOpen: true,
                snackBarType: "success",
                snackBarMessage: payload
            };
        case SNACKBAR_ERROR:
            return {
                ...state,
                snackBarOpen: true,
                snackBarType: "error",
                snackBarMessage: payload
            };
        case SNACKBAR_INFO:
            return {
                ...state,
                snackBarOpen: true,
                snackBarType: "info",
                snackBarMessage: payload
            };
        case SNACKBAR_CLEAR:
            return {
                ...state,
                snackBarOpen: false,
                snackBarType: "",
                snackBarMessage: null
            };
        case IS_PAGE_LOADING:
            return {
                ...state,
                isPageLoading: payload
            };
        default:
            return state;
    }
};

export default uiReducer;
