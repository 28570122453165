import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import JoditEditor from "jodit-pro-react";
import "./JoditEditor.scss";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const JoditEditorControl = (props) => {
  const classes = useStyles();

  const editor = useRef(null);
  const [content, setContent] = useState(props.content);

  useEffect(() => {
    setContent(props.content);
  }, [ props.content ]);

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    toolbarButtonSize: "large",
    removeButtons: [
      "source",
      "preview",
      "print",
      "about",
      "superscript",
      "subscript",
    ],
    controls: {
      //Todo : Decide on flow of uploading images to be inserted into the editor directly
      // insertPicture: {
      //   name: "insertPicture",
      //   iconURL: "https://img.icons8.com/dusk/16/000000/picture.png",
      //   exec: function (editor) {
      //     editor.s.insertHTML(
      //       "<img src=https://firebasestorage.googleapis.com/v0/b/japizz.appspot.com/o/users%2Fposts%2Fimages%2Fdownload.jpg?alt=media&token=2d118eae-689c-4fca-82f4-b40fe5cb8847>"
      //     );
      //   },
      // },
    },
    minHeight: "400"
  };

  const onhandleLooseFocus = (newContent) => {
    props.onChange(newContent);
    setContent(newContent);
  };
  return (
    <React.Fragment>
      <JoditEditor
        ref={props.innerRef || editor}
        value={content}
        config={props.config || config}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => onhandleLooseFocus(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={(newContent) => {}}
      />
    </React.Fragment>
  );
};

export default JoditEditorControl;
