import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, TextField, Typography, Grid, Container, Divider, Button} from "@material-ui/core";
import { setOpenedPostCard, getAllPostCards, getPostCard, savePostCard } from "../../../../redux/actions/postCardAction";

import { useSelector, useDispatch } from "react-redux";
import photoURL from "../../../../assets/images/child-avatar.png";
import confirmationURL from '../../../../assets/images/confirmation_Logo.svg'


const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    shadow:{
        boxShadow:12

    },
    boxShadow:{
        boxShadow: "5px 5px 15px 5px #000000",
        background: '#ebeae6',
        width: '75%',
        padding: "3%", 
        border: '10px solid #89c2fb'
    },
    userAvatar: {
        display: 'inline-block',
        marginRight: `15px`
    },
    postName: {
        "&> div": {
            backgroundColor: "#fff !important",
            color: 'hsl(0, 0%, 20%)',
            borderRadius: 4,
        },
        "& input": {
            padding: `10px !important`,
            fontSize: 14
        },
        "& input::before, & input::after": {
            display: `none`
        }
    },
   
}));

const  ConformationForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
   

   

    return (
        <React.Fragment>
            <Container  align = "center">
            <Box>
            <img src={confirmationURL} alt="logo" width="200px" style={{marginTop:"55px"}}/>
                <h1 style={ {  fontFamily: "Brush Script MT", color:"red",fontSize:"40px"}}> Yaay !</h1>
               <h1>The postcard was sent to your friend </h1>
               {/* <h1>Let’s wait for the response.</h1> */}
            </ Box>
            </Container>
            <Box align="center" paddingTop={2} paddingBottom={4} marginTop={5} >
                <Box flexGrow={1}>
                    <Button size="small"
                        variant="contained"
                        onClick={() => props.handleGoFirst()}
                        color="light">Send Another Postcard </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default ConformationForm;
