import {
    Button, Container, Icon, Box, CardContent, Card, CardActions, CardHeader, CardMedia, Typography, Grid,
    Link, TextField, Select, MenuItem, FormControl, InputLabel
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { firebaseAnalytics } from "../../../base";
import { makeStyles } from '@material-ui/core/styles';
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import dayjs from "dayjs";
import { useHistory } from 'react-router-dom';
import lodash from "lodash-es";
import deepdash from "deepdash-es";
import { EssayStatus } from "../../../helper/const";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2)
    },
    card: {
        maxWidth: 345,
        position: `relative`
    },
    content: {
        height: 115,
        padding: `0 5px`,
        width: `100%`,
        position: `absolute`,
        bottom: 65,
        background: `#3D3E3F`,
        color: `#fff`,
        opacity: 0.75
    },
    media: {
        minHeight: 300,
        paddingTop: '56.25%', // 16:9
    },
    mediaNoImage: {
        minHeight: 300,
        paddingTop: '56.25%', // 16:9
        background: `#D9D9D9`,
        opacity: 0.75
    },
    searchWrap: {
        width: `500px`
    },
    searchBy: {
        width: `125px`
    },
    searchBox: {
        marginLeft: 10,
        width: `300px`
    },
    contentBody: {
        [`-webkit-box-orient`]: `vertical`,
        display: `-webkit-box`,
        [`-webkit-line-clamp`]: 3,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: `normal`,
        color: `#fff`
    },
    contentPublished: {
        textAlign: `right`,
        marginTop: theme.spacing(4),
        display: `inline-block`,
        width: `100%`
    },
    cardHeader: {
        display: `block`,
        width: 325,
        cursor: `pointer`,
        textDecoration: `none`,
        whiteSpace: `nowrap`,
        overflow: `hidden`,
        textOverflow: `ellipsis`
    },
    navLink: {
        cursor: `pointer`,
        textDecoration: `none`
    },
    contentAction: {
        position: `absolute`,
        height: 65,
        bottom: 0,
        width: `100%`,
        color: `#eee`,
        background: `#4E108B`,
        textAlign: `center`,
        justifyContent: 'space-around'
    },
    blogIcon: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));
const EssayList = props => {
    const classes = useStyles();
    const auth = useSelector(state => state.firebase.auth);
    const searchRef = useRef(null);
    const [allEssays, setAllEssays] = useState([]);
    const [filteredEssays, setFilteredEssays] = useState([]);
    const [searchBy, setSearchBy] = useState(`All`);
    const [search, setSearch] = useState(null);
    const history = useHistory();
    const _ = deepdash(lodash);

    useFirestoreConnect([
        // { collection: 'essay', orderBy: ['views','desc'] }
        { collection: 'essay' }
    ]);

    const { essay: essays } = useSelector((state) => state.firestore.ordered);

    useEffect(() => {
        firebaseAnalytics.logEvent("Library Accessed", auth.email);
    }, []);

    useEffect(() => {
        if (essays && essays.length > 0 && essays !== undefined) {
            setAllEssays(essays);
        }
    }, [essays]);

    const filteredSearch = allEssays.filter(essay => essay.status === EssayStatus.PUBLISHED);

    useEffect(() => {
        showContentGrid(filteredEssays);
    }, [filteredEssays]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!search)
                setFilteredEssays(filteredSearch);
        }, 250);
        return () => clearTimeout(timer);

    }, [filteredSearch]);

    const handleSearchByChange = event => {
        setSearchBy(event.target.value);
        setSearch('');
        setFilteredEssays(filteredSearch);
        searchRef.current.focus();
    }
    const handleSearch = event => {
        setSearch(event.target.value);
        const searchTerm = event.target.value.toString().toLowerCase();

        if (event.target.value.length < 3)
            setFilteredEssays(filteredSearch)
        else
            switch (searchBy) {
                case 'Category':
                    setFilteredEssays(filteredSearch.filter(term => term.category.toString().toLowerCase().indexOf(searchTerm) !== -1));
                    break;
                case 'Title':
                    setFilteredEssays(filteredSearch.filter(term => term.title.toString().toLowerCase().indexOf(searchTerm) !== -1));
                    break;
                default:
                    setFilteredEssays(filteredSearch.filter(term => JSON.stringify(term).toLowerCase().includes(searchTerm)));
                    break;
            }
    }

    const redirectToBack = (id = false) => {
        history.push("/library");
    };

    const redirectToView = id => {
        if (id) {
            history.push(`/essay/${id}`);
        }
    };

    const showActions = (item) => (
        <React.Fragment>
            <Box className={classes.blogIcon}>
                <Icon>visibility</Icon>&nbsp;
                <Typography variant="body2">{item.views}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>thumb_up_off_alt</Icon>&nbsp;
                <Typography variant="body2">{item.likedby && item.likedby.length}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>favorite</Icon>&nbsp;
                <Typography variant="body2">{item.lovedby && item.lovedby.length}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>share</Icon>&nbsp;
                <Typography variant="body2">{item.shared}</Typography>
            </Box>
            <Box className={classes.blogIcon}>
                <Icon>comment</Icon>
                &nbsp;
                <Typography variant="body2">{item.commentscount}</Typography>
            </Box>
        </React.Fragment>
    );

    const showContentGrid = rows => {
        if (!rows.length) {
            return ``;
        }
        return <Grid container spacing={3}>
            {rows.map((row) => {
                const img = null; //row.images && row.images.length ? row.images[0] : null;
                return <Grid item xs={4} key={row.title}>
                    <Card className={classes.card}>
                        <CardHeader
                            title={<Link className={classes.cardHeader}
                                onClick={() => redirectToView(row.id)}>{row.title}</Link>}
                            subheader={row.category}
                        />
                        {img ?
                            <CardMedia
                                className={classes.media}
                                image={img}
                                title={row.title}
                            /> : <div className={classes.mediaNoImage}></div>
                        }
                        <CardContent className={classes.content}>
                            <Typography className={classes.contentBody} variant="body2" color="textSecondary" component="p">
                                {ReactHtmlParser(row.content)}
                            </Typography>
                            {row.isPublished &&
                                <Typography variant="caption" className={classes.contentPublished}>{dayjs(new Date(row.lastUpdated)).format('MMMM D YYYY, h:mm A')}</Typography>
                            }
                        </CardContent>
                        <CardActions disableSpacing className={classes.contentAction}>
                            {showActions(row)}
                        </CardActions>
                    </Card>
                </Grid>
            })}
        </Grid>;
    };


    return (
        <React.Fragment>
            <Container className={classes.root}>
                <Box alignItems="flex-start" paddingTop={2} paddingBottom={4} display="flex">
                    <Box flexGrow={1}>
                        <Button
                            variant="outlined"
                            onClick={() => redirectToBack()}
                        ><Icon>arrow_back</Icon>&nbsp;Back</Button>
                    </Box>
                    <Box className={classes.searchWrap}>
                        <FormControl className={classes.searchBy} size="small" variant="outlined">
                            <InputLabel id="search-by-label">Search By</InputLabel>
                            <Select
                                labelId="search-by-label"
                                value={searchBy}
                                label="Search By"
                                onChange={handleSearchByChange}>
                                <MenuItem value={`All`}>All</MenuItem>
                                <MenuItem value={`Category`}>Category</MenuItem>
                                <MenuItem value={`Title`}>Title</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.searchBox}>
                            <TextField
                                label="Search"
                                size="small"
                                value={search}
                                variant="outlined"
                                onChange={handleSearch}
                                ref={searchRef} />
                        </FormControl>
                    </Box>
                </Box>
                {showContentGrid(filteredEssays)}
            </Container>
        </React.Fragment>
    );
};

export default EssayList;
