import { LOGIN, LOGOUT } from "./types";
import firebaseApp from "../../base";
import { showErrorSnackbar, showSuccessSnackbar } from "./uiAction";
import store from "../store";

export const doLogin = payload => (dispatch, getState) => {
    try {
        firebaseApp.auth()
            .signInWithEmailAndPassword(payload.email, payload.password).then(res => {
                if (res.code) {
                    throw res.message;
                } else {
                    dispatch(showSuccessSnackbar("Logged In Successfully"));
                    dispatch({ type: LOGIN, res });
                }
            }).catch(error => {
                dispatch(showErrorSnackbar("Invalid Credentials"));
            });
    }
    catch (e) {
        console.log(e, "Invalid Credentials");
        dispatch(showErrorSnackbar("Invalid Credentials"));
    }
};

export const doLogout = payload => {
    firebaseApp.auth().signOut();
    return {
        type: LOGOUT,
        payload
    };
};

export const doSignUp = payload => dispatch => {
    const { firestore } = store;

    try {
        firebaseApp.auth()
            .createUserWithEmailAndPassword(payload.email, payload.password).then(res => {
                if (res.code) {
                    throw res.message;
                }
                firestore.collection('users').doc(res.user.uid).set({...payload}, {merge: true});
                dispatch(showSuccessSnackbar("Registered Successfully"));
            }).catch(e => {
                dispatch(showErrorSnackbar("Unable to Register."));
            });
    }
    catch (e) {
        console.log(e, "Unable to Register.");
        dispatch(showErrorSnackbar("Unable to Register."));
    }
};

export const resetPassword = email => dispatch => {
    firebaseApp.auth().sendPasswordResetEmail(email).then(function () {
        dispatch(showSuccessSnackbar("Password reset link has been sent to your email."));
    }).catch(function (error) {
        dispatch(showErrorSnackbar("An Error Occured."));
    });
}
