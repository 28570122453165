import { SET_OPENED_BLOG, SET_SAVED_BLOG, SET_LIST_BLOGS } from "../actions/types";

const INITIAL_STATE = {
  openedBlog: {
    id: null,
    title: null,
    posts: []
  },
  savedBlog: {
    id: null,
    title: null,
    posts: []
  }
};

const blogReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OPENED_BLOG:
      return {
        ...state,
        openedBlog: payload
      };
    case SET_SAVED_BLOG:
      return {
        ...state,
        savedBlog: payload.openedBlog
      };
    case SET_LIST_BLOGS:
      return {
        ...state,
        setListBlogs: payload.setListBlogs
      };
    default:
      return state;
  }
};

export default blogReducer;
