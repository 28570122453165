import { Box, Button, Checkbox, Divider, FormControlLabel, Icon, IconButton, Paper } from "@material-ui/core"
import React, { useState } from 'react';
import { firebaseAnalytics } from "../../../base";
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useDispatch } from "react-redux";
import { doLogin } from "../../../redux/actions/userAction";
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import classNames from 'classnames/bind';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '350px',
    padding: '20px 0',
    border: '0 none',
    boxShadow: 'none'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 2, 3, 2),
  },
  signin: {
    alignSelf: `right`
  },
  linkWrapper: {
    display: 'flex',
    flexGrow: 1,
    marginTop: 10,
    alignItems: `baseline`
  },
  fpBtn: {
    textAlign: `right`
  },
  signup: {
    fontSize: `18px`,
    marginLeft: 10
  },
  loginWith: {
    fontSize: `15px`,
    fontWeight: `bold`,
    display: 'inline-block',
    margin: theme.spacing(4)
  },
  loginLinkWrapper: {
    display: 'inline-block'
  },
  terms: {
    marginBottom: 2
  }
}));


const LoginForm = () => {
  const classes = useStyles();
  const cx = classNames.bind(classes);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);
  const firebase = useFirebase();

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleLogin = event => {
    event.preventDefault();
    dispatch(doLogin({ email, password }));
    firebaseAnalytics.logEvent("Custom Authentication Login Attempted",email);
  }

  const loginWithGoogle = () => {
    return firebase.login({ provider: 'google', type: 'popup' })
    firebaseAnalytics.logEvent("Google Authentication Login Attempted",email);
  }

  const loginWithFacebook = () => {
    return firebase.login({ provider: 'facebook', type: 'popup' })
    firebaseAnalytics.logEvent("Facebook Authentication Login Attempted",email);
  }

  return (
    <Paper className={classes.paper} >
      <form className={classes.form} noValidate onSubmit={handleLogin}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Login"
          name="email"
          autoComplete="email"
          onChange={handleEmailChange}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onChange={handlePasswordChange}
          autoComplete="current-password"
        />

        <Box className={classes.terms}>
          <Checkbox checked={isTermsAccepted} onChange={(event) => setIsTermsAccepted(event.target.checked)} name="terms" />
          I accept <Link href="/terms-conditions" variant="body2">
          "Terms and Conditions"
          </Link>
          &nbsp;and&nbsp;
          <Link href="/privacy" variant="body2">
          "Privacy Policy"
          </Link>
        </Box>

        <div className={classes.fpBtn}>
          <Link href="/forget-password" variant="body2">
            Forgot password ?
          </Link>
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isTermsAccepted}
            className={cx(classes.submit, classes.signin)}
            startIcon={<Icon>lock</Icon>}
          >Sign In
          </Button>
        </div>

        <div className={classes.loginWith}>Login With</div>
        <div className={classes.loginLinkWrapper}>
          <IconButton
            type="button"
            color="primary"
            className={classes.submit}
            onClick={loginWithFacebook}
          ><FontAwesomeIcon icon={faFacebook} />
          </IconButton>
          <IconButton
            type="button"
            color="secondary"
            className={classes.submit}
            onClick={loginWithGoogle}
          ><FontAwesomeIcon icon={faGoogle} />
          </IconButton>
          {/* <IconButton
            type="button"
            className={classes.submit}
          ><FontAwesomeIcon icon={faApple} />
          </IconButton> */}
        </div>
        <div className={classes.linkWrapper}>
          Don't have an account ?
          <Link href="/register" className={classes.signup} variant="body2">
            {"SignUp"}
          </Link>
        </div>
      </form>
    </Paper>
  );
};

export default LoginForm;
