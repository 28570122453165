import { SET_OPENED_POST, SET_SAVED_POST, SET_LIST_POST } from "./types";
import { showErrorSnackbar, showSuccessSnackbar, togglePageLoading } from "./uiAction";
import store from "../store";
import dayjs from "dayjs";
import { INITIAL_STATE as postInitialState } from "../reducers/postReducer";

export const setOpenedPost = (payload) => (dispatch) => {
  dispatch({ type: SET_OPENED_POST, payload });
};

export const clearPost = () => (dispatch) => {
  const payload = postInitialState.openedPost;
  dispatch({ type: SET_OPENED_POST, payload });
};

export const setSavedPost = (isPublished = false, autoSave=true) => async (dispatch, getState) => {
  const { auth } = getState().firebase;
  const { openedPost } = getState().post;
  const { firestore } = store;
console.log(  "setSavedPost auth",auth ,"openedPost", openedPost,"firestore",firestore);
  const payload = {
    userId: auth.uid,
    updatedBy: auth.uid,
    lastUpdate: new Date(),
    ...openedPost,
  };
console.log("payload" ,payload);
  payload.blogId = payload.blogId;
  payload.category = payload.category;
  payload.isPublished = isPublished;
  
  if (openedPost.blogId.__isNew__) {
    dispatch(togglePageLoading(true));
    await firestore.add({ collection: "blogs" }, {
      title: openedPost.blogId.label,
      userId: auth.uid,
      category: payload.category,
    }).then((resp) => {
      console.log("resp",resp);
      payload.blogId = {
        value: resp.id,
        label: openedPost.blogId.label,
      };
      dispatch(savePost(payload,autoSave));
    });
  } else {
    dispatch(savePost(payload,autoSave));
  }
};

export const savePost = (payload,autoSave) => async (dispatch) => {
  const { firestore } = store;
console.log("savePost","payload",payload,"firestore");
  try {
    const docRefId = payload.id;
    delete payload.id;
    if (!docRefId) {
      dispatch(togglePageLoading(true));
      firestore.add({ collection: "posts" }, payload).then((docRef) => {
        payload.id = docRef.id;
        dispatch({ type: SET_SAVED_POST, payload });
        if(!autoSave) dispatch(showSuccessSnackbar("Post Saved Successfully"));
        dispatch(togglePageLoading(false));
      }).catch((error) => {
        console.log(error, "Error in saving Post");
        if(!autoSave) dispatch(showErrorSnackbar("Error in saving Post"));
        dispatch(togglePageLoading(false));
      });
    } else {
      dispatch(togglePageLoading(true));
      firestore.collection("posts").doc(docRefId).update(payload)
        .then(() => {
          if(!autoSave) dispatch(showSuccessSnackbar("Post Updated Successfully"));
          dispatch({ type: SET_SAVED_POST, payload });
          dispatch(togglePageLoading(false));
        }).catch((error) => {
          console.log(error, "Error in updating Post");
          if(!autoSave) dispatch(showErrorSnackbar("Error in updating Post"));
          dispatch(togglePageLoading(false));
        });
    }
  } catch (error) {
    console.log(error, "Error in saving Post");
    if(!autoSave) dispatch(showErrorSnackbar("Error in saving Post"));
    dispatch(togglePageLoading(false));
  }
};

