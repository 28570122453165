import {
  SET_ADD_ASPIRATIONS,
  SET_GET_ASPIRATIONS,
  SET_ASPIRATIONS_ACTIONS
} from "../actions/types";

export const INITIAL_STATE = {
  openedAspirations: {
    id: null,
    aspirations: [],
    userid: null
  },
  savedAspirations: {
    id: null,
    aspirations: [],
    userid: null
  }
};

const aspirationsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GET_ASPIRATIONS:
      return {
        ...state,
        openedAspirations: payload,
      };
    case SET_ADD_ASPIRATIONS:
      return {
        ...state,
        openedAspirations: payload,
      };
    case SET_ASPIRATIONS_ACTIONS:
      return {
        ...state,
        setAspirationsActions: payload,
      };
    default:
      return state;
  }
};

export default aspirationsReducer;
