import { combineReducers } from 'redux'
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import blogReducer from './blogReducer';
import postReducer from './postReducer';
import diaryReducer from './diaryReducer';
import journalReducer from './journalReducer';
import aspirationsReducer from './aspirationsReducer';
import todosReducer from './todosReducer';
import postCardReducer from './postCardReducer';
import imagesReducer from './imagesReducer';
import notificationReducer from './notificationsReducer';
import riddlesReducer from './riddlesReducer';


const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    user: userReducer,
    ui: uiReducer,
    blog: blogReducer,
    post: postReducer,
    diary:diaryReducer,
    journal:journalReducer,
    aspirations:aspirationsReducer,
    todos:todosReducer,
    postCard: postCardReducer,
    images:imagesReducer,
    notifications:notificationReducer,
    riddles:riddlesReducer
});

export default rootReducer;
