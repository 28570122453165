import React, { useEffect, useState } from "react";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Card, CardActions, CardContent, Icon, IconButton, Typography } from "@material-ui/core";
import BlogConfigForms from "./BlogConfigForms";
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const darkTheme = createMuiTheme({
    palette: {
        type: "dark"
    }
});

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    confTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    confWrapper: {
        position: `absolute`,
        minWidth: 400
    },
    confBox: {
        position: `relative`
    },
    confActions: {
        flexDirection: `column`,
        alignItems: 'flex-end'
    }
}));

const BlogConfig = ({ openedTab, tabs, anchorEl, next }) => {
    const classes = useStyles();
    const [open, toggleOpen] = useState(false);
    const opendTabIndex = tabs.findIndex(tab => tab.id === openedTab.id);

    const handleClose = () => {
        toggleOpen(false);
    };

    const handleNextClick = () => {
        next();
    }

    useEffect(() => {
        toggleOpen(true);
    }, [openedTab]);

    return (
        <React.Fragment>
            <MuiThemeProvider theme={darkTheme}>
                <Slide direction="right" in={open} mountOnEnter unmountOnExit>
                    <Box transitioncomponent={Transition} zIndex="tooltip" className={classes.confBox}>
                        <Card className={classes.confWrapper}>
                            <CardContent>
                                <Box className={classes.confTitle}>
                                    <Typography variant="h5" component="h2">
                                        {openedTab.formTitle || openedTab.title}
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <Icon>chevron_left</Icon>
                                    </IconButton>
                                </Box>
                                <BlogConfigForms openedTab={openedTab} tabs={tabs} onNext={handleNextClick} />
                            </CardContent>
                            <CardActions className={classes.confActions}>
                                {opendTabIndex === (tabs.length - 1) ?
                                    <Button variant="contained" color="secondary" onClick={handleNextClick}>Done</Button> :
                                    <Button variant="contained" color="secondary" onClick={handleNextClick}>Next</Button>
                                }
                            </CardActions>
                        </Card>
                    </Box></Slide>
            </MuiThemeProvider>
        </React.Fragment>
    );
};

export default BlogConfig;
