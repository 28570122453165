import {
  Button,
  Container,
  Tab,
  Icon,
  Box,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CardContent,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Typography,
  Grid,
  Link,
} from "@material-ui/core";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../redux/actions/uiAction";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { fireDB } from "../../../base";
import { doc, deleteDoc } from "firebase/firestore";
import { EssayStatus } from "../../../helper/const";
import Carousel from "react-material-ui-carousel";
import create_essay from "../../../assets/images/create_essay.svg";
import quill_icon from "../../../assets/images/quill.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  card: {
    maxWidth: 345,
  },
  topCards: {
    width: `100%`,
    display: `flex`,
  },
  roundCard: {
    borderRadius: 30,
    padding: 20,
    width: `30%`,
    margin: 20,
  },
  content: {
    height: `150px`,
    width: `100%`,
  },
  contentBody: {
    [`-webkit-box-orient`]: `vertical`,
    display: `-webkit-box`,
    [`-webkit-line-clamp`]: 3,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `normal`,
  },
  contentPublished: {
    textAlign: `right`,
    marginTop: theme.spacing(4),
    display: `inline-block`,
    width: `100%`,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  navLink: {
    cursor: `pointer`,
    textDecoration: `none`,
  },
  contentAction: {
    justifyContent: `space-between`,
    flexDirection: `row`,
  },
}));

const MyEssays = (props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("1");
  const [allEssays, setAllEssays] = useState([]);
  const history = useHistory();
  const auth = useSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();

  useFirestoreConnect([{ collection: "essay" }]);

  const { essay: essay_list } = useSelector((state) => state.firestore.ordered);
  useEffect(() => {
    if (essay_list && essay_list !== undefined) {
      setAllEssays(essay_list);
    }
  }, [essay_list]);

  const published = allEssays.filter(
    (essay) =>
      essay.status === EssayStatus.PUBLISHED && essay.userId === auth.uid
  );
  const myReads = allEssays.filter(
    (essay) =>
      essay.status === EssayStatus.PUBLISHED && essay.userId === auth.uid
  );
  const inProgress = allEssays.filter(
    (essay) =>
      essay.status === EssayStatus.IN_PROGRESS && essay.userId === auth.uid
  );
  const inReview = allEssays.filter(
    (essay) =>
      essay.status === EssayStatus.IN_REVIEW && essay.userId === auth.uid
  );
  const myReview = inReview.filter(
    (essay) =>
      essay.status === EssayStatus.IN_REVIEW && essay.reviewer && essay.reviewer.email === auth.email
  );
  console.log(auth);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const redirectToCreate = (id = false, userId) => {
    if (id) {
      if (userId != auth.uid) {
        dispatch(
          showErrorSnackbar("You're not allowed to edit other's essays")
        );
        return;
      }
      history.push(`/essay/edit/${id}`);
    } else {
      history.push("/essay/create");
    }
  };

  const redirectToReview = (id) => history.push(`/essay/review/${id}`);

  const redirectToDelete = (id = false, userId) => {
    if (id) {
      if (userId != auth.uid) {
        dispatch(
          showErrorSnackbar("You're not allowed to delete other's essays")
        );
        return;
      } else {
        deleteDoc(doc(fireDB, "essay", id));
        dispatch(showSuccessSnackbar("Essay deleted successfully!!"));
      }
    }
  };

  const redirectToView = (id) => {
    if (id) {
      history.push(`/essay/${id}`);
    }
  };

  const showContentGrid = (rows) => {
    if (!rows.length) {
      return ``;
    }
    return (
      <Box padding={10}>
        <Grid container spacing={3}>
          {rows.map((row) => {
            const img = row.images && row.images.length ? row.images[0] : null;
            return (
              <Grid item xs={4} key={row.title}>
                <Card className={classes.card}>
                  <CardHeader
                    title={
                      <Link
                        className={classes.navLink}
                        onClick={() => redirectToView(row.id)}
                      >
                        {row.title}
                      </Link>
                    }
                    subheader={row.category}
                  />
                  {img && (
                    <CardMedia
                      className={classes.media}
                      image={img}
                      title={row.title}
                    />
                  )}
                  <CardContent className={classes.content}>
                    <Typography
                      className={classes.contentBody}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {ReactHtmlParser(row.content)}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.contentPublished}
                    >
                      {dayjs(new Date(row.lastUpdated)).format(
                        "MMMM D YYYY, h:mm A"
                      )}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing className={classes.contentAction}>
                    <IconButton
                      onClick={() => redirectToCreate(row.id, row.userId)}
                      aria-label="edit"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton
                      onClick={() => redirectToDelete(row.id, row.userId)}
                      aria-label="edit"
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const showContentList = (rows) => {
    return (
      <Table className="table-container">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Category</TableCell>
            {activeTab === "2" && <TableCell>Review</TableCell>}
            <TableCell>Last Modified</TableCell>
            {activeTab === "3" ? <TableCell>Review</TableCell> : <TableCell>Edit</TableCell> }
            {activeTab !== "3" && <TableCell>Delete</TableCell> }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.title}>
              <TableCell component="th" scope="row">
                <Link
                  className={classes.navLink}
                  onClick={() => redirectToView(row.id)}
                >
                  {row.title}
                </Link>
              </TableCell>
              <TableCell>{row.category}</TableCell>
              {activeTab === "2" && (
                <TableCell>
                  {row.status === EssayStatus.IN_REVIEW &&
                    row.isReviewComplete && (
                      <Icon style={{ color: "green" }}>check_circle</Icon>
                    )}
                </TableCell>
              )}
              <TableCell>
                {dayjs(new Date(row.lastUpdated)).format("MMMM D YYYY, h:mm A")}
              </TableCell>
              {activeTab === "3" ? (
                <TableCell>
                  <IconButton
                    onClick={() => redirectToReview(row.id, row.userId)}
                  >
                    <Icon>preview</Icon>
                  </IconButton>
                </TableCell>
              ) : (
                <React.Fragment>
                  <TableCell>
                    <IconButton
                      onClick={() => redirectToCreate(row.id, row.userId)}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => redirectToDelete(row.id, row.userId)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          ))}

          {!rows.length && (
            <Box padding={7}>
              <Typography>No records to display !</Typography>
            </Box>
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <Box
          alignItems="flex-start"
          paddingTop={2}
          paddingBottom={4}
          display="flex"
        >
          <Box className={classes.topCards}>
            <Card className={classes.roundCard}>
              <CardContent>
                <Typography variant="h6">Create a new Essay</Typography>
              </CardContent>
              <CardActions disableSpacing className={classes.contentAction}>
                <Button
                  variant="contained"
                  onClick={() => redirectToCreate()}
                  color="secondary"
                >
                  Create Essay
                </Button>
                <img src={create_essay} />
              </CardActions>
            </Card>

            <Card className={classes.roundCard}>
              <CardContent>
                <Typography variant="h6">My most popular essay</Typography>
              </CardContent>
              <CardActions disableSpacing className={classes.contentAction}>
                TBD
              </CardActions>
            </Card>

            <Card className={classes.roundCard}>
              <CardContent>
                <Typography variant="h6">
                  Did you know? Parts of Essay
                </Typography>
                <br />
                <Box justifyContent={`space-around`} display={`flex`}>
                  <div>
                    Introduction. First Body Paragraph, Second Body Paragraph,
                    Third Body Paragraph, Conclusion.
                  </div>
                  <img src={quill_icon} />
                </Box>
              </CardContent>
              <CardActions disableSpacing className={classes.contentAction}>
                <Button
                  variant="contained"
                  onClick={() => redirectToCreate()}
                  color="secondary"
                >
                  More Info
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Box>

        <TabContext value={activeTab}>
          <Box className="tabs-container" marginX={5}>
            <TabList onChange={handleTabChange} aria-label="Tabs">
              <Tab label="In Progress" value="1" />
              <Tab label="In Review" value="2" />
              <Tab label="My Reviews" value="3" />
              <Tab label="Published" value="4" />
              <Tab label="My Reads" value="5" />
            </TabList>
            <TabPanel value="1">{showContentList(inProgress)}</TabPanel>
            <TabPanel value="2">{showContentList(inReview)}</TabPanel>
            <TabPanel value="3">{showContentList(myReview)}</TabPanel>
            <TabPanel value="4">{showContentGrid(published)}</TabPanel>
            <TabPanel value="5">{showContentGrid(myReads)}</TabPanel>
          </Box>
        </TabContext>
        <Box paddingY={10}>
          <Carousel
            indicators={false}
            autoPlay={false}
            navButtonsAlwaysVisible={true}
          >
            <Card className={classes.roundCard}>
              <CardContent>
                <Typography variant="h6">My Essay 1</Typography>
              </CardContent>
              <CardActions disableSpacing className={classes.contentAction}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown.
              </CardActions>
            </Card>
          </Carousel>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default MyEssays;
