import React from "react";
import { useDispatch } from "react-redux";
import { saveTodosActions } from "../../../redux/actions/todosAction";
import { saveAspirationsActions } from "../../../redux/actions/aspirationsAction";
import dayjs from "dayjs";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  table: {
    position: `relative`,
  },
}));

const TodoList = (props) => {
  const { list, title, doc } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event, row) => {
    const payload = list.map(item => item.name === row.name ? {...item, status: Number(event.target.value)} : item);
    (props.title === 'Goals') ? dispatch(saveAspirationsActions(payload, doc)) : dispatch(saveTodosActions(payload, doc))
  };

  return (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>{title}</TableCell>
          <TableCell align="right">Added On</TableCell>
          <TableCell align="center">Target Date</TableCell>
          <TableCell align="center">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list && list.map((row, i) => (
          <TableRow>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{dayjs(row.added.toDate().toString()).format('D MMM YYYY')}</TableCell>
            <TableCell align="center">{dayjs(row.target.toDate().toString()).format('D MMM YYYY')}</TableCell>
            <TableCell align="center" width="500px">
              <FormControl component="fieldset">
                <RadioGroup row aria-label="action" name="row-radio-buttons-group">
                  <FormControlLabel name="inprogress" value="0" checked={row.status === 0} onChange={(event) => handleChange(event, row)} control={<Radio />} label="In Progress!" />
                  <FormControlLabel name="completed" value="1" checked={row.status === 1} onChange={(event) => handleChange(event, row)} control={<Radio />} label="Completed!" />
                  <FormControlLabel name="hangup" value="-1" checked={row.status === -1} onChange={(event) => handleChange(event, row)} control={<Radio />} label="Hang Up!" />
                </RadioGroup>
              </FormControl>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default TodoList;
