import React, { useEffect, useState } from 'react';
import { firebaseAnalytics } from '../../../base';
import { getAuth, updateProfile } from "firebase/auth";
import {
  Avatar, Grid, TextField, IconButton, Icon, Typography, Paper, Button,
  Box, FormControl, FormGroup, RadioGroup, Radio,
  FormControlLabel, FormLabel, Checkbox, InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames/bind";
import DateFnsUtils from '@date-io/dayjs';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useFirestoreConnect } from 'react-redux-firebase';
import store from '../../../redux/store';
import dayjs from 'dayjs';
import { showSuccessSnackbar } from '../../../redux/actions/uiAction';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 2 * theme.spacing(7)
  },
  avatar: {
    width: 7 * theme.spacing(7),
    height: 7 * theme.spacing(7)
  },
  avatarInput: {
    display: `none`
  },
  avatarEdit: {
    top: 10,
    bottom: 0,
    left: 45,
    right: 0,
    opacity: 0.5,
    height: `100%`,
    width: `100%`,
    '& .MuiIcon-root': {
      width: 20,
      height: 20,
      fontSize: 20
    }
  },
  themeBox: {
    padding: 0,
    margin: 0,
    textAlign: "center",
    color: `black`,
    cursor: `pointer`,
    height: 50,
    width: 50,
    border: `4px solid white`
  },
  themeImage: {
    width: `100%`,
    height: `100%`,
    background: `white`
  },
  selectedTheme: {
    border: `4px solid gray`,
  },
  selectedThemeCheck: {
    color: `#fff`,
    fontWeight: `bold`,
    marginTop: 8,
    fontSize: 25
  }
}));

const userInit = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  tagLine: '',
  photoURL: '',

  address: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',

  password: '',
  dob: new Date(),
  school: '',
  gender: '',
  preference: [],
  notification: [],
  theme: ''
};

const themes = ["#01234E", "#059908", "#056899", "#ec6e06"];
const prefer = ['Books', 'Invitations', 'Poems', 'Post Cards', 'Blogs', 'Essay', 'Comic', 'Newsletter'];
const notifications = ['Friends Request', 'I have subscribed', 'Most Viewed (Books, Blogs, Poems etc.)',
  'Comments'];

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { firestore } = useSelector(state => state);
  const auth = useSelector(state => state.firebase.auth);
  const { users } = firestore.ordered;

  const cx = classNames.bind(classes);

  const [user, setUser] = useState(userInit);
  const [AvatarPhotoUrl, setAvatarPhotoUrl] = useState();

  const onChange = event => {
    const { name, value } = event.target;
    console.log(name, value);
    setUser({ ...user, [name]: value });
  };

  const onCheckBoxChange = (event, name) => {
    const { checked, value } = event.target;

    if (checked) {
      setUser({ ...user, [name]: [...user[name], value] });
    } else {
      const checkedValues = user[name].filter(val => val !== value);
      setUser({ ...user, [name]: checkedValues });
    }
  };

  console.log(user);


  const onImageUpload = (event) => {
    const reader = new FileReader();
    let file = event.target.files[0];
    if (file) {
      reader.readAsDataURL(event.target.files[0]);
    } else {
      const dummyProfileImage = 'https://firebasestorage.googleapis.com/v0/b/quillatwill24.appspot.com/o/dummy%2Fprofiledp.jpg?alt=media&token=cc8adbf4-c93d-4dad-bdbd-004cb90d51ae';
      setAvatarPhotoUrl(dummyProfileImage);
    }
    const storage = getStorage();
    const storageRef = ref(storage, 'users/' + auth.uid + '/avatar.jpg');
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log('Uploaded a blob or file!');
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setAvatarPhotoUrl(downloadURL);
      });
    });
  }

  const onSubmit = event => {
    event.preventDefault();
    const userObject = {
      ...user,
      dob: new Date(user.dob),
      displayName: user.firstName + ` ` + user.lastName
    };
    store.firestore.collection('users').doc(auth.uid).set({ ...userObject }, { merge: true }).then(() => {
      dispatch(showSuccessSnackbar(`Profile Updated Successfully !!`));
      firebaseAnalytics.logEvent("Profile Updated", auth.email);
    });
  };

  useFirestoreConnect([
    { collection: 'users', doc: auth.uid }
  ]);

  useEffect(() => {

    if (users) {
      const currentUser = users[0];
      setUser({ ...user, ...currentUser, dob: currentUser.dob ? currentUser.dob.toDate().toString() : new Date() });
    }
  }, [users]);

  useEffect(() => {
    if (AvatarPhotoUrl) {
      store.firestore
        .collection("users")
        .doc(auth.uid)
        .update({
          avatarUrl: AvatarPhotoUrl,
        });
      user.avatarUrl = AvatarPhotoUrl;
      const authCurrent = getAuth();
      updateProfile(authCurrent.currentUser, {
        photoURL: AvatarPhotoUrl
      }).then(() => {
        console.log("Profile Updated")
        dispatch(showSuccessSnackbar(`Profile Picture Updated Successfully. Login again to see the new profile picture!`));
      }).catch((error) => {
        console.log("Profile Updated Failed")
      });
    }
  }, [AvatarPhotoUrl]);
  return (
    <React.Fragment>
      <Grid container gutters className={classes.root} spacing={10}>
        {/* User Avatar */}
        <Grid item xs={4}>
          <Box textAlign={`center`}>
            <input
              accept="image/*"
              className={classes.avatarInput}
              onChange={(e) => { onImageUpload(e) }}
              id="avatar-input-file"
              name='photoURL'
              type="file"
            />
            <label htmlFor="avatar-input-file" style={{ padding: 0 }}>
              <IconButton color="primary" aria-label="upload picture" component="span" style={{ padding: 0 }}>
                <Avatar variant="square" src={user.avatarUrl} alt={user.displayName} className={classes.avatar}></Avatar>
                <div className={classes.avatarEdit}>
                  <Icon>edit</Icon>
                </div>
              </IconButton>
            </label>
          </Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="tag-line"
            label="Tag Line"
            name="tagLine"
            onChange={onChange}
            value={user.tagLine}
          />
        </Grid>
        {/* User Profile Form */}
        <Grid item xs={8}>
          <Grid container gutters spacing={2}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                onChange={onChange}
                value={user.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                onChange={onChange}
                value={user.lastName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={onChange}
                value={user.email}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="number"
                id="phoneNumber"
                label="Contact Number"
                name="phoneNumber"
                autoComplete="phoneNumber"
                inputProps={{ maxLength: 15, minLength: 10 }}
                onChange={onChange}
                value={user.phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      +91-
                    </InputAdornment>
                  ),
                }}

              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
                onChange={onChange}
                value={user.address}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                autoComplete="city"
                onChange={onChange}
                value={user.city}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="state"
                label="State"
                name="state"
                autoComplete="state"
                onChange={onChange}
                value={user.state}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="zipcode"
                label="Zip Code"
                name="zipcode"
                autoComplete="zipcode"
                onChange={onChange}
                value={user.zipcode}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="country"
                label="Country"
                name="country"
                autoComplete="country"
                onChange={onChange}
                value={user.country}
              />
            </Grid>

            <Grid item xs={12}>
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="password"
                onChange={onChange}
                value={user.password}
              /> */}
            </Grid>

            {/* <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date of Birth (dd/mm/yyyy)"
                  value={user.dob}
                  name="dob"
                  autoOk={true}
                  onChange={(event) => onChange({ target: { name: `dob`, value: event } })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="school"
                label="School"
                name="school"
                autoComplete="school"
                onChange={onChange}
                value={user.school}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal" fullWidth>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  row
                  name="gender"
                  onChange={onChange}
                  value={user.gender}
                >
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="other" control={<Radio />} label="Other" />
                  <FormControlLabel value="cantsay" control={<Radio />} label="Can't Say" />
                </RadioGroup>

              </FormControl>

              <FormControl margin="normal" fullWidth>
                <FormLabel component="legend">I Like</FormLabel>
                <FormGroup aria-label="position" row>
                  {prefer.map(pf => (
                    <FormControlLabel
                      control={<Checkbox
                        value={pf}
                        checked={user.preference.includes(pf)}
                        onChange={(event) => onCheckBoxChange(event, 'preference')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />}
                      label={pf}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <FormLabel component="legend">Notifications</FormLabel>
                <FormGroup aria-label="position" row>
                  {notifications.map(pf => (
                    <FormControlLabel
                      control={<Checkbox
                        value={pf}
                        checked={user.notification.includes(pf)}
                        onChange={(event) => onCheckBoxChange(event, 'notification')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />}
                      label={pf}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <br />
              <Typography>Theme</Typography>
              <Grid container spacing={5}>
                {themes.map((themeId) => (
                  <Grid item xs={2} key={themeId}>
                    <Paper
                      onClick={() => onChange({ target: { name: `theme`, value: themeId } })}
                      className={cx({
                        [classes.themeBox]: true,
                        [classes.selectedTheme]: user.theme === themeId,
                      })}
                      style={{ background: `${themeId}` }}
                    >
                      {user.theme === themeId && <Icon className={classes.selectedThemeCheck}>check</Icon>}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Box style={{ textAlign: "right" }}>
              <br />
              <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment >
  );
};

export default Profile;
