import React, { } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Hub.scss";
import blogclick from '../../../assets/images/blogclick.png';
import blogcategory from '../../../assets/images/blogcategory.png';
import blogcreate from '../../../assets/images/blogcreate.png';
import bloglayout from '../../../assets/images/bloglayout.png';
import blogshare from '../../../assets/images/blogshare.png';
import blogsubscribe from '../../../assets/images/blogsubscribe.png'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing() * 5,
        overflow: `hidden`
    }
}));

const Hub = props => {
    const classes = useStyles();

    return (
        <div className="hub-page">
            <div className={classes.root}>
  {/* <div className="topnav">
    <a href="#Responsible Blogging">Responsible Blogging</a>
    <a href="#blogging">Blogging</a>
    <a href="#manualBlog">Manual Blog</a>
    <a href="#notWriter">Why not a writer!</a>
    <a href="#creativeWriting">Creative Writing</a>
  </div>
  <br />
  <br /> */}
  <div className="BlogTotal">
    <div id="Responsible Blogging">
      <h1>Responsible Blogging</h1>
      <p>
        We encourage respectful and polite exchanges among all our users. Kindly
        refrain from using inappropriate or abusive content on{" "}
        <a href="https://www.quillatwill.com/" target="_blank">
          {" "}
          www.quillatwill.com
        </a>{" "}
        Let us not resort to shaming or intimidating people; and avoid the use
        of cruel, intimidating and offensive language. Respect the privacy of
        people and their ideas. Give the best of what you can; encourage and
        appreciate the efforts of all. Together, we can make
        <a href="https://www.quillatwill.com/" target="_blank">
          {" "}
          www.quillatwill.com
        </a>{" "}
        a free and open space for creative ideas and expressions!
      </p>
    </div>
    <div id="blogging">
      <h1>What is Blogging</h1>
      <p>
        We have always wondered why blogging is so popular. You could always
        write an essay about something. Or write a memoir. But why do people
        prefer blogging to these options?
      </p>
      <p>Once we began to think, we got not one, but many reasons.</p>
      <p>
        <b>Personal Space: </b>
        Blogs give you your own space and immense freedom to choose what you
        want to write about. You may write about your memories, your passion, or
        anything you like.
      </p>
      <p>
        <b>No Limitation: </b>
        There is no limit to what kind of a blog you can write, or how many
        blogs you can have at the same time.
      </p>
      <p>
        <b>Blogging as a passion:</b>
        For many, blogging is a passion. Even if your passion isn't blogging,
        you may still write a blog to promote your real passion or showcase your
        talent. Food blogs and travel blogs are examples of this idea. (You may
        also use a blog to market a product that you sell).
      </p>
      <p>
        <b>No need for special skill: </b>
        Anyone can write a blog, as blogging does not require exceptional
        language or any prior experience. If you are interested and know what to
        write about, you are on!
      </p>
      <h3>TYPES OF BLOGS</h3>
      <p>
        Blogs are written in several niches. A niche is a field of interest
        around which you write a blog. You may write several niches at once or
        specialize in one. The various niches include fashion/beauty, fitness,
        travel, food, health, photography, books, movies, and so on. You may
        also try taking two at a time- like fashion, travel and fitness, food
        and photography, etc. However, you ought to make sure that you are
        passionate about your niche, to deliver good content. The element of
        passionate writing is what would help your blog to stand out from the
        rest.
      </p>
      <h3>A FEW TAKEAWAYS ABOUT WRITING A BLOG</h3>
      <p></p>
      <li>
        You need not wait for a unique idea to hit you, to start a blog. Mostly
        the best blogs are those which people find relatable and interesting to
        read.
      </li>
      <li>
        Posting articles at regular intervals is essential to keep your blog
        active. Hence, while selecting a niche for your blog, you should be
        careful to select a broad section rather than a narrow idea. Good niches
        are those in which innovation or experimentation happens consistently.
        You may also choose an idea to which you can keep adding something new.
        <p>
          For example, if you take women empowerment as your niche, you can keep
          adding empowerment stories, initiatives to help women, your
          perspectives on the topic, etc.
        </p>
      </li>
      <li>
        {" "}
        You can bring about innovative ideas of presentation that can excite the
        readers. For example, if you do a food blog, you can come up with
        cuisines presented one after the other, in the order followed in a map!
        You may create a mascot or a character who speaks in the blog to make
        the narrative interesting. You may also use a different style of
        delivery to make your blog stand out from the rest.
      </li>
      <p />
      <p>
        The ideas to make your blog worth a read are many. The best thing about
        a blog is that there are no strict rules about how to write it. Hence,
        blogs promote individual, yet diverse writing styles- thereby opening a
        great number of possibilities for budding writers to try their hand at.
        Write, experiment, innovate and learn- masters weren't created in a day!
      </p>
    </div>
    <div id="manualBlog">
      <h1>How to write a Blog on quillatwill.com?</h1>
      <h3>What is a blog?</h3>
      <p>
        A blog is a page or site on the web, written and updated regularly. You
        can write blogs on anything at all, no limitations.
      </p>
      <h3>Blogging on quillatwill.com!</h3>
      <p>
        Writing a blog is quite simple on Quillatwill! Once you have decided the
        topic, follow the simple steps to pen a wonderful blog!
      </p>
      <ol>
        <li value={1}>
          Sign up on Quillatwill, and you will be led to <b>Dashboard</b>. Here you
          can see records of recent activities of your friends as well as
          yourself. You can <b>Create</b>, read from the <b>Library</b>, and go
          to
          <b>Hub</b> from the Dashboard.
        </li>
        <li>Click on Create, and select Blogs from the options available.</li>
        <img src={blogclick} />
        <li>
          At <b>Create a New Blog</b> page, you can fill in your details- the
          pen name you like, the title of the Blog, etc. Your name will be
          filled in automatically.{" "}
        </li>
        <img src={blogcreate} />
        <li>
          Choose an attractive Layout for your blog. Click on{" "}
          <b>Select a Layout</b> option from the Blog page, or select
          <b>Layout</b> from the handlebar on the left.
        </li>
        <li>
          Check out the amazing layouts in the Layout page to choose from. Once
          you choose your layout, you are good to go! You can write in different
          fonts, sizes, alignments and what not! Click on Preview option to try
          the layout.
        </li>
        <img src={bloglayout} />
        <li>
          Tag and categorize your blog to define your blog and make it easily
          accessible on Search. Click on <b>Category and Tags,</b> select the
          Category and add desirable tags.
        </li>
        <img src={blogcategory} />
      </ol>
      <p />
      <p>When the blog's done!</p>
      <p></p>
      <li>
        Click on Share option on the right handlebar to share your blog on
        Social Media.
      </li>
      <img src={blogshare} />
      <li>
        Keep note of your progress at <b>My Blogs-</b> both the blogs in
        Progress and the blogs Published.
      </li>
      <li>
        <b>Subscribe</b> option to let fellow writers follow you!
      </li>
      <img src={blogsubscribe}/>
      <p />
    </div>
    <div id="notWriter">
      <h1>Why not a writer!</h1>
      <p>
        We love to clutch a book on a Saturday, sip coffee and relax. Nearly all
        of us have our favorite authors. You may have a book that you love to
        read again or a book that you have come to associate your childhood
        with. Even when we appreciate writers for the happiness they give us, do
        we fancy our children becoming writers? Do we cherish their talent
        enough?
      </p>
      <p>
        Let's see why creative writing should be encouraged in the first place.
      </p>
      <p>
        Creativity activates the brain, gives expression to thoughts, and
        relaxes the soul. While the writer derives pleasure from expressing his
        thoughts and communicating with people, a reader has the delightful
        experience of finding a window into the author's mind. Writing
        facilitates a deeper conversation with oneself as well as the others.
        Creative writers are free-thinking, observant people who are broadminded
        as well. This does not mean that 'creative types” are a different set of
        people. Child or adult, we all have a creative side to ourselves- we
        just need to brush it up.
      </p>
      <p>
        People wonder if their children are capable enough to journey through
        the entire writing process by themselves. Here is where Quillatwill.com
        helps you. A pioneer in aiding creative writing, Quillatwill.com
        introduces your child to different genres of writing and helps him/her
        to understand her forte. Quillatwill.com also provides the option of
        writing with help from a parent, teacher, or friend by collaborative
        writing through buddy system. The buddy may guide, supervise, or
        contribute to the child's writing. Quillatwill.com provides options to
        write postcards and invitations along with templates and images to make
        them more attractive. Quillatwill.com, therefore, supports your child as
        he/she expresses his/her creativity.
      </p>
      <p>
        Creativity helps us to overcome the mundanity of our lives. Hence to
        encourage creativity is to help ourselves.
      </p>
    </div>
    <div id="creativeWriting">
      <h1> CREATIVE WRITING</h1>
      <p>
        Creative writing is an art, ability, and escape. A work of literature is
        similar to a painting- it pleasures the audience and satisfaction to the
        author. Most of us have our personal favorite author, but hardly any of
        us want our children to be writers. Nor do we cherish their capability
        if they write quite well. "She has a good language", we boast to others,
        and wrap it up.
      </p>
      <p>
        But, is creative writing something to be treated with negligence? I
        think not.
      </p>
      <h3>Why so?</h3>
      <li>
        Creative writing helps to develop clarity of thought and perspectives
        and thereby, the ability to think for oneself. She wouldn't be one who
        would merely take sides in opinion, she would have one of her own.
      </li>
      <li>
        {" "}
        Creative writing helps to develop a greater sense of humanity and
        emotional intelligence. A writer can think from different points of
        view, understand the limitations of different people, and handle life
        situations efficiently.
      </li>
      <li>
        Creative writing makes people observant. Writers have a keen eye for the
        people they meet and the surroundings they interact with. As a
        consequence, they have a deeper understanding of society and its
        workings. They even have commendable mental visualization that helps
        them to visualize everything that is narrated to them.
      </li>
      <p>
        With his literary output, a writer provides the audience, an escape from
        an increasingly data- driven and system-oriented world. It also creates
        more humane people in a seemingly selfish world- we need the little good
        that the world can spare!
      </p>
      <p>
        Having said this one cannot overlook the uncertainty that entails the
        life of a full-time writer. Quillatwill.com app, a pioneer in aiding the
        writer throughout his journey, helps in eradicating this uncertainty to
        a great extent.
      </p>
      <p>
        A platform to baby-step into the vast expanse of creative writing,
        www.quillatwill.com is equipped with the tools to aid the writing
        process and promises to be a companion in becoming a full-fledged
        writer. Quillatwill.com provides a platform for beginners to write under
        the supervision of a mentor or through collaboration with a buddy. It
        could be a parent, a teacher, or even a friend. With help, faltering
        steps may be corrected and collaborative learning may be practiced.
      </p>
      <p>
        At quillatwill.com, an aspiring writer can find their mentor, peer,
        editor, audience- all under the same roof. Altogether, quillatwill.com
        makes sure that no Tagore or Wordsworth goes unnoticed just because they
        had no means to try- to learn, to practice, and to excel.
      </p>
    </div>
  </div>
            </div>
        </div>
    );
};

export default Hub;
