import { Box, Button, Grid, Icon, TextField, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import React from "react";
import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../redux/actions/uiAction";
import logo from "../../../assets/images/logo.png";
import profile from "../../../assets/images/profile.jpg";

const darkTheme = createMuiTheme({
    palette: {
        type: "dark"
    }
});

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing() * 5
    },
    left: {
    },
    support: {
        display: `flex`
    },
    footerListHeader: {
        fontWeight: `bold`,
        fontSize: 20
    },
    footerListHeader2: {
        fontWeight: `bold`,
        fontSize: 18
    },
    footerList: {
        padding: 0,
        display: `grid`,
        listStyle: `none`,
        gridTemplateColumns: `200px 200px`
    },
    links: {
        color: `#fff`,
        fontSize: 16,
        textDecoration: `none`
    },
    emailText: {
        width: 300
    },
    emailSend: {
        marginTop: 5
    }
}));



const Footer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const [usersEmail, setUsersEmail] = React.useState();
    const footerLinks = [{
        path: '/dashboard',
        title: 'Dashboard'
    },
    {
        path: '/diary',
        title: 'Diary'
    }, {
        path: '/post-card',
        title: 'PostCard'
    }, {
        path: '/library',
        title: 'Library'
    }, {
        path: '/blogs',
        title: 'Blogs'
    }];
    //   html: "../../../views/index.html"+usersEmail
    const handleSendMail = async () =>{
        const uuid = uuidv4();
        if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(usersEmail)) {     
        await firestore.collection("mails").doc(uuid).set({
            to: ["support@quillatwill.com"],
            message: {
              subject: "Quill At Will Interest Mail",              
              html: "I am intrested in Quill at will. Please connect me at "+usersEmail
            }
          }).then(function() {
            dispatch(showSuccessSnackbar("Thank you for your email. We will get connected soon!"));
            console.log("Mail Sent");
          });
        } else{
            dispatch(showErrorSnackbar("Please enter valid email address!"));
        }
    }
    const handleEmailChange = (event) => {
        setUsersEmail(event.target.value);
        console.log(event.target.value);
    }


    return (
        <Box className={classes.root} color="white" bgcolor="black">
            <Box p={2}>
                <Grid container gutters>
                    <Grid item xs={4} className={classes.left}>
                        <Box color="white" bgcolor="black" p={2} >
                            <Typography className={classes.footerListHeader2}>Have a question ?</Typography>
                            <div className={classes.support}><Icon>email</Icon> &nbsp;support@quillatwill.com</div>
                        </Box>
                    </Grid>
                    <Grid item xs={4} className={classes.left}>
                        <Box color="white" bgcolor="black" p={2} >
                            <Typography className={classes.footerListHeader}>Links</Typography>
                            <ul className={classes.footerList}>
                                {footerLinks.map((menu, key) =>
                                    <li className={classes.footerList}>
                                        <NavLink to={menu.path}
                                            className={classes.links}>{menu.title}
                                        </NavLink>
                                    </li>)}
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xs={4} className={classes.left}>
                        <MuiThemeProvider theme={darkTheme}>
                            <Box color="white" bgcolor="black" p={2} >
                                <Typography className={classes.footerListHeader2}>We will connect with you :)</Typography>
                                <TextField
                                    className={classes.emailText}
                                    id="email"
                                    placeholder="Enter your Email Address"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    onChange={handleEmailChange}
                                />
                                <br />
                                <Button className={classes.emailSend} variant="outlined" onClick={handleSendMail}>Send</Button>
                            </Box>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            </Box>
            <Box color="white" bgcolor="black" p={2}>
                ©{dayjs().format(`YYYY`)} Quill at Will
            </Box>
        </Box>
    );
};

export default Footer;
