import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { firebaseAnalytics } from '../../../base'
import { Box, Card, Paper, CardContent, Typography, Grid, Button, IconButton, Icon, LinearProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import meme from '../../../assets/images/meme.svg';
import my_stories from '../../../assets/images/my_stories.svg';
import my_archives from '../../../assets/images/my_archives.svg';
import my_blogs from '../../../assets/images/my_blogs.svg';
import my_postcards from '../../../assets/images/my_postcards.svg';
import my_invitations from '../../../assets/images/my_invitations.svg';
import my_commics from '../../../assets/images/my_commics.svg';
import my_poems from '../../../assets/images/my_poems.svg';
import dashboard_stats from '../../../assets/images/dashboard_stats.svg';
import todays_riddle from '../../../assets/images/todays_riddle.svg';
import { useSelector, useDispatch } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { getRiddle } from "../../../redux/actions/riddlesAction";
import store from "../../../redux/store";
import { showInfoSnackbar } from "../../../redux/actions/uiAction";
import { getReceivedPostCards, getSentPostCards } from "../../../redux/actions/postCardAction";
import { EssayStatus } from "../../../helper/const";
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing() * 5
    },
    left: {

    },
    right: {
        textAlign: `right`,
        paddingLeft: `20px`
    },
    todaysRiddle: {
        backgroundColor: `#4F108C !important`,
        backgroundPosition: `bottom`,
        color: `#fff`,
        padding: `5px 15px`,
        borderRadius: 10,
        marginBottom: 10
    },
    todaysRiddleLabel: {
        fontSize: 30,
        fontWeight: 200
    },
    todaysRiddleText: {
        fontSize: 24,
        fontWeight: 200,
        fontStyle: `italic`,
        fontFamily: `Segoi Print`,
        color: `#E2A43A`,
        width: `50%`,
        marginRight: 10
    },
    todaysRiddleAnswer: {
        fontSize: 24,
        fontWeight: 200,
        fontStyle: `bold`,
        fontFamily: `Segoi Print`,
        color: `#fff`,
        width: `50%`,
        marginRight: 10
    },
    todaysRiddleActions: {
        width: `48%`,
        textAlign: `right`,
        marginTop: 20
    },
    riddleButton: {
        color: `#4F108C`,
        borderColor: `white`,
        background: `#fff`,
        marginRight: 10,
        '&.MuiIconButton-root': {
            background: `transparent`,
            color: `#fff`
        },
        '& :hover': {
            color: `#fff`
        }
    },
    todaysRiddleContent: {
        display: `flex`
    },
    memes: {

    },
    mainCardWrapper: {
        display: `grid`,
        gridTemplateColumns: `repeat(2, 1fr)`,
        gridGap: `20px`
    },
    mainCard: {
        padding: 20,
        minHeight: 200,
        display: `flex`,
        backgroundPosition: `bottom`,
        backgroundRepeat: `no-repeat`
    },
    mainCardTitle: {
        fontSize: 30,
        fontWeight: 300,
        color: `#810070`
    },
    mainCardSubTitle: {
        fontSize: 16,
        fontWeight: 300,
        height: 30,
        fontStyle: `italic`,
        color: `#B40A0A`
    },
    mainCardBtn: {
        marginTop: 30,
        background: `#6200EE`,
        color: `#fff`,
        padding: `3px 10px`
    },
    mainCardIconBtn: {
        marginTop: 30,
        marginLeft: 20
    },
    mainCardFull: {
        padding: `20px`,
        minHeight: 155,
        backgroundPosition: `bottom`,
        backgroundRepeat: `no-repeat`,
        display: `flex`,
        marginTop: 20
    },
    mainCardFullContent: {
        width: `40%`
    },
    dashboardStats: {
        marginTop: 15,
        backgroundImage: `url(${dashboard_stats})`,
        minHeight: 600,
        backgroundPosition: `bottom`
    },
    dashboardTitle: {
        fontSize: 25,
        fontFamily: `Open Sans`,
        fontWeight: 300,
        textAlign: `center`,
        paddingTop: 20,
        marginBottom: 10
    },
    dashboardSt: {
        display: `flex`,
        justifyContent: `space-evenly`,
        marginTop: 10
    },
    dashboardStLeft: {
        width: `50%`,
        textAlign: `left`,
        display: `flex`,
        marginTop: 20,
        '& .MuiTypography-root': {
            color: `#333333`,
            fontSize: 15,
            fontFamily: `Open Sans`,
            fontWeight: 300
        }
    },
    dashboardStRight: {
        '& .MuiTypography-root': {
            color: `#671D6A`,
            fontSize: 35,
            fontFamily: `Open Sans`,
            fontWeight: 300
        }
    },
    dashboardStProgress: {
    },
    dashboardViewAll: {
        marginTop: 30,
        background: `#6200EE`,
        color: `#fff`,
        padding: `3px 10px`
    }
}));

const Dashboard = props => {
    const classes = useStyles();
    const history = useHistory();
    const [riddleOfTheDay, setRiddle] = useState([])
    const [allPosts, setAllPosts] = useState([]);
    const [isAnswer, setIsAnswer] = useState(false)
    const riddles = useSelector(state => state.riddles.OpenedRiddle);
    const PostCardsReceived = useSelector(state => state.postCard.received);
    const PostCardsSent = useSelector(state => state.postCard.sent);

    const { firestore } = store
    const dispatch = useDispatch()

    const auth = useSelector(state => state.firebase.auth);
    useFirestoreConnect([
        { collection: 'posts' },
        { collection: 'essay' }
    ]);

    const { posts } = useSelector((state) => state.firestore.ordered);

    const { essay: allEssays } = useSelector((state) => state.firestore.ordered);
    var publishedEssays = 0, inProgressEssays = 0, inReviewEssays = 0;
    if (allEssays && allEssays.length > 0) {
        publishedEssays = allEssays.filter(essay => essay.status === EssayStatus.PUBLISHED && essay.userId === auth.uid).length;
        inProgressEssays = allEssays.filter(essay => essay.status === EssayStatus.IN_PROGRESS && essay.userId === auth.uid).length;
        inReviewEssays = allEssays.filter(essay => essay.status === EssayStatus.IN_REVIEW && essay.userId === auth.uid).length;
    }

    useFirestoreConnect([
        {
            collection: 'aspirations',
            where: ["userid", "==", auth.uid]
        },
    ]);
    const { aspirations: aspirationsDoc } = useSelector((state) => state.firestore.ordered);
    var missedCount = 0, targettedGoals = 0;
    if (aspirationsDoc && aspirationsDoc.length > 0) {
        aspirationsDoc[0].aspirations.map(a => (a.target.toDate() < new Date()) ? missedCount++ : missedCount);
        aspirationsDoc[0].aspirations.map(a => (a.target.toDate() < new Date()) ? targettedGoals++ : targettedGoals);
    }
    useFirestoreConnect([
        {
            collection: 'todos',
            where: ["userid", "==", auth.uid]
        },
    ]);
    const { todos: todosDoc } = useSelector((state) => state.firestore.ordered);

    useFirestoreConnect([
        {
            collection: 'diary',
            where: ["userid", "==", auth.uid]
        },
    ]);
    const { diary: diaryDoc } = useSelector((state) => state.firestore.ordered);

    useFirestoreConnect([
        {
            collection: 'journal',
            where: ["userid", "==", auth.uid]
        },
    ]);
    const { journal: journalDoc } = useSelector((state) => state.firestore.ordered);

    var missedTodos = 0, targettedTodos = 0;
    if (todosDoc && todosDoc.length > 0) {
        todosDoc[0].todos.map(a => (a.target.toDate() < new Date() && a.status === -1) ? missedTodos++ : missedTodos);
        todosDoc[0].todos.map(a => (a.target.toDate() < new Date() && a.status === -1) ? targettedTodos++ : targettedTodos);
    }

    useEffect(() => {
        dispatch(getRiddle())
        if (posts && posts.length > 0 && posts !== undefined) {
            setAllPosts(posts);
        }
    }, [posts]);

    useEffect(() => {
        firebaseAnalytics.logEvent("Dashboard Visited", auth.email);
        dispatch(getReceivedPostCards(auth))
        dispatch(getSentPostCards(auth))
    }, []);

    const publishedBlogs = allPosts.filter(post => post.isPublished && post.userId === auth.uid).length;
    const inProgressBlogs = allPosts.filter(post => !post.isPublished && post.userId === auth.uid).length

    let sec = new Date().getTime() / 1000;
    for (let i = 0; i < riddles.length; i++) {
        if (sec >= riddles[i].start.seconds && sec <= riddles[i].end.seconds) {
            console.log("text in riddles", riddles[i].riddle)
            // setRiddle(riddles[i])
            riddleOfTheDay.push(riddles[i])
        }
    }
    const handleSharedClick = () => {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        dispatch(showInfoSnackbar("Awesome! This site is copied to share!"));
    };

    return (
        <React.Fragment>
            {riddleOfTheDay.length === 0 ? "loading" :
                <Grid container gutters className={classes.root}>
                    <Grid item xs={12} className={classes.left}>
                        <Card className={classes.todaysRiddle} style={{ backgroundImage: `url(${todays_riddle})` }}>
                            <CardContent>
                                <Typography className={classes.todaysRiddleLabel}>
                                    Today’s Riddle
                                </Typography>
                                <div className={classes.todaysRiddleContent}>
                                    <Typography className={classes.todaysRiddleText}>
                                        {riddleOfTheDay[0].riddle}
                                        {isAnswer &&
                                            <p className={classes.todaysRiddleAnswer}>{riddleOfTheDay[0].answer}</p>
                                        }
                                    </Typography>
                                    <div className={classes.todaysRiddleActions}>
                                        <Button className={classes.riddleButton} variant="outlined" size="small" onClick={() => setIsAnswer(!isAnswer)}>Show Answer</Button>
                                        {/* <Button className={classes.riddleButton} variant="outlined" size="small">Vote</Button> */}
                                        <IconButton className={classes.riddleButton} variant="outlined" size="small" onClick={handleSharedClick}>
                                            <Icon>share</Icon>
                                        </IconButton>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={classes.mainCardWrapper}>
                            {/* My Dairy */}
                            <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_stories})` }}>
                                <div>
                                    <Typography className={classes.mainCardTitle}>
                                        My Dairy
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {missedCount + ` Missed & ` + targettedGoals + ` Goals targetted`}<br />
                                        {missedTodos + ` Missed & ` + targettedTodos + ` Todos targetted`}<br />
                                        {diaryDoc.length + ` Dairy Pages & ` + journalDoc.length + ` Journal Pages Written`}
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {/* 14 in Progress */}
                                    </Typography>
                                    <Button size="small" className={classes.mainCardBtn}
                                        onClick={() => history.push(`diary`)}>Start Now</Button>
                                </div>
                            </Card>
                            {/* My Essays */}
                            <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_poems})` }}>
                                <div>
                                    <Typography className={classes.mainCardTitle}>
                                        My Essays
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {publishedEssays} Published
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {inReviewEssays} In Review
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {inProgressEssays} In Progress
                                    </Typography>
                                    <Button size="small" className={classes.mainCardBtn}
                                        onClick={() => history.push(`essay/create`)}>Start Now</Button>
                                    <IconButton size="small" className={classes.mainCardIconBtn}
                                        onClick={() => history.push(`essay`)}><Icon>visibility</Icon></IconButton>
                                </div>
                            </Card>
                            {/* My Poems */}
                            {/* <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_poems})` }}>
                            <div>
                                <Typography className={classes.mainCardTitle}>
                                    My Poems
                                </Typography>
                                <Typography className={classes.mainCardSubTitle}>
                                    22 Published
                                </Typography>
                                <Typography className={classes.mainCardSubTitle}>
                                    12 in Progress
                                </Typography>
                                <Button size="small" className={classes.mainCardBtn}>Start Now</Button>
                            </div>
                        </Card> */}
                            {/* My Commics */}
                            {/* <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_commics})` }}>
                            <div>
                                <Typography className={classes.mainCardTitle}>
                                    My Comics
                                </Typography>
                                <Typography className={classes.mainCardSubTitle}>
                                    174 Published
                                </Typography>
                                <Typography className={classes.mainCardSubTitle}>
                                    94 in Progress
                                </Typography>
                                <Button size="small" className={classes.mainCardBtn}>Start Now</Button>
                            </div>
                        </Card> */}
                            {/* My Invitations */}
                            {/* <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_invitations})` }}>
                            <div>
                                <Typography className={classes.mainCardTitle}>
                                    My Invitations
                                </Typography>
                                <Typography className={classes.mainCardSubTitle}>
                                    121 Published
                                </Typography>
                                <Typography className={classes.mainCardSubTitle}>
                                    64 in Progress
                                </Typography>
                                <Button size="small" className={classes.mainCardBtn}>Start Now</Button>
                            </div>
                        </Card> */}
                            {/* My Postcards */}
                            <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_postcards})` }}>
                                <div>
                                    <Typography className={classes.mainCardTitle}>
                                        My Postcards
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {PostCardsReceived.length + ` Received`}
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {PostCardsSent.length + ` Sent`}
                                    </Typography>
                                    <Button size="small" className={classes.mainCardBtn}
                                        onClick={() => history.push(`post-card`)}>Start Now</Button>
                                    {/* <IconButton size="small" className={classes.mainCardIconBtn}
                                onClick={() => history.push(`post-card`)}><Icon>visibility</Icon></IconButton> */}
                                </div>
                            </Card>
                            {/* My Blogs */}
                            <Card className={classes.mainCard} style={{ backgroundImage: `url(${my_blogs})` }}>
                                <div>
                                    <Typography className={classes.mainCardTitle}>
                                        My Blogs
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {`${publishedBlogs} Published`}
                                    </Typography>
                                    <Typography className={classes.mainCardSubTitle}>
                                        {`${inProgressBlogs} in Progress`}
                                    </Typography>
                                    <Button size="small" className={classes.mainCardBtn}
                                        onClick={() => history.push(`blogs/create`)}>Start Now</Button>
                                    <IconButton size="small" className={classes.mainCardIconBtn}
                                        onClick={() => history.push(`blogs`)}><Icon>visibility</Icon></IconButton>
                                </div>
                            </Card>
                        </div>
                        {/* My Archive */}
                        {/* <Card className={classes.mainCardFull} style={{ backgroundImage: `url(${my_archives})` }}>
                        <div className={classes.mainCardFullContent}>
                            <Typography className={classes.mainCardTitle}>
                                My Archive
                            </Typography>
                            <Typography className={classes.mainCardSubTitle}>
                                21 Published
                            </Typography>
                            <Typography className={classes.mainCardSubTitle}>
                                3 in Progress
                            </Typography>
                        </div>
                        <div>
                            <Button size="small" className={classes.mainCardBtn}>Start Now</Button>
                        </div>
                    </Card> */}
                    </Grid>
                    {/* <Grid item xs={4} className={classes.right}>
                    <img src={meme} alt="Memes Corner" className={classes.memes} />
                    <Paper className={classes.dashboardStats}>
                        <Typography className={classes.dashboardTitle}>Quill At Will Dashboard</Typography>
                        <Box className={classes.dashboardSt}>
                            <div className={classes.dashboardStLeft}>
                                <Icon>books</Icon>&nbsp;
                                <Typography>Books Published</Typography>
                            </div>
                            <div className={classes.dashboardStRight}>
                                <Typography>76,298</Typography>
                                <LinearProgress
                                    className={classes.dashboardStProgress}
                                    variant="determinate"
                                    value={20}
                                    color="secondary" />
                            </div>
                        </Box>
                        {/* New Articles */}
                    {/* <Box className={classes.dashboardSt}>
                            <div className={classes.dashboardStLeft}>
                                <Icon>library_books</Icon>&nbsp;
                                <Typography>New Articles</Typography>
                            </div>
                            <div className={classes.dashboardStRight}>
                                <Typography>24,361</Typography>
                                <LinearProgress
                                    className={classes.dashboardStProgress}
                                    variant="determinate"
                                    value={20}
                                    color="secondary" />
                            </div>
                        </Box> */}
                    {/* Friend Activities */}
                    {/* <Box className={classes.dashboardSt}>
                            <div className={classes.dashboardStLeft}>
                                <Icon>edit_note</Icon>&nbsp;
                                <Typography>Friend Activities</Typography>
                            </div>
                            <div className={classes.dashboardStRight}>
                                <Typography>76,298</Typography>
                                <LinearProgress
                                    className={classes.dashboardStProgress}
                                    variant="determinate"
                                    value={20}
                                    color="secondary" />
                            </div>
                        </Box>
                        <Box className={classes.dashboardSt}>
                            <Button className={classes.dashboardViewAll}>View All</Button>
                        </Box>                        
                    </Paper>
                </Grid>  */}
                </Grid>
            }
        </React.Fragment>
    );
};

export default Dashboard;
